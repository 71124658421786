import React, { Component } from 'react'
import {
    FormControl,
    Autocomplete,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { TextField, List, ListItem, ListItemSecondaryAction, ListItemText, Switch } from '@material-ui/core';
import ZiplyneButton from 'components/Button/Button';
import { typeIds } from 'config';

class EditSubCompaniesModal extends Component {
    componentDidUpdate(prevProps) {
        if (prevProps.editData.modalType !== this.props.editData.modalType) {
            this.setState({
                selectedDomain: '',
                deleteDomain: ''
            })
        }
    }

    state = {
        selectedDomain: '',
    }

    render() {
        const { editableSubCompanyName, WebActive, MobileActive, ZiplyneSnap, SiteSnap, 
        CustomizeLogo, AdvancedAnalytics, onChange, buttonType, selectedStatus,
        newDomainName, EditableCompanyUrl, editedDomainDetails, EditableParentCompanyUrl} = this.props.editData;
        
        const draftId = typeIds.status.filter(el => el.name === 'draft')[0].id;
        const testId = typeIds.status.filter(el => el.name === 'test')[0].id;
        const liveId = typeIds.status.filter(el => el.name === 'live')[0].id;
        
        console.log(selectedStatus)
        return (
            <div>
                <FormControl sx={{ width: "100%" }}>
                    <TextField 
                        label="Edit SubCompany Name" 
                        id="outlined-size-normal" 
                        defaultValue={editableSubCompanyName}
                        fullWidth
                        style={{
                            margin: "1.2rem 0"
                        }}
                        onChange={(e) => onChange('editableSubCompanyName', e.target.value)}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedStatus}
                            label="Status"
                            onChange={(e) => onChange('selectedStatus', e.target.value)}
                        >
                            <MenuItem value={draftId}>Draft</MenuItem>
                            <MenuItem value={testId}>Test</MenuItem>
                            <MenuItem value={liveId}>Live</MenuItem>
                        </Select>
                    </FormControl>
                    <List className="position-static">
                        <ListItem>
                            <ListItemText primary="Web Active"/>
                            <ListItemSecondaryAction>
                                <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('WebActive', e.target.checked)} checked={WebActive}/>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Mobile Active"/>
                            <ListItemSecondaryAction>
                                <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('MobileActive', e.target.checked)} checked={MobileActive}/>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Popup Snap"/>
                            <ListItemSecondaryAction>
                                <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('ZiplyneSnap', e.target.checked)} checked={ZiplyneSnap}/>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Site Snap"/>
                            <ListItemSecondaryAction>
                                <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('SiteSnap', e.target.checked)} checked={SiteSnap}/>
                            </ListItemSecondaryAction>
                        </ListItem> 
                        <ListItem>
                            <ListItemText primary="Whitelabel"/>
                            <ListItemSecondaryAction>
                                <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('CustomizeLogo', e.target.checked)} checked={CustomizeLogo}/>
                            </ListItemSecondaryAction>
                        </ListItem> 
                        <ListItem>
                            <ListItemText primary="Advanced Analytics"/>
                            <ListItemSecondaryAction>
                                <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('AdvancedAnalytics', e.target.checked)} checked={AdvancedAnalytics}/>
                            </ListItemSecondaryAction>
                        </ListItem> 
                        {/*<ListItem>
                            <ListItemText primary="Cross Domain Access"/>
                            <ListItemSecondaryAction>
                                <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('CrossDomainAccess', e.target.checked)} checked={CrossDomainAccess}/>
                            </ListItemSecondaryAction>
                        </ListItem> */}
                    </List>
                </FormControl>
                <div style={{
                    display: "flex",
                    // flexDirection: "column",
                    justifyContent: "center",
                    margin: "1.5rem 0"
                }}>
                    <ZiplyneButton
                        customWidth="50%"
                        background={(buttonType && buttonType === 'add') ? "#673ab7" : ""}
                        border={(buttonType !== 'add') ? "1px solid #673ab7" : ''}
                        onClick={(e) => onChange('buttonType', 'add')}
                    >Add Domain</ZiplyneButton>
                    <ZiplyneButton
                        customWidth="50%"
                        background={(buttonType && buttonType === 'delete') ? "#673ab7" : ""}
                        border={(buttonType !== 'delete') ? "1px solid #673ab7" : ''}
                        onClick={(e) => onChange('buttonType', 'delete')}
                        border="1px solid #673ab7"
                    >Delete Domain</ZiplyneButton>
                </div>
                {
                    buttonType && buttonType === 'add' && 
                    <Autocomplete
                        id="checkboxes-tags-demo"
                        options={EditableParentCompanyUrl}
                        onChange={(e, val) => {
                            this.setState({ 
                                selectedDomain: val,
                                // editedDomainName: val
                            });
                            onChange('newDomainName', val.url)
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.url}
                        renderInput={(params) => <TextField {...params} label="Search for the domain to add" margin="normal" />}
                    />
                }
                {
                    buttonType && buttonType === 'edit' &&
                    <Autocomplete
                        id="checkboxes-tags-demo"
                        options={EditableCompanyUrl}
                        onChange={(e, val) => {
                            this.setState({ 
                                selectedDomain: val,
                                // editedDomainName: val
                            });
                            onChange('editedDomainDetails', { nameBefore: val.url, nameAfter: val.url, domainId: val.id })
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.url}
                        renderInput={(params) => <TextField {...params} label="Search for the domain to Edit" margin="normal" />}
                    />
                }
                {
                    this.state.selectedDomain !== '' && buttonType === 'edit' &&
                    <TextField 
                        fullWidth
                        label="Edit the selected domain"
                        value={editedDomainDetails.nameAfter}
                        onChange={(e) => onChange('editedDomainDetails', {
                            nameBefore: this.state.selectedDomain,
                            nameAfter: e.target.value
                        })}
                    />
                }
                {
                    buttonType === 'delete' && <Autocomplete
                        id="checkboxes-tags-demo"
                        options={EditableCompanyUrl}
                        onChange={(e, val) => {
                            onChange('deleteDomainId', val.domainId)
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.url}
                        renderInput={(params) => <TextField {...params} label="Search for the domain to Delete" margin="normal" />}
                    />
                }
            </div>
        )
    }
}

export default EditSubCompaniesModal
