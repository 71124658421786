import React, {Component} from 'react';
import Dropzone from 'components/FileUpload/DropZone';
import { TextField, FormControl } from '@material-ui/core';

class CompaniesModal extends Component {
    render () {
        const {CompanyName, Url, onChange,ServerPath } = this.props.editData;
        var showDropZone =  {}
        return (
            <FormControl fullWidth>
                <TextField label="Application Name" value={CompanyName} onChange={(e) => onChange('CompanyName', e.target.value)}/>
                <TextField label="URL" value={Url} onChange={(e) => onChange('Url', e.target.value)}/>
                <div style={showDropZone}>
                <Dropzone Url={Url} ServerPath={ServerPath} CompanyName={CompanyName} file={(e) => this.props.onChange('file', e)} acceptedFileTypes=".jpeg, .jpg, .png"/>
                </div>
            </FormControl>
        )
    }
}

export default CompaniesModal;