import { typeIds } from 'config';
import html2canvas from 'html2canvas';
import { toPng } from 'html-to-image';

const displayTime = (timestamp) => {
	if (new Date(timestamp) !== "Invalid Date" && !isNaN(new Date(timestamp))) {
    if (timestamp === undefined || timestamp === null) {
      return "N.A."
    } else {
      if (timestamp.length < 10) {
        return timestamp;
      }
      var tmp = new Date(timestamp).toLocaleString();
      if (tmp.indexOf(",") >= 0) {
        return tmp.substring(0, tmp.indexOf(","));
      } else {
        return tmp;
      
      }
      }
	} else {
		return timestamp;
	}
}
const generateId = (length) => {
   var result           = '';
   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

const modifyMobileUsers = (data, isMobile) => {
  var tempArr = [];
  for (var i = 0; i < data.length; i++) {
    if (isMobile === true) {
      if (data[i].IsMobile === true) {
        tempArr.push(data[i]);
      }
    } else {
      if (data[i].IsMobile === false) {
        tempArr.push(data[i]);
      }
    }
  }
  return tempArr;
}
const checkVal = (inp) => {
    if (inp === undefined || inp === null || inp === false) {
        return false;
    } else {
        return true;
    }
}

/*Sort array by one element of an Object
  arr, element by which sorting is needed, and order if you want in increasing(true) or deccresing (false_)
*/
const sortArrayByElement = (arr, elementName, order) => {
  const myData = [].concat(arr)
    .sort((a, b) => a[elementName] > b[elementName] ? 1 : -1);
    /// for increasing order : true if you want the order to be increading
    if (order === true ) {
      return myData;
    } else {
      return myData.reverse();
    }
}
const fetchUniquePages = (arr) => {
  var pageList = [];
  for (var i = 0; i < arr.length; i++) {
    if(pageList.indexOf(arr[i].FirstPage) === -1) {
      pageList.push(arr[i].FirstPage);
    }

  }
  return pageList;
}

const ZipFilter = (string, zips) => {
  let filteredZip = []
  if (string) {
    let attributes = string.split('-');
    if (attributes[attributes.length - 1] === 'All') {
      filteredZip = zips;
    } else if (attributes.length > 1) {
      zips.forEach(zip => {
        if (zip.GroupName === string) {
          filteredZip.push(zip);
        } 
      })
    } else if (attributes.length === 1) {
      zips.forEach(zip => {
        if(zip.GroupName.match(attributes[attributes.length - 1])) {
          filteredZip.push(zip);
        }
      })
    }
  }
  // "ADString" : "Ziplyne-User-JHU , Ziplyne-ADMIN-JHHS-Oncology , Group1, Group2 , Group3 , Group4 "
}

const convertUserPayload = (companyUsers, userCompaniesRoles) => {
  let newData = [], allUsers = [], uniqueUsers = [], allEmails = [];
  userCompaniesRoles.map(company => {
    let user = company.user;
    let roles = []
    if (company.role_id) {
      let role = typeIds.roles.filter(el => el.id === company.role_id)[0];
      roles.push({
        id: role.id,
        name: role.name
      })
    }
    let obj = {
      ...user,
      roles
    }
    newData.push(obj);
  });
  allUsers = [...companyUsers, ...newData];
  allUsers.map(user => {
    if (!allEmails.includes(user.email)) {
      uniqueUsers.push(user);
      allEmails.push(user.email)
    }
  })
  return uniqueUsers;
}

const takeScreenshot = (element) => {
  setTimeout(() => {
      // html2canvas(element)
      // .then(canvas => {
      //     canvas.style.display = 'none'
      //     document.body.appendChild(canvas)
      //     return canvas
      // })
      // .then(canvas => {
      //     const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      //     const a = document.createElement('a')
      //     a.setAttribute('download', 'analytics-image.png')
      //     a.setAttribute('href', image)
      //     a.click()
      //     canvas.remove()
      // }).catch(err => {
      //     console.log('Error taking a screenshot', err);
      // })
      toPng(element)
      .then(function (dataUrl) {
        const a = document.createElement('a')
        a.setAttribute('download', 'analytics-image.png')
        a.setAttribute('href', dataUrl)
        a.click()
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }, 1000);
}

const BasicFunctions = {
    displayTime, generateId, modifyMobileUsers, checkVal,sortArrayByElement,
    fetchUniquePages, ZipFilter, convertUserPayload,takeScreenshot
}

export default BasicFunctions;
