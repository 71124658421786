import React from 'react';/*
import {connect} from 'react-redux';*/
import SignServices from '../_services/sigin';
import {/*  withStyles,*/ TextField, Button/*, CircularProgress, FormControlLabel, Checkbox*/ }from '@material-ui/core/';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
/*import { hideMessage, showAuthLoader, userSignIn } from 'actions/Auth';*/
import { Link } from 'react-router-dom';
/*import ziplyne from 'assets/images/ziplogo.png'
*/
/*const styles = theme => ({
  content: {
    textAlign: 'center'
  },
  ziplyne: {
    fontSize: '30px',
    color: 'white',
    letterSpacing: '7px',
    width: '68%',
    marginLeft: '84px'
  },
  hr: {
    width: '5%',
    color: 'white',
    background: 'white',
    height: '05px',
  },
  login: {
    width: '77%',
    margin: '0 61px',
    height: '40px',
    borderRadius: '20px',
    color: '#9c70f9',
    fontSize: '20px',
    background: 'white',
    textAlign: 'center',
    padding: '4px'
  }
});*/

class ResetPassword extends React.Component {
  state = {
    email: '',
    password: '',
    repeatPassword:'',
  }

  onChangeHandler = (type) => {
    var name = type.target.name;
    this.setState({[name]: type.target.value})
  }

  onClickHandler = () => {
    var search = this.props.location.search;
    if (this.state.password !== this.state.repeatPassword) {
      NotificationManager.error("Password mismatch", 'Error');
      return;
    }
    // var code = tempVal.substr(tempVal.indexOf("verifylink=")).replace("verifylink=","");
    //code.replace("%257C","|");
    let arr = search.split('&');
    let data = {}
    arr.forEach(val => {
      let key = val.split('=')[0];
      let value = val.split('=')[1];
      data[key] = value;
    })

    let params = {
      id: data['id'],
      first_name: data['first_name'],
      last_name: data['last_name'],
      company_id: data['company_id'],
      username: data['username'],
      email: data['verifylink'],
      password: this.state.password
    }

    let validateTime = data['validate'].split('exp')[1];
    let currentDate = new Date().getTime();

    let timeLoop = 60 * 60 * 60 * 24;

    if ((currentDate - validateTime) < timeLoop) {
      SignServices.resetPassword(params).then(response => {
        if (response.status === 201) {
            
            NotificationManager.success('Password was changed successfully', 'Success')
            this.props.history.push('/signin');
            setTimeout(() => {
              window.location.href = "/";
            }, 2500);
                        

        }
    })
    } else {
      NotificationManager.error('Link has expired', 'Error');
    }
  }

  componentDidMount() {
    
  }


  render() {
    /*const { email, password } = this.state;
    const {showMessage, loader, alertMessage, classes} = this.props;*/
    return (
      <div className="app-background">
      <div className="app-login-container align-items-center animated slideInUpTiny animation-duration-3">
       <div className="login-content" style={{marginTop:'100px'}}>
          <div className="login-header">
            <Link className="app-logo" to="/" title="Jambo">
              <img src="./ziplyne_dropshadow.png" alt="jambo" title="jambo"/>
            </Link>
          </div>

          <div className="mb-2">
            <h2><IntlMessages id="appModule.resetYourPassword"/></h2>
          </div>

          <div className="login-form">
            <TextField fullWidth
              type="password" label={<IntlMessages id="appModule.newPassword"/>}
              margin="normal" className="mt-0 mb-4" name="password" onChange={this.onChangeHandler}/>
            <TextField fullWidth
              type="password" label={<IntlMessages id="appModule.reEnterPassword"/>}
              margin="normal" className="mt-0 mb-4" name="repeatPassword" onChange={this.onChangeHandler}/>
            <Button variant="contained" color="primary" className="text-white" onClick={this.onClickHandler}>
              <IntlMessages id="appModule.resetPassword"/>
            </Button>
              
            <br/>
            <br/>
            {/*<Link to="signin">Login ?</Link>*/}
            <NotificationContainer />
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default ResetPassword;

