import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import { login, logout } from 'actions/AuthHelperMethods';
import { SIGNIN_USER, SIGNIN_SSO, SIGNOUT_USER } from "constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess,} from "actions/Auth";


const signInUserWithEmailPasswordRequest = async (email, password, rememberme) => 
  await login(email, password, rememberme)
    .then(authUser => {
      return authUser
    })
    .catch(error => error);
  

const signOutRequest = async () =>
  await logout()
    .then(authUser => authUser)
    .catch(error => error);

const signInUsingSSORequest = () =>  {return {login: true}}

function* signInUsingSSO() {
  try {
    const sign = yield call(signInUsingSSORequest)
    if (sign.login) {
      yield put(userSignInSuccess(sign));
    } 
  } catch(error) {
    yield put(showAuthMessage("Failed"));
  }
}

function* signInUserWithEmailPassword({payload}) {
  const {email, password, rememberMe} = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password, rememberMe);
    if (signInUser.login) {
      yield put(userSignInSuccess(signInUser));
    } else {
      yield put(showAuthMessage(signInUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser.logout) {
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* signInSSO() {
  yield takeEvery(SIGNIN_SSO, signInUsingSSO);
}

export default function* rootSaga() {
  yield all([fork(signInUser),fork(signOutUser),fork(signInSSO)]);
}