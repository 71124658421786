import React, {Component} from 'react';
import { FormControl } from '@material-ui/core';
import TableComponent from 'components/Table/table';
import UserSearchSupportFunctions from './UserSearchSupportFunctions';
import ZipService from '_services/zip';
function createDataTableApi(element) {
  // let hidden = element.id;
  let frequency = "";
  if (element.clicked_guide) {
      frequency = element.guide.name;
  }
  let userName = element.user.first_name;
  let userRole = ''
  if (element.user.last_name) {
      userName += " " + element.user.last_name;
  }
  if (userName === undefined || userName === null || userName.length === 0) {
    userName = "Anonymous";
    userRole = "End User";
  }
  let userGroup = element.usergroup;
  userRole = UserSearchSupportFunctions.checkUserStatus(element);

  return {frequency, userName, userRole, userGroup};
}
function createDataTableUniqueApi(element) {
  
  let zipClicked = "";
  if (element.clicked_guide) {
    zipClicked = element.guide.name;
  }
  let userName = element.user.first_name;
  if (element.user.last_name) {
      userName += " " + element.user.last_name;
  }
  let userRole = UserSearchSupportFunctions.checkUserStatus(element);
  let frequency = element.Frequency;
  let userGroup = element.usergroup;
  return {zipClicked, userName, userRole, userGroup, frequency};
  // return {zipClicked, userName, userRole,userGroup, frequency};
}


const columnData = [
  {id: 'frequency', align: false, disablePadding: true, label: 'Zip Clicked'},
  {id: 'userName', align: true, disablePadding: false, label: 'User Name'},
  {id: 'userRole', align: true, disablePadding: false, label: 'User Role'},
  {id: 'userGroup', align: true, disablePadding: false, label: 'User Group'},
  /*{ id: 'action', label: 'Action', delete: true, edit: false }*/
];
const uniqueColumnData = [
  {id: 'zipClicked', align: false, disablePadding: false, label: 'Zip Clicked '},
  {id: 'userName', align: true, disablePadding: false, label: 'User Name'},
  {id: 'userRole', align: true, disablePadding: false, label: 'User Role'},
  {id: 'userGroup', align: true, disablePadding: false, label: 'User Group'},
  {id: 'frequency', align: true, disablePadding: false, label: 'Frequency'},
]

class UserSearchModal extends Component {
    state = {
        dataTable: [],
        uniqueDataTable: [],
        uniqueText: "Show Unique List",
        uniqueSelected : false
    }
    switchViews = () => {
      
      if (this.state.uniqueSelected) {
        this.setState({uniqueSelected: false, uniqueText: "Show all Users"})
      } else {
        this.setState({uniqueSelected: true, uniqueText: "Show Unique List"})

      }
    }
    UNSAFE_componentWillMount() {
        let tempData = [];
        for (let i = 0; i < this.props.editData.SearchDetailData.length; i++) {
            tempData.push(createDataTableApi(this.props.editData.SearchDetailData[i]))
        }
        console.log(tempData);
        var uniqueDataList = UserSearchSupportFunctions.fetchUniqueTable(this.props.editData.SearchDetailData);
        var uniqueTempTable = [];
        for (let i = 0; i < uniqueDataList.length; i++) {
          uniqueTempTable.push(createDataTableUniqueApi(uniqueDataList[i]));
        }
        console.log(uniqueTempTable)
        this.setState({dataTable: tempData, uniqueDataTable: uniqueTempTable})
      }

    render () {    
        const {SearchTerm, SearchFrequency, SearchUrl,/*SearchUsers, */SearchDetailData } = this.props.editData;
        const { uniqueDataTable,  dataTable} = this.state;
        return (
            <FormControl fullWidth>
               <h6>Search Term : {SearchTerm}</h6>
               <h6>Search Frequency : {SearchFrequency}</h6>
               <h6>Searched on URL : {SearchUrl}</h6>{/*
               <h6>Users : {SearchUsers}</h6>*/}
               <h6>Unique Users: {UserSearchSupportFunctions.getUniqueUsersNumber(SearchDetailData)}</h6>
               <h6 onClick={this.switchViews} className="selected-color hover-thing">{this.state.uniqueText}</h6>
               {this.state.uniqueSelected ? <TableComponent datas={uniqueDataTable} onClickHandler={this.onClickHandlerElement} onBulkDeleteHandler={this.bulkDeleteSelectionHandler}  _delete={this.onDeleteHandler} columns={uniqueColumnData} edit={this.onEditHandler} name={'Unique Users'}/>:
               <TableComponent datas={dataTable} onClickHandler={this.onClickHandlerElement} onBulkDeleteHandler={this.bulkDeleteSelectionHandler}  _delete={this.onDeleteHandler} columns={columnData} edit={this.onEditHandler} name={'User Details'}/> 
             }
            </FormControl>
        )
    }
}

export default UserSearchModal;