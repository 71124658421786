import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const WebApp = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/info`} />
      <Route
        path={`${match.url}/users`}
        component={asyncComponent(() => import("./routes/Users/user"))}
      />
      <Route
        path={`${match.url}/zips`}
        component={asyncComponent(() => import("./routes/Zips/zip"))}
      />
      <Route
        path={`${match.url}/contentLinks`}
        component={asyncComponent(() => import("./routes/ContentLinks/contentLinks"))}
      />
      <Route
        path={`${match.url}/ratings`}
        component={asyncComponent(() => import("./routes/Rating/rating"))}
      />
      <Route
        path={`${match.url}/snaps`}
        component={asyncComponent(() => import("./routes/Snaps/snaps"))}
      />
      <Route
        path={`${match.url}/analytics`}
        component={asyncComponent(() => import("./routes/Analytics/analytics"))}
      />
      <Route
        path={`${match.url}/analyticsNew`}
        component={asyncComponent(() =>
          import("./routes/AnalyticsNew/analytics")
        )}
      />
      <Route
        path={`${match.url}/integrations`}
        component={asyncComponent(() =>
          import("./routes/Integration/integration")
        )}
      />
      <Route
        path={`${match.url}/embedscript`}
        component={asyncComponent(() =>
          import("./routes/EmbedScript/embedScript")
        )}
      />
      <Route
        path={`${match.url}/player`}
        component={asyncComponent(() => import("./routes/Player/player"))}
      />
      {/* <Route
        path={`${match.url}/customizer`}
        component={asyncComponent(() =>
          import("./routes/Customizer/customizer")
        )}
      /> */}
      <Route
        path={`${match.url}/customizer`}
        component={asyncComponent(() =>
          import("./routes/CustomizerV2/customizer")
        )}
      />
      <Route
        path={`${match.url}/zipmonitor`}
        component={asyncComponent(() =>
          import("./routes/ZipMonitor/zipMonitor")
        )}
      />
      <Route
        path={`${match.url}/usergroup`}
        component={asyncComponent(() =>
          import("./routes/ExperienceManager/userGroup")
        )}
      />
      <Route
        path={`${match.url}/group/details`}
        component={asyncComponent(() =>
          import("./routes/ExperienceManager/groupDetails")
        )}
      />
      <Route
        path={`${match.url}/group/add`}
        component={asyncComponent(() =>
          import("./routes/ExperienceManager/addGroupDetails")
        )}
      />
      <Route
        path={`${match.url}/experience`}
        component={asyncComponent(() =>
          import("./routes/ExperienceManager/Experiences/simpleExperiences")
        )}
      />
      <Route
        path={`${match.url}/modalManager/create`}
        component={asyncComponent(() =>
          import("./routes/ModalManager/createModal")
        )}
      />
      <Route
        path={`${match.url}/modalManager`}
        component={asyncComponent(() =>
          import("./routes/ModalManager/modalManager")
        )}
      />
      <Route
        path={`${match.url}/zipContent`}
        component={asyncComponent(() =>
          import("../mobileApp/routes/Zips/ContentUpload")
        )}
      />
        <Route
        path={`${match.url}/zipManagement`}
        component={asyncComponent(() =>
          import("./routes/ZipManagement/zipManagement")
        )}
      />
       <Route
        path={`${match.url}/migrationHistory`}
        // component={asyncComponent(() =>
        //   import("./routes/ZipManagement/zipManagementHistory")
        // )}
        component={asyncComponent(() =>
          import("./routes/ZipManagement/zipManagementMain")
        )}
      />
       <Route
        path={`${match.url}/migrationKey`}
        component={asyncComponent(() =>
          import("./routes/Migration/migrationKey")
        )}
      />
      <Route
        path={`${match.url}/selectorSettings`}
        component={asyncComponent(() =>
          import("../mobileApp/routes/RoutesToIgnore/ignore")
        )}
      />
      <Route
        path={`${match.url}/assets`}
        component={asyncComponent(() =>
          import("../mobileApp/routes/UploadLogo/upload")
        )}
      />
      <Route
        path={`${match.url}/applications`}
        component={asyncComponent(() =>
          import("../mobileApp/routes/PublicCompanies/companies")
        )}
      />
      <Route
        path={`${match.url}/customers`}
        component={asyncComponent(() =>
          import("../mobileApp/routes/Companies/companies")
        )}
      />
      <Route
        path={`${match.url}/subCompanies`}
        component={asyncComponent(() =>
          import("../mobileApp/routes/SubCompanies/subcompanies")
        )}
      />
      <Route
        path={`${match.url}/shareables`}
        component={asyncComponent(() =>
          import("../mobileApp/routes/Shareables/shareable")
        )}
      />
      <Route
        path={`${match.url}/userSearches`}
        component={asyncComponent(() =>
          import("../mobileApp/routes/UserSearch/searchMetric")
        )}
      />
      <Route
        path={`${match.url}/categories&tags`}
        component={asyncComponent(() =>
          import("./routes/CategoriesAndTags/categoriesAndTags")
        )}
      />
      <Route
        path={`${match.url}/embedScriptUtility`}
          component={asyncComponent(() =>
            import("./routes/EmbedModule/index")
          )}
      />
      <Route
        path={`${match.url}/creatorUtility`}
          component={asyncComponent(() =>
            import("./routes/CreatorEmbedModule/index")
          )}
      />
      <Route
        path={`${match.url}/playerUtility`}
          component={asyncComponent(() =>
            import("./routes/PlayerEmbedModule/index")
          )}
      />
      <Route
        component={asyncComponent(() =>
          import("app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default WebApp;
