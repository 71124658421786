import React, {Component} from 'react';
import Dropzone from 'components/FileUpload/DropZone';
import { TextField, FormControl } from '@material-ui/core';

class UploadLogoModal extends Component {
    render () {
        const { LabelName } = this.props.editData;
        

        return (
            <FormControl fullWidth>
                <TextField label="Logo Label" value={LabelName} onChange={(e) => this.props.onChange('LabelName', e.target.value)}/>
                { LabelName !== '' ?  
                    <Dropzone type="asset" updatedName={LabelName} file={(e) => this.props.onChange('file', e)} acceptedFileTypes=".jpeg, .jpg, .png"/>
                    : null
                }
            </FormControl>
        )
    }
}

export default UploadLogoModal;