import React, {Component} from 'react';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import URLSearchParams from 'url-search-params'
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import "assets/vendors/style"
import purpleTheme from './themes/purpleTheme';
import AppLocale from '../lngProvider';
import MainApp from 'app/index';
import SignIn from './SignIn';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import ZipMonitor from 'app/routes/webApp/routes/ZipMonitor/zipMonitor';
import {setInitUrl, userSignInSuccess} from '../actions/Auth';
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';
import {setDarkTheme, setThemeColor} from "../actions/Setting";
import VerifyUser from './VerifyUser';

const RestrictedRoute = ({component: Component, authUser, ...rest}) => {
  return <Route
  {...rest}
  render={props =>
    authUser
      ? <Component {...props} />
      : <Redirect
        to={{
          pathname: '/signin',
          state: {from: props.location}
        }}
      />}
  />;
}
  

class App extends Component {
  UNSAFE_componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);
    if (params.has("theme-name")) {
      this.props.setThemeColor(params.get('theme-name'));
    }    
  }


  render() {
    const {match, location, locale, authUser, initURL, isDirectionRTL} = this.props;
    let applyTheme = createMuiTheme(purpleTheme);
    if (location.pathname === '/') {
      if (authUser === null) {
        return ( <Redirect to={'/signin'}/> );
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return ( <Redirect to={'/app/dashboard/homepage'}/> );
      } else {
        return ( <Redirect to={initURL}/> );
      }
    } else if (location.pathname.match('/zipmonitordetail')) {
      location.pathname = '/app/webapp/zipmonitor'
    }
    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <ThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <RTL>
              <div className="app-main">
                <Switch>
                  <RestrictedRoute path={`${match.url}app`} authUser={authUser} component={MainApp}/>
                  <Route path='/signin' component={SignIn}/>
                  <Route path='/forgotpassword' component={ForgotPassword}/>
                  <Route path='/forgot' component={ResetPassword}/>
                  <Route path='/user' component={VerifyUser} />
                  {/* <Route path='/zipmonitordetail' component={ZipMonitor}/> */}
                  <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {themeColor, sideNavColor, darkTheme, locale, isDirectionRTL} = settings;
  const {authUser, initURL} = auth;
  return {themeColor, sideNavColor, isDarkTheme: darkTheme, locale, isDirectionRTL, authUser, initURL}
};

export default connect(mapStateToProps, {setInitUrl, userSignInSuccess, setThemeColor, setDarkTheme})(App);