import React, {Component} from 'react';
import {Button} from '@material-ui/core';
import './Button.css';

class ZiplyneButton extends Component {
	state = {
		customStyle: {}
	}
	componentDidMount() {
		var customStyle ={};
		if (this.props.customWidth !== undefined && this.props.customWidth !== null) {
			customStyle.width = this.props.customWidth;
		}
		if (this.props.custommarginTop !== undefined && this.props.custommarginTop !== null) {
			customStyle.marginTop = this.props.custommarginTop;
		}
		if (this.props.custommarginLeft !== undefined && this.props.custommarginLeft !== null) {
			customStyle.marginLeft = this.props.custommarginLeft;
		}
		if (this.props.custommarginRight !== undefined && this.props.custommarginRight !== null) {
			customStyle.marginRight = this.props.custommarginRight;
		}
		if (this.props.custommarginBottom !== undefined && this.props.custommarginBottom !== null) {
			customStyle.marginBottom = this.props.custommarginBottom;
		}
		if (this.props.border !== undefined && this.props.border !== null) {
			customStyle.border = this.props.border;
		}
		if (this.props.customDisplay !== undefined && this.props.customDisplay !== null) {
			customStyle.display = this.props.customDisplay;
		}
		this.setState({customStyle: customStyle});
	}

    render () { 
        const {children, title, disabled, onClick, variant, background, color} = this.props;
        
        return <Button title={title} style={{
        	background: background ? background : "transparent",
        	color: !background && !color ? "#673ab7" : background && !color ? "#fff" : color ? color: "#673ab7",
        	boxShadow: background ? `0 0 4px ${background}` : '',
			opacity: disabled ? ".6" : "1",
			...this.state.customStyle
        }} disabled={disabled} className="main-btn" onClick={onClick}>{children}</Button>
    }
}

export default ZiplyneButton;