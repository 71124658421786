import React, {Component} from 'react';
import { TextField, FormControl } from '@material-ui/core';

class PasswordModal extends Component {
    render () {
        const {NewPassword, OldPassword, RepeatPassword, onChange } = this.props.editData;
        return (
            <FormControl fullWidth>
                {/* <TextField label="Old Password" value={OldPassword} type="password" onChange={(e) => onChange('OldPassword', e.target.value)}/> */}
                <TextField label="New Password" value={NewPassword}  type="password"  onChange={(e) => onChange('NewPassword', e.target.value)}/>
                <TextField label="Repeat New Password" value={RepeatPassword}  type="password"  onChange={(e) => onChange('RepeatPassword', e.target.value)}/>
            </FormControl>
        )
    }
}

export default PasswordModal;