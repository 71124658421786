import React, { Component } from 'react';
import { FormControl, FormControlLabel, TextField, Select, InputLabel, MenuItem, Checkbox, ListItemText, Input } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

class AddGroup extends Component {
    state = {
        users: [],
        zips: [],
        showInput: false,
        subgroups: [{ aSubgroup: "" }],
    }

    handleChange = (type, value) => {
        this.setState({ [type]: value }, () => {
            if (type === 'users') this.props.onChange('selectedUsers', this.state.users)
            else this.props.onChange('selectedZips', this.state.zips)
        })
    };

    handleSubgroupChange = idx => event => {
        const newSubgroup = this.state.subgroups.map((aSubgroup, sidx) => {
            if (idx !== sidx) return aSubgroup;
            return { ...aSubgroup, aSubgroup: event.target.value };
        });
        this.setState({ subgroups: newSubgroup });
        this.props.onChange('subGroupName', newSubgroup)
    };

    handleAddSubgroup = (event) => {
        if (event.key === 'Enter') {
            this.setState({
                subgroups: this.state.subgroups.concat([{ aSubgroup: "" }])
            });
        }
    };

    handleRemoveSubgroup = idx => () => {
        const newSubgroup = this.state.subgroups?.filter((_, i) => {
            return idx !== i;
        });
        this.setState({ subgroups: newSubgroup })
        this.props.onChange('subGroupName', newSubgroup)
    };

    render() {
        const { onChange, type, data } = this.props;
        const { showInput, subgroups } = this.state;
        return (
            <div className="addParentGroup-container">
                <FormControl fullWidth>
                    {type === 'addParentGroup' ?
                        <>
                            <TextField label="Group Name" onChange={(e) => onChange('parentGroupName', e.target.value)} />
                            <FormControlLabel
                                style={{ flexDirection: 'row', marginLeft: '0px' }}
                                value={showInput}
                                control={<Checkbox color="primary" onChange={() => this.setState({ showInput: !showInput })} />}
                                label="Add Sub-Group"
                                labelPlacement="start"
                                className="addSubgroup-label"
                            />
                            {/* {showInput ? <TextField label="Sub-Group Name" onChange={(e) => onChange('subGroupName', e.target.value)} /> : null} */}
                            <div className="addSubgroups-container">
                                {showInput ?
                                    subgroups.map((aSubgroup, idx) => (
                                        <div className="addSubgroups-row" key={idx}>
                                            <TextField
                                                className="addGroup-form"
                                                autoFocus
                                                label="Sub-Group Name"
                                                helperText="Press Enter to add new sub group"
                                                value={aSubgroup.aSubgroup}
                                                onChange={this.handleSubgroupChange(idx)}
                                                onKeyPress={this.handleAddSubgroup}

                                            />
                                            <button
                                                type="button"
                                                onClick={this.handleRemoveSubgroup(idx)}
                                                className="deleteSubgroup-Btn">
                                                <DeleteOutlineIcon />
                                            </button>
                                        </div>
                                    )) : null}
                            </div>
                        </>
                        : type === 'addSubGroups' ?
                            <TextField label="Sub Group Name" onChange={(e) => onChange('subGroupName', e.target.value)} />
                            : type === 'addUsers' ?
                                <>
                                    <InputLabel>Select Users</InputLabel>
                                    <Select
                                        multiple
                                        value={this.state.users}
                                        onChange={(e) => this.handleChange('users', e.target.value)}
                                        input={<Input />}
                                        renderValue={selected => selected.join(', ')}
                                    >
                                        {data.map(user => (
                                            <MenuItem key={user.Id} value={user.Id}>
                                                <Checkbox checked={this.state.users.indexOf(user.Id) > -1} />
                                                <ListItemText primary={`${user.FirstName} ${user.LastName}`} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </>
                                : type === 'addZips' ?
                                    <>
                                        <InputLabel>Select Zips</InputLabel>
                                        <Select
                                            multiple
                                            value={this.state.zips}
                                            onChange={(e) => this.handleChange('zips', e.target.value)}
                                            input={<Input />}
                                            renderValue={selected => selected.join(', ')}
                                        >
                                            {data.map(zip => (
                                                <MenuItem key={zip.Id} value={zip.Id}>
                                                    <Checkbox checked={this.state.zips.indexOf(zip.Id) > -1} />
                                                    <ListItemText primary={zip.Name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </>
                                    : null}
                </FormControl>
            </div>
        )
    }
}

export default AddGroup;