import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import images from "./images";
import { userSignOut } from "actions/Auth";
import { connect } from "react-redux";
import {
  isSuperAdmin,
  getMobileActive,
  getWebActive,
} from "actions/AuthHelperMethods";
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';

class SidenavContent extends Component {
  _isMounted = false;

  state = {
    isWebMode: true,
    mobileActive: false,
    webActive: false,
  };

  componentDidMount() {
    this._isMounted = true;
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path
    if (window.location.href.includes("/mobileApp/")) {
      this.setState({ isWebMode: false });
    }

    /*if (pathname.match('/app/mobileApp')) {
    }*/
          var webActive = (getWebActive() === "true" || getWebActive() === true)? true: false;
      var mobileActive = (getMobileActive() === "true" || getMobileActive() === true)? true: false;
     if(mobileActive === true) {
        this.setState({mobileActive: true});
        
      }
      if(webActive === true) {
        this.setState({webActive: true});
        

      }
    if (!(isSuperAdmin() !== undefined && isSuperAdmin() !== null && isSuperAdmin() === true)) {
      if (mobileActive === true && webActive !== true) {
        this.setState({ isWebMode: false });
        this.props.history.push({
          pathname: "/app/mobileApp/dashboard/homepage",
        });
      }
    }

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        const parentLiEle = that.closest(this, "li");
        if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains("open")) {
            menuLi[i].classList.remove("open", "active");
          } else {
            menuLi[i].classList.add("open", "active");
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, "li");
            if (
              menuLi[j] !== this &&
              (parentLi === null || !parentLi.classList.contains("open"))
            ) {
              menuLi[j].classList.remove("open");
            } else {
              if (menuLi[j].classList.contains("open")) {
                menuLi[j].classList.remove("open");
              } else {
                menuLi[j].classList.add("open");
              }
            }
          }
        }
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "li"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  handleViewHandler = (event) => {
    this.setState({ isWebMode: !event.target.checked });
  };

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function (fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let webModeVisible = this.state.isWebMode ? {} : { display: "none" };
    let mobileModeVisible = !this.state.isWebMode ? {} : { display: "none" };
    return (
      <>
        {/* <FormGroup
          aria-label="position"
          row
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <span>Web</span>
          <Switch
            onChange={this.handleViewHandler}
            disabled={
              !(
                isSuperAdmin() !== undefined &&
                isSuperAdmin() !== null &&
                isSuperAdmin() === true
              ) &&
              (this.state.webActive !== true ||
                this.state.mobileActive !== true)
            }
            value={this.state.isWebMode}
            checked={!this.state.isWebMode}
          />
          <span>Mobile</span>
        </FormGroup> */}

        {/* <CustomScrollbars className="scrollbar"> */}
          <ul className="nav-menu scroll" style={webModeVisible}>
            <li className="nav-header">
              <IntlMessages id="sidebar.web" />
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/dashboard/homepage">
                <img
                  className="zmdi zmdi-hc-fw"
                  src={images.dashboardOverview}
                  alt=""
                />
                <span className="nav-text">
                  <IntlMessages id="sidebar.dashboardOverview" />
                </span>
              </NavLink>
            </li>
            <li className="menu collapse-box">
              <Button>
                <img className="zmdi zmdi-hc-fw" src={images.setup} alt="" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.setup" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink to="/app/webapp/embedscript">
                    <img
                      className="zmdi zmdi-hc-fw"
                      src={images.embed}
                      alt=""
                    />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.embedscript" />
                    </span>
                  </NavLink>
                </li>
                <li>
                <NavLink to="/app/webapp/player">
                  <img className="zmdi zmdi-hc-fw" src={images.player} alt=""/>
                  <span className="nav-text"><IntlMessages id="sidebar.player"/></span>
                </NavLink>
                {/* <NavLink to="/app/webapp/migrationKey">
                  <img className="zmdi zmdi-hc-fw" src={images.player} alt=""/>
                  <span className="nav-text"><IntlMessages id="sidebar.keySetup"/></span>
                </NavLink> */}
              </li>
              <li>
                <NavLink to="/app/webapp/embedScriptUtility">
                  <img
                    className="zmdi zmdi-hc-fw"
                    src={images.embedUtils}
                    alt=""
                  />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.embedModule" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/webapp/creatorUtility">
                  <img
                    className="zmdi zmdi-hc-fw"
                    src={images.embedUtils}
                    alt=""
                  />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.creatorEmbedModule" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/webapp/playerUtility">
                  <img
                    className="zmdi zmdi-hc-fw"
                    src={images.embedUtils}
                    alt=""
                  />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.playerEmbedModule" />
                  </span>
                </NavLink>
              </li>
              </ul>
            </li>
            {
              (isSuperAdmin() !== undefined && isSuperAdmin() !== null && isSuperAdmin() === true) && <li className="menu collapse-box">
                <Button>
                  <img className="zmdi zmdi-hc-fw" src={images.companies} alt="" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.companies" />
                  </span>
                </Button>
                <ul className="sub-menu">
                  {
                    (isSuperAdmin() !== undefined && isSuperAdmin() !== null && isSuperAdmin() === true) ? <li><NavLink to="/app/webapp/customers">
                        <img className="zmdi zmdi-hc-fw" src={images.companies} alt=""/>
                        <span className="nav-text"><IntlMessages id="sidebar.companiesNew"/></span>
                      </NavLink></li> : null
                    }
                  {
                    (isSuperAdmin() !== undefined && isSuperAdmin() !== null && isSuperAdmin() === true) ? <li><NavLink to="/app/webapp/subCompanies">
                      <img className="zmdi zmdi-hc-fw" src={images.companies} alt=""/>
                      <span className="nav-text"><IntlMessages id="sidebar.subCompaniesNew"/></span>
                    </NavLink></li> : null
                  }
                </ul>
              </li>
            }
            {/* Zips */}
            <li className="menu collapse-box">
              <Button>
                <img className="zmdi zmdi-hc-fw" src={images.zips} alt="" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.zips" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
              <NavLink to="/app/webapp/zips">
                <img className="zmdi zmdi-hc-fw" src={images.zips} alt=""/>
                <span className="nav-text"><IntlMessages id="sidebar.zips"/></span>
              </NavLink>
              </li>
              <li>
              <NavLink to="/app/webapp/ratings">
                <img className="zmdi zmdi-hc-fw" src={images.rating} alt=""/>
                <span className="nav-text"><IntlMessages id="sidebar.rating"/></span>
              </NavLink>
              <NavLink to="/app/webapp/contentLinks">
                <img className="zmdi zmdi-hc-fw" src={images.contentLinks} alt=""/>
                <span className="nav-text"><IntlMessages id="sidebar.contentLinks"/></span>
              </NavLink>
              <NavLink to="/app/webapp/zipContent">
                <img className="zmdi zmdi-hc-fw" src={images.zipContent} alt=""/>
                <span className="nav-text"><IntlMessages id="sidebar.uploads"/></span>
              </NavLink>
              <NavLink to="/app/webapp/assets">
              <img className="zmdi zmdi-hc-fw" src={images.assets} alt=""/>
                <span className="nav-text"><IntlMessages id="sidebar.uploadLogo"/></span>
              </NavLink>
              <NavLink to="/app/webapp/shareables">
                  
              <img className="zmdi zmdi-hc-fw" src={images.shareable} alt=""/>
                <span className="nav-text"><IntlMessages id="sidebar.shareable"/></span>
              </NavLink>
             
              {/* {
               (isSuperAdmin() !== undefined && isSuperAdmin() !== null && isSuperAdmin() === true) ? <NavLink to="/app/webapp/applications">
              <img className="zmdi zmdi-hc-fw" src={images.application} alt=""/>
                <span className="nav-text"><IntlMessages id="sidebar.companies"/></span>
              </NavLink>: null
              } */}
              {/* {
               (isSuperAdmin() !== undefined && isSuperAdmin() !== null && isSuperAdmin() === true) ? <NavLink to="/app/webapp/selectorSettings">
                <img className="zmdi zmdi-hc-fw" src={images.selectorSettings} alt=""/>
                <span className="nav-text"><IntlMessages id="sidebar.routeToIgnoreList"/></span>
              </NavLink> : null
              } */}
              </li>
              </ul>
            </li>

            <li className="menu no-arrow">
             <NavLink to="/app/webapp/categories&tags">
                <img className="zmdi zmdi-hc-fw" src={images.tag} alt=""/>
                <span className="nav-text"><IntlMessages id="sidebar.Category"/></span>
              </NavLink>
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/webapp/users">
                <img className="zmdi zmdi-hc-fw" src={images.users} alt="" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.users" />
                </span>
              </NavLink>
            </li>
            <li className="menu no-arrow">
              {/* <Button> */}
              <NavLink to="/app/webapp/customizer">
                <img
                  className="zmdi zmdi-hc-fw"
                  src={images.customizer}
                  alt=""
                />
                <span className="nav-text">
                  <IntlMessages id="sidebar.onboardingV2" />
                </span>
              </NavLink>
              {/* <NavLink to="/app/webapp/customizerV2">
                <img
                  className="zmdi zmdi-hc-fw"
                  src={images.customizerV2}
                  alt=""
                />
                <span className="nav-text">
                  <IntlMessages id="sidebar.onboardingV2" />
                </span>
              </NavLink> */}
              {/* </Button> 
              <ul className="sub-menu">
                
                 <li>
                  <NavLink to="/app/mobileApp/zips/onboarding">
                    <i className="zmdi zmdi-email zmdi-hc-fw"/>
                    <span className="nav-text"><IntlMessages id="sidebar.themeManager"/></span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/mobileApp/zips/onboarding">
                    <i className="zmdi zmdi-email zmdi-hc-fw"/>
                    <span className="nav-text"><IntlMessages id="sidebar.template"/></span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/mobileApp/zips/onboarding">
                    <i className="zmdi zmdi-email zmdi-hc-fw"/>
                    <span className="nav-text"><IntlMessages id="sidebar.onboarding"/></span>
                  </NavLink>
                </li> 

              </ul>*/}
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/webapp/usergroup">
                <img
                  className="zmdi zmdi-hc-fw"
                  src={images.expManager}
                  alt=""
                />
                <span className="nav-text">
                  <IntlMessages id="sidebar.experiencemanager" />
                </span>
              </NavLink>
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/webapp/modalManager">
                <img
                  className="zmdi zmdi-hc-fw"
                  src={images.modalManager}
                  alt=""
                />
                <span className="nav-text">
                  <IntlMessages id="sidebar.modalmanager" />
                </span>
              </NavLink>
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/webapp/snaps">
                <img className="zmdi zmdi-hc-fw" src={images.snap} alt="" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.snaps" />
                </span>
              </NavLink>
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/webapp/migrationHistory">
                  
                  <img className="zmdi zmdi-hc-fw" src={images.migrationHistory} alt=""/>
                    <span className="nav-text"><IntlMessages id="sidebar.migrationHistory"/></span>
              </NavLink>
            </li>

            <li className="menu no-arrow">
              <NavLink to="/app/webapp/zipmonitor">
                <img
                  className="zmdi zmdi-hc-fw"
                  src={images.zipMonitor}
                  alt=""
                />
                <span className="nav-text">
                  <IntlMessages id="sidebar.zipmonitor" />
                </span>
              </NavLink>
            </li>
            <li className="menu collapse-box">
              <Button>
                <img
                  className="zmdi zmdi-hc-fw"
                  src={images.analytics}
                  alt=""
                />
                <span className="nav-text">
                  <IntlMessages id="sidebar.analytics" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink to="/app/webapp/analytics">
                    <img
                      className="zmdi zmdi-hc-fw menu no-arrow"
                      src={images.analytics}
                      alt=""
                    />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.analytics" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/webapp/userSearches">
                    <img
                      className="zmdi zmdi-hc-fw"
                      src={images.users}
                      alt=""
                    />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.userSearch" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="menu no-arrow">
              <NavLink to="/app/webapp/integrations">
                <img
                  className="zmdi zmdi-hc-fw"
                  src={images.integration}
                  alt=""
                />
                <span className="nav-text">
                  <IntlMessages id="sidebar.integrations" />
                </span>
              </NavLink>
            </li>
            
          </ul>

          <ul className="nav-menu" style={mobileModeVisible}>
            <li className="nav-header">
              <IntlMessages id="sidebar.mobile" />
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/mobileApp/dashboard/homepage">
                <img
                  className="zmdi zmdi-hc-fw"
                  src={images.dashboardOverview}
                  alt=""
                />
                <span className="nav-text">
                  <IntlMessages id="sidebar.dashboardOverview" />
                </span>
              </NavLink>
            </li>
            <li className="menu collapse-box">
              <Button>
                <img className="zmdi zmdi-hc-fw" src={images.apps} alt="" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.mobileApps" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink to="/app/mobileApp/info">
                    <img
                      className="zmdi zmdi-hc-fw"
                      src={images.appInfo}
                      alt=""
                    />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.appInfo" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/mobileApp/setup">
                    <img
                      className="zmdi zmdi-hc-fw"
                      src={images.phoneSettings}
                      alt=""
                    />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.setup" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="menu collapse-box">
              <Button>
                <img className="zmdi zmdi-hc-fw" src={images.zips} alt="" />

                <span className="nav-text">
                  <IntlMessages id="sidebar.zips" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink to="/app/mobileApp/zips">
                    <img className="zmdi zmdi-hc-fw" src={images.zips} alt="" />

                    <span className="nav-text">
                      <IntlMessages id="sidebar.zips" />
                    </span>
                  </NavLink>
                </li>
                {/*<li>
                 <NavLink to="/app/webapp/ratings">
                   <img className="zmdi zmdi-hc-fw" src={images.rating} alt=""/>
                  <span className="nav-text"><IntlMessages id="sidebar.rating"/></span>
                </NavLink>
                </li>*/}
                <li>
                  <NavLink to="/app/mobileApp/screens">
                    <img
                      className="zmdi zmdi-hc-fw"
                      src={images.screens}
                      alt=""
                    />

                    <span className="nav-text">
                      <IntlMessages id="sidebar.screens" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/mobileApp/elements">
                    <img
                      className="zmdi zmdi-hc-fw"
                      src={images.elements}
                      alt=""
                    />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.elements" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/mobileApp/zipContent">
                    <img
                      className="zmdi zmdi-hc-fw"
                      src={images.zipContent}
                      alt=""
                    />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.uploads" />
                    </span>
                  </NavLink>

                  <NavLink to="/app/mobileApp/shareables">
                    <img
                      className="zmdi zmdi-hc-fw"
                      src={images.shareable}
                      alt=""
                    />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.shareable" />
                    </span>
                  </NavLink>
                </li>
                {/*<li>
                  <NavLink to="/">
                    <i className="zmdi zmdi-email zmdi-hc-fw"/>
                    <span className="nav-text"><IntlMessages id="sidebar.sharable"/></span>
                  </NavLink>
                </li>*/}
              </ul>
            </li>
            <li className="menu collapse-box">
              <Button>
                <img className="zmdi zmdi-hc-fw" src={images.zips} alt="" />

                <span className="nav-text">
                  <IntlMessages id="sidebar.uploadLogo" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink to="/app/mobileApp/logo">
                    <img
                      className="zmdi zmdi-hc-fw"
                      src={images.assets}
                      alt=""
                    />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.upload" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/mobileApp/assets">
                    <img
                      className="zmdi zmdi-hc-fw"
                      src={images.assets}
                      alt=""
                    />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.uploadLogo" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/app/mobileApp/users">
                <img className="zmdi zmdi-hc-fw" src={images.users} alt="" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.users" />
                </span>
              </NavLink>

              <NavLink to="/app/mobileApp/customizer">
                <img
                  className="zmdi zmdi-hc-fw"
                  src={images.customizer}
                  alt=""
                />
                <span className="nav-text">
                  <IntlMessages id="sidebar.onboading" />
                </span>
              </NavLink>

              <NavLink to="/app/mobileApp/analytics">
                <img
                  className="zmdi zmdi-hc-fw"
                  src={images.analytics}
                  alt=""
                />

                <span className="nav-text">
                  <IntlMessages id="sidebar.analytics" />
                </span>
              </NavLink>
              {/*<NavLink to="/app/mail">
                <i className="zmdi zmdi-email zmdi-hc-fw"/>
                <span className="nav-text"><IntlMessages id="sidebar.snaps"/></span>
              </NavLink>
              <NavLink to="/app/mail">
                <i className="zmdi zmdi-email zmdi-hc-fw"/>
                <span className="nav-text"><IntlMessages id="sidebar.settings"/></span>
              </NavLink>*/}
            </li>
          </ul>
        {/* </CustomScrollbars> */}
        <ul className="nav-menu">
          <li className="menu no-arrow">
            <div
              onClick={() => {
                this.props.userSignOut();
              }}
              style={{ cursor: "pointer", marginBottom: "10px" }}
            >
              <img className="zmdi zmdi-hc-fw" src={images.logout} alt="" />
              <span className="nav-text">
                <IntlMessages id="popup.logout" />
              </span>
            </div>
          </li>
        </ul>
      </>
    );
  }
}

export default withRouter(connect(() => {}, { userSignOut })(SidenavContent));
