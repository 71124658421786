import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const SelectMultipleModal = (props) => {
    const { selectedDomains, allUrls, onChange } = props.editData;
    console.log({ selectedDomains, allUrls, onChange })
    return (
        <Autocomplete
            multiple
            id="combo-box-demo"
            style={{ margin: "2.5rem 0" }}
            options={allUrls}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Select a URL" />}
            getOptionLabel={(option) => option}
            onChange={(e, value) => onChange('selectedDomains', value)}
            value={selectedDomains}
        />
        )
}

export default SelectMultipleModal
