const BUILD_ENV = "HopkinsProduction2";
// BUILD_ENV
// ZiplyneStaging, ZiplyneTesting, ZiplyneDemo, ZiplyneProduction, HopkinsTesting, HopkinsStaging, HopkinsProduction, local
const VERSION = "5.1.0";

export const showUrls = [
  "dashboard.mrsziplyneweb.hosts.jhmi.edu",
  "staging.mrsziplyneweb.hosts.jhmi.edu",
  "dashboard.mrmpzipweb01.hosts.jhmi.edu",
  "dashboard.mrmpzipweb02.hosts.jhmi.edu"
];

const allConfigs = {
  ZiplyneStaging: {
    BaseUrl: "https://stagingapi.ziplyne.com/api/",
    MobileBaseUrl: "https://stagingapi.ziplyne.com/mobileApi/",
    UploadFilePath: "https://stagingapi.ziplyne.com/",
    ImagePath: "https://stagingapi.ziplyne.com/",
    SEcondaryImagePath: "https://cdn.ziplyne.com/uploads/",
    MobileUploadFilePath: "https://stagingapi.ziplyne.com/",
    MobileBaseUrlSignin: "https://stagingapi.ziplyne.com/api/",
    SessionBaseUrl: "https://stagingapi.ziplyne.com/",
    LocalImagePath: "https://staging.ziplyne.com/",
    CDNURL: "https://cdn.ziplyne.com/",
    snapPrefix: "",
    name: "Staging",
    headerTitle: "Ziplyne Staging"
  },

  ZiplyneTesting: {
    BaseUrl: "https://testapi.ziplyne.com/api/v2/",
    MobileBaseUrl: "https://testapi.ziplyne.com/api/v2/",
    UploadFilePath: "https://testapi.ziplyne.com/",
    ImagePath: "https://testapi.ziplyne.com/api/v2/",
    SEcondaryImagePath: "https://cdn.ziplyne.com/",
    MobileUploadFilePath: "https://testapi.ziplyne.com/api/v2/",
    MobileBaseUrlSignin: "https://testapi.ziplyne.com/api/v2/signin",
    SessionBaseUrl: "https://testapi.ziplyne.com/api/v2/",
    LocalImagePath: "http://test.ziplyne.com/",
    CDNURL: "https://cdn.ziplyne.com/",
    snapPrefix: "uploads/",
    name: "Ziplyne-Test",
    headerTitle: "Ziplyne Test"
  },

  ZiplyneDemo: {
    BaseUrl: "https://demoapi.ziplyne.com/api/v2/",
    MobileBaseUrl: "https://demoapi.ziplyne.com/api/v2/",
    UploadFilePath: "https://demoapi.ziplyne.com/",
    ImagePath: "https://demoapi.ziplyne.com/api/v2/",
    SEcondaryImagePath: "https://cdn.ziplyne.com/",
    MobileUploadFilePath: "https://demoapi.ziplyne.com/api/v2/",
    MobileBaseUrlSignin: "https://demoapi.ziplyne.com/api/v2/signin",
    SessionBaseUrl: "https://demoapi.ziplyne.com/api/v2/",
    LocalImagePath: "https://demo.ziplyne.com/",
    CDNURL: "https://cdn.ziplyne.com/",
    snapPrefix: "uploads/",
    name: "Demo",
    headerTitle: "Ziplyne Demo"
  },

  ZiplyneProduction: {
    BaseUrl: "https://api.ziplyne.com/api/v2/",
    MobileBaseUrl: "https://api.ziplyne.com/api/v2/",
    UploadFilePath: "https://api.ziplyne.com/",
    ImagePath: "https://api.ziplyne.com/api/v2/",
    SEcondaryImagePath: "https://cdn.ziplyne.com/",
    MobileUploadFilePath: "https://api.ziplyne.com/api/v2/",
    MobileBaseUrlSignin: "https://api.ziplyne.com/api/v2/signin",
    SessionBaseUrl: "https://api.ziplyne.com/api/v2/",
    LocalImagePath: "https://customer.ziplyne.com/",
    CDNURL: "https://cdn.ziplyne.com/",
    snapPrefix: "uploads/",
    name: "Production",
    headerTitle: "Ziplyne Production"
  },

  HopkinsStaging: {
    BaseUrl: "https://stagingapi.mrsziplyneweb.hosts.jhmi.edu/api/v2/",
    MobileBaseUrl: "https://stagingapi.mrsziplyneweb.hosts.jhmi.edu/api/v2/",
    UploadFilePath: "https://stagingapi.mrsziplyneweb.hosts.jhmi.edu/",
    ImagePath: "https://stagingapi.mrsziplyneweb.hosts.jhmi.edu/api/v2/",
    SEcondaryImagePath: "https://stagingapi.mrsziplyneweb.hosts.jhmi.edu/assets/",
    MobileUploadFilePath: "https://stagingapi.mrsziplyneweb.hosts.jhmi.edu/api/v2/",
    MobileBaseUrlSignin: "https://stagingapi.mrsziplyneweb.hosts.jhmi.edu/api/v2/signin",
    SessionBaseUrl: "https://stagingapi.mrsziplyneweb.hosts.jhmi.edu/api/v2/",
    LocalImagePath: "http://staging.mrsziplyneweb.hosts.jhmi.edu/",
    CDNURL: "https://stagingapi.mrsziplyneweb.hosts.jhmi.edu/assets/",
    snapPrefix: "",
    name: "Hopkins-Staging",
    headerTitle: "Hopkins Staging for Ziplyne"
  },

  HopkinsTesting: {
    BaseUrl: "https://api.mrsziplyneweb.hosts.jhmi.edu/api/v2/",
    MobileBaseUrl: "https://api.mrsziplyneweb.hosts.jhmi.edu/api/v2/",
    UploadFilePath: "https://testapi.ziplyne.com/",
    ImagePath: "https://api.mrsziplyneweb.hosts.jhmi.edu/api/v2/",
    SEcondaryImagePath: "https://api.mrsziplyneweb.hosts.jhmi.edu/assets/",
    MobileUploadFilePath: "https://api.mrsziplyneweb.hosts.jhmi.edu/api/v2/",
    MobileBaseUrlSignin: "https://api.mrsziplyneweb.hosts.jhmi.edu/api/v2/signin",
    SessionBaseUrl: "https://api.mrsziplyneweb.hosts.jhmi.edu/api/v2/",
    LocalImagePath: "http://test.ziplyne.com/",
    CDNURL: "https://api.mrsziplyneweb.hosts.jhmi.edu/assets/",
    snapPrefix: "",
    name: "Hopkins-Testing",
    headerTitle: "Hopkins Test for Ziplyne"
  },

  HopkinsProduction: {
    BaseUrl: "https://api.mrmpzipweb01.hosts.jhmi.edu/api/v2/",
    MobileBaseUrl: "https://api.mrmpzipweb01.hosts.jhmi.edu/api/v2/",
    UploadFilePath: "https://api.mrmpzipweb01.hosts.jhmi.edu/",
    ImagePath: "https://api.mrmpzipweb01.hosts.jhmi.edu/api/v2/",
    SEcondaryImagePath: "https://api.mrmpzipweb01.hosts.jhmi.edu/assets/",
    MobileUploadFilePath: "https://api.mrmpzipweb01.hosts.jhmi.edu/api/v2/",
    MobileBaseUrlSignin: "https://api.mrmpzipweb01.hosts.jhmi.edu/api/v2/signin",
    SessionBaseUrl: "https://api.mrmpzipweb01.hosts.jhmi.edu/api/v2/",
    LocalImagePath: "https://dashboard.mrmpzipweb01.hosts.jhmi.edu/",
    CDNURL: "https://api.mrmpzipweb01.hosts.jhmi.edu/assets/",
    snapPrefix: "",
    name: "Hopkins-Production",
    headerTitle: "Hopkins Production for Ziplyne"
  },

  HopkinsProduction2: {
    BaseUrl: "https://api.mrmpzipweb02.hosts.jhmi.edu/api/v2/",
    MobileBaseUrl: "https://api.mrmpzipweb02.hosts.jhmi.edu/api/v2/",
    UploadFilePath: "https://api.mrmpzipweb02.hosts.jhmi.edu/",
    ImagePath: "https://api.mrmpzipweb02.hosts.jhmi.edu/api/v2/",
    SEcondaryImagePath: "https://api.mrmpzipweb02.hosts.jhmi.edu/assets/",
    MobileUploadFilePath: "https://api.mrmpzipweb02.hosts.jhmi.edu/api/v2/",
    MobileBaseUrlSignin: "https://api.mrmpzipweb02.hosts.jhmi.edu/api/v2/signin",
    SessionBaseUrl: "https://api.mrmpzipweb02.hosts.jhmi.edu/api/v2/",
    LocalImagePath: "https://dashboard.mrmpzipweb02.hosts.jhmi.edu/",
    CDNURL: "https://cdn.mrmpzipweb02.hosts.jhmi.edu/assets/",
    snapPrefix: "",
    name: "Hopkins-Production2",
    headerTitle: "Hopkins Production 2 for Ziplyne"
  },

  // local: {
  //   // local config
  //   BaseUrl: "http://localhost:30061/api/",
  //   UploadFilePath: "http://localhost:30061/",
  //   MobileUploadFilePath: "http://localhost:30061/",
  //   MobileBaseUrlSignin: "http://localhost:30061/api/",
  //   MobileBaseUrl: "http://localhost:30061/mobileApi/",
  //   ImagePath: "http://localhost:30061/",
  //   SEcondaryImagePath: "http://localhost:30061",
  //   LocalImagePath: "http://localhost:30061/",
  //   SessionBaseUrl: "http://localhost:30061/",
  // },

  local: {
    // local config
    BaseUrl: "http://localhost:3004/api/v2/",
    UploadFilePath: "http://localhost:3004/",
    MobileUploadFilePath: "http://localhost:3004/",
    MobileBaseUrlSignin: "http://localhost:3004/api/v2",
    MobileBaseUrl: "http://localhost:3004/mobileApi/",
    ImagePath: "http://localhost:3000/",
    SEcondaryImagePath: "http://localhost:3004",
    LocalImagePath: "http://localhost:3001/",
    SessionBaseUrl: "http://localhost:3004/",
    name: "Local",
    headerTitle: "Local"
  },
};
const Config = {
  // SENTRY Config
  REACT_APP_SENTRY_RELEASE: VERSION,
  REACT_APP_ENVIRONMENT: BUILD_ENV,
  ...allConfigs[BUILD_ENV],
};

export const environmentApi = {
  "Ziplyne-Test": "https://testapi.ziplyne.com/api/v2/",
  "Staging": "https://stagingapi.ziplyne.com/api/v2/",
  "Demo": "https://demoapi.ziplyne.com/api/v2/",
  "Production": "https://api.ziplyne.com/api/v2/",
  "Hopkins-Testing": "https://api.mrsziplyneweb.hosts.jhmi.edu/api/v2/",
  "Hopkins-Staging": "https://stagingapi.mrsziplyneweb.hosts.jhmi.edu/api/v2/",
  "Hopkins-Production": "https://api.mrmpzipweb01.hosts.jhmi.edu/api/v2/",
  "Local": "http://localhost:3004/api/v2/"
}

export const typeIds = {
  status: [
    {
      id: '3b74c80a-21c8-11ec-9621-0242ac130002',
      name: 'draft'
    },
    {
      id: '4b86ea84-21c8-11ec-9621-0242ac130002',
      name: 'live'
    },
    {
      id: 'a73b308b-4827-4ad5-9abe-12e90fc39745',
      name: 'test'
    },
    {
      id: 'e5fadea5-89a3-4717-bb46-8efbd7fbe796',
      name: 'archived'
    }
  ],
  attribute: [
    {
      id: 'd9e6a534-4893-43dc-b20d-ba140aff3410',
      name: 'modal'
    },
    {
      id: '0de98103-d4ff-4aa1-a4f2-9b15f02898c8',
      name: 'content-link'
    },
    {
      id: '66415929-08a7-487d-8bd9-787748097ecb',
      name: 'multiple-domains'
    },
    {
      id: '13c40285-eba6-431f-806d-142068c5d5db',
      name: 'zip'
    },
    {
      id: 'f7cf3709-0c7c-49f7-8e33-9138609b2d86',
      name: 'autoplay'
    },
    {
      id: '04434b93-eed0-4e24-8521-46096bec4e79',
      name: 'flagged'
    }
  ],
  guideType: [
    {
      id: '1ad97f58-d826-4bd4-839f-407be3cf73f0',
      name: 'pulse'
    },
    {
      id: '6d9b39a8-80fa-4053-8814-56440444320d',
      name: 'tooltip'
    },
    {
      id: '587d61ed-9522-4c71-a67e-b881da62e645',
      name: 'modal'
    },
    {
      id: '34b20607-1f41-4e32-859d-3ed08577965b',
      name: 'zip'
    },
    {
      id: '3fc2208d-4bec-484d-8fc0-d367d214eecd',
      name: 'content-link'
    }
  ],
  roles: [
    {
      id: '02075499-3714-499e-8f12-c0e74fadc1d4',
      name: 'admin'
    },
    {
      id: '300b0edb-dabe-4e72-9f90-7e25cd2f0f28',
      name: 'tester'
    },
    {
      id: '798a47ae-388b-44ac-91b4-09d135ede37c',
      name: 'creator'
    },
    {
      id: '9caec0dd-ac62-4144-b0bc-83b7ac397de0',
      name: 'user'
    },
    {
      id: 'e0ee1d97-9060-48a0-b8cf-4a7989e1b37d',
      name: 'superadmin'
    },
    {
      id: '73a84a4b-438f-4302-8ab0-05a9f365e262',
      name: 'analyst'
    }
  ]
}

export default Config;