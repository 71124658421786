import React from 'react'
import { useTheme } from '@mui/material/styles';
import {
    Autocomplete,
    OutlinedInput,
    InputLabel,
    MenuItem,
    FormControl,
    Select
} from '@mui/material';
import { TextField, List, ListItem, ListItemSecondaryAction, ListItemText, Switch } from '@material-ui/core';
import ZiplyneButton from 'components/Button/Button';
import { typeIds } from 'config';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const customStyles = {
    margin: ".6rem 0"
}

class AddSubCompaniesModal extends React.Component {
    state = {}

    render() {
        const { allCompanyData, selectedCompany, onChange, Username, FirstName, LastName, Email, Password, ownerName, selectedStatus,
        WebActive, MobileActive, ZiplyneSnap, SiteSnap, CustomizeLogo, AdvancedAnalytics, ownerType, } = this.props.editData;
        
        const draftId = typeIds.status.filter(el => el.name === 'draft')[0].id;
        const testId = typeIds.status.filter(el => el.name === 'test')[0].id;
        const liveId = typeIds.status.filter(el => el.name === 'live')[0].id;

         return (
            <div>
                <FormControl sx={{ width: "100%" }}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={allCompanyData}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} label="Select the parent company" />}
                        getOptionLabel={(option) => `${option.parentCompanyName}`}
                        onChange={(e, value) => onChange('selectedCompany', value)}
                    />
                    {
                        selectedCompany && <><TextField 
                            label="Add SubCompany Name" 
                            id="outlined-size-normal" 
                            defaultValue=""
                            fullWidth
                            style={customStyles}
                            onChange={(e) => onChange('subCompanyName', e.target.value)}
                        />
                        <h3>Do you want to keep {ownerName} as the owner or want to create a new one?</h3>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "1rem 0"
                        }}>
                            <ZiplyneButton 
                                background="#673ab7"
                                custommarginLeft="1rem"
                                customWidth="45%"
                                onClick={() => onChange('ownerType', 'current')}
                                >Keep
                            </ZiplyneButton>
                            <ZiplyneButton 
                                background="#673ab7"
                                custommarginLeft="1rem"
                                customWidth="45%"
                                onClick={() => onChange('ownerType', 'new')}
                                >Create
                            </ZiplyneButton>
                        </div>
                        </>
                    }
                </FormControl>
                {
                    ownerType === 'new' && <FormControl sx={{ width: "100%" }}>
                        <TextField label="Username" style={customStyles} value={Username} onChange={(e) => onChange('Username', e.target.value)} />
                        <TextField label="First Name" style={customStyles} value={FirstName} onChange={(e) => onChange('FirstName', e.target.value)}/>
                        <TextField label="Last Name" style={customStyles} value={LastName} onChange={(e) => onChange('LastName', e.target.value)}/>
                        <TextField label="Email" style={customStyles} value={Email} onChange={(e) => onChange('Email', e.target.value)}/>
                        <TextField label="Password" style={customStyles} value={Password} onChange={(e) => onChange('Password', e.target.value)}/>
                    </FormControl>
                }
                {
                    ownerType && <FormControl sx={{ width: "100%" }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedStatus}
                                label="Status"
                                onChange={(e) => onChange('selectedStatus', e.target.value)}
                            >
                                <MenuItem value={draftId}>Draft</MenuItem>
                                <MenuItem value={testId}>Test</MenuItem>
                                <MenuItem value={liveId}>Live</MenuItem>
                            </Select>
                        </FormControl>
                        <List className="position-static">
                            <ListItem>
                                <ListItemText primary="Web Active"/>
                                <ListItemSecondaryAction>
                                    <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('WebActive', e.target.checked)} checked={WebActive}/>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Mobile Active"/>
                                <ListItemSecondaryAction>
                                    <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('MobileActive', e.target.checked)} checked={MobileActive}/>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Popup Snap"/>
                                <ListItemSecondaryAction>
                                    <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('ZiplyneSnap', e.target.checked)} checked={ZiplyneSnap}/>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Site Snap"/>
                                <ListItemSecondaryAction>
                                    <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('SiteSnap', e.target.checked)} checked={SiteSnap}/>
                                </ListItemSecondaryAction>
                            </ListItem> 
                            <ListItem>
                                <ListItemText primary="Whitelabel"/>
                                <ListItemSecondaryAction>
                                    <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('CustomizeLogo', e.target.checked)} checked={CustomizeLogo}/>
                                </ListItemSecondaryAction>
                            </ListItem> 
                            <ListItem>
                                <ListItemText primary="Advanced Analytics"/>
                                <ListItemSecondaryAction>
                                    <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('AdvancedAnalytics', e.target.checked)} checked={AdvancedAnalytics}/>
                                </ListItemSecondaryAction>
                            </ListItem> 
                            {/*<ListItem>
                                <ListItemText primary="Cross Domain Access"/>
                                <ListItemSecondaryAction>
                                    <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('CrossDomainAccess', e.target.checked)} checked={CrossDomainAccess}/>
                                </ListItemSecondaryAction>
                            </ListItem> */}
                            
                        </List>
                    </FormControl>
                }
            </div>
        )
    }
}

export default AddSubCompaniesModal
