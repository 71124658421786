import * as AuthenticateAPI from '../actions/ApiCalls';

const addNewCompany = (payload) => {
    return AuthenticateAPI.postResponseFormDataMobile(`ziplyneWork/addNewApplication`, payload);
}
const getCompanyList = (params) => {
	return AuthenticateAPI.getResponse(`companydomain`, params);
}

const editCompanyDomain = (uuid, params) => {
	return AuthenticateAPI.putResponse(`companydomain/${uuid}`, params)
}

const addCompanyDomain = (params) => {
	return AuthenticateAPI.postResponse(`companydomain`, params)
}

const getCompany = (param) => {
	return AuthenticateAPI.getResponse(`company`, param);
}

const deleteCompanyDomain = (payload) => {
	return AuthenticateAPI.deleteResponse(`companydomain/${payload.uuid}`);
}

const deleteCompany = (payload) => {
	return AuthenticateAPI.postMobileResponse(`ziplyneWork/deleteApplication`, payload);
}

const CompanyServices = {
    addNewCompany, getCompanyList,deleteCompany, getCompany, addCompanyDomain, editCompanyDomain,
	deleteCompanyDomain
}

export default CompanyServices;