import React, {Component} from 'react';
import BasicFunctions from 'helpers/BasicFunctions';
import {isSuperAdmin} from 'actions/AuthHelperMethods';
import { TextField, Switch, FormControl, 
    List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';

class AddEditUser extends Component {
    state = {user: false, password: '***********'}

    componentDidMount() {
        if (BasicFunctions.checkVal(this.props.editData.IsAdmin) === false && BasicFunctions.checkVal(this.props.editData.IsTester) === false && BasicFunctions.checkVal(this.props.editData.IsCreator) === false) {
            this.setState({user: true}, function() {
            });
        }    
    }    

    updatePassword = (inp, value) => {
        if (value.length < 0) {
            this.setState({password: "Password"});
        }else {
            this.setState({password: "***********"})
        }
        this.props.onChange(inp, value);
    }


    render () {
        const {Username, FirstName, LastName, Email, IsActive, Password, IsAdmin, IsCreator, MailConfirmed, IsTester, CurCompanyName, onChange, type} = this.props.editData
        let tempAdmin = IsAdmin, tempCreator = IsCreator, tempTester = IsTester;
        return (
            <FormControl fullWidth>
                <TextField label="Username" value={Username} onChange={(e) => onChange('username', e.target.value)}/>
                <TextField label="First Name" value={FirstName} onChange={(e) => onChange('first_name', e.target.value)}/>
                <TextField label="Last Name" value={LastName} onChange={(e) => onChange('last_name', e.target.value)}/>
                <TextField inputProps={{autoComplete: 'off'}} autoComplete="new-password" label="Email Id" value={Email} onChange={(e) => onChange('email', e.target.value)}/>
                <TextField label="Password" disabled={type === 'addUser' ? false : true} value={Password} autoComplete="new-password" type="password" onChange={(e) => this.updatePassword('password', e.target.value)}/>
                <TextField label="Company" style={(isSuperAdmin() !== undefined && isSuperAdmin() !== null && isSuperAdmin() === true) ?{}:{display:'none'}} value={CurCompanyName} disabled  onChange={(e) => this.updatePassword('password', e.target.value)}/>

                <List className="position-static">
                    <ListItem>
                        <ListItemText primary="Status"/>
                        <ListItemSecondaryAction>
                            <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('isActive', e.target.checked)} checked={IsActive}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="ID Verified"/>
                        <ListItemSecondaryAction>
                            <Switch 
                                classes={{checked: 'text-primary'}}
                                onChange={(e) => onChange('mailConfirmed', e.target.checked)}
                                checked={MailConfirmed}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Admin"/>
                        <ListItemSecondaryAction>
                            <Switch 
                                classes={{checked: 'text-primary'}} 
                                disabled={(tempCreator || tempTester) ? true : false}
                                onChange={(e) => {
                                    tempAdmin = e.target.checked;
                                    onChange('isAdmin', e.target.checked)}
                                } 
                                checked={IsAdmin}
                            />
                        </ListItemSecondaryAction>
                    </ListItem> 
                    <ListItem>
                        <ListItemText primary="Creator"/>
                        <ListItemSecondaryAction>
                            <Switch 
                                classes={{checked: 'text-primary'}} 
                                disabled={(tempAdmin || tempTester) ? true : false}
                                onChange={(e) => {
                                    tempCreator = e.target.checked;
                                    onChange('isCreator', e.target.checked)}
                                } 
                                checked={IsCreator}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>  
                    <ListItem>
                        <ListItemText primary="Tester"/>
                        <ListItemSecondaryAction>
                            <Switch 
                                classes={{checked: 'text-primary'}} 
                                disabled={(tempCreator || tempAdmin) ? true : false}
                                onChange={(e) => {
                                    tempTester = e.target.checked;
                                    onChange('isTester', e.target.checked)}
                                }
                                checked={IsTester}
                            />
                        </ListItemSecondaryAction>
                    </ListItem> 
                    <ListItem>
                        <ListItemText primary="User"/>
                        <ListItemSecondaryAction>
                            <Switch 
                                classes={{checked: 'text-primary'}} 
                                disabled onChange={(e) => onChange('user', e.target.checked)} 
                                // checked={this.state.user}
                                checked={!tempAdmin && !tempCreator && !tempTester}           
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </FormControl>
        )
    }
}

export default AddEditUser;