import React from 'react';
import {connect} from 'react-redux';
import {  withStyles, TextField, Button, CircularProgress, FormControlLabel, Checkbox }from '@material-ui/core/';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import { hideMessage, showAuthLoader, userSignIn, signInSSO } from 'actions/Auth';
import { Link } from 'react-router-dom';
import ziplyne from 'assets/images/ziplogo.png'
import { setSamlUserCompanyData, setCurrentUser, setToken, checkHopkins } from 'actions/AuthHelperMethods';
import Config from "../config"

const styles = theme => ({
  content: {
    textAlign: 'center',
    borderRadius: "0 !important"
  },
  ziplyne: {
    fontSize: '30px',
    color: 'white',
    letterSpacing: '7px',
    width: '68%',
    marginLeft: '84px'
  },
  hr: {
    width: '5%',
    color: 'white',
    background: 'white',
    height: '05px',
  },
  login: {
    width: '77%',
    margin: '0 61px',
    height: '40px',
    // borderRadius: '20px',
    color: '#9c70f9',
    fontSize: '20px',
    background: 'white',
    textAlign: 'center',
    padding: '4px'
  },
  btn: {
    borderRadius: "0 !important",
    fontWeight: "bold !important"
  },
  appLogin: {
    borderRadius: '0 !important'
  }
});

class SignIn extends React.Component {
  state = {
    email: '',
    password: '',
    rememberMe: false,
    showSSO: false
  }

  onChangeHadler = (type, value) => {
    this.setState({[type]: value})
  }

  onClick = () => {
    var {email, password, rememberMe} = this.state;
    email = email.trim().toLowerCase();
    localStorage.setItem('isSAML', false);
    this.props.userSignIn({email, password, rememberMe});
  }

  componentDidMount() {
    let isChecked = localStorage.getItem('isChecked');
    if (isChecked) {
      let email = localStorage.getItem('email');
      let password = localStorage.getItem('password');
      this.setState({email, password, rememberMe: eval(isChecked)});
    }

    let check = checkHopkins();
    // check = true;
    if(check) {
      this.setState({ showSSO: true });
    }

    // LogIn on Enter functionality

    var input1 = document.getElementById('email')
    var input2 = document.getElementById("password");
    input1.addEventListener("keyup", function(event) {
      if (event.key === 'Enter') {
          event.preventDefault();
          check === true ? document.getElementById("sso-btn").click() : document.getElementById("signin-btn").click();
      }
    });
    input2.addEventListener("keyup", function(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            check === true ? document.getElementById("sso-btn").click() : document.getElementById("signin-btn").click();;
        }
    });


    window.addEventListener("message", (msg) => {
      var loginResponse = msg.data;
      try{
        if(loginResponse.zipaction.action == "authentication" ) {
          let data  = loginResponse.zipaction.data;
          const processSAML = async (data) => {
            let adString = [];
            if (data.ADString.length > 0) {
              data.ADString.map(ad => adString.push(ad.company.name));
            }
            let count = [], parentCompanies = [];
            data.ADString.forEach(ad => {
              if (ad.role.name === 'admin') {
                if (ad.company.name.match('/')) {
                  let parentName = ad.company.name.split('/')[0];
                  parentCompanies.push(parentName)
                } else {
                  parentCompanies.push(ad.company.name)
                }
                count.push(ad);
              }
            })
            if (count.length > 0) {
              setCurrentUser(data.user)
              setToken(data.token)
              localStorage.setItem('isSAML', true);
              if(data.ADString) {
                localStorage.setItem('ADString', adString.join(','));
              }
              if (this.state.rememberMe) {
                localStorage.setItem('email', this.state.email);
                localStorage.setItem('password', this.state.password);
                localStorage.setItem('isChecked', this.state.rememberMe);
              }
              else {
                localStorage.setItem('email', '');
                localStorage.setItem('password', '');
                localStorage.setItem('isChecked', false);
              }
              localStorage.setItem('user_name', `${data.user.first_name} ${data.user.last_name}`);
              localStorage.setItem('user_email', data.user.email);
              parentCompanies = [...new Set(parentCompanies)]
              let roles = ['admin']
              localStorage.setItem('user_type', roles.join(','));
              let completed = await setSamlUserCompanyData(count,parentCompanies);
              if (completed) {
                this.props.signInSSO();
              }
            } else {
              NotificationManager.error('You do not belong to any AD Groups', 'Error');
            }
          } 
          processSAML(data);
        } 
      }catch(exception){}
    });
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  ssoSignIn = () => {
    let url = `${Config.BaseUrl}saml/login`;
    let newwindow = window.open(url,"Single SignOn",'height=400,width=400');
    if (window.focus) {newwindow.focus()}
    return false;
  }

  render() {
    const { email, password, rememberMe, showSSO } = this.state;
    const {showMessage, loader, alertMessage, classes} = this.props;
    return (
      <div className="app-background">
      <div className="app-login-container align-items-center animated slideInUpTiny animation-duration-3">
      <div className={classes.content}>
        <img src={ziplyne} alt="" width="100" />
        <p className={classes.ziplyne}>ZIPLYNE DASHBOARD</p>
        <hr className={classes.hr}></hr>
        </div>
   
        <div className={`app-login-main-content ${classes.appLogin}`} style={{padding:30}}>
        
          <div style={{display: 'block'}}>
            <h1 style={{textAlign: 'center'}}><IntlMessages id="appModule.signIn"/></h1>
          </div>
          <TextField
            id="email"
            label={<IntlMessages id="appModule.email"/>} fullWidth
            value={email}
            onChange={(e) => this.onChangeHadler('email', e.target.value)}
            margin="normal" className="mt-1 my-sm-3" />
          <TextField type="password"
            id="password"
            label={<IntlMessages id="appModule.password"/>} fullWidth
            value={password}
            onChange={(e) => this.onChangeHadler('password', e.target.value)}
            margin="normal" className="mt-1 my-sm-3" />
          <FormControlLabel
          control={<Checkbox checked={rememberMe} onChange={(e) => this.onChangeHadler('rememberMe', e.target.checked)} name="gilad" />}
          label="Remember Me"
          />
          <div style={{textAlign:'center'}}>
            <div style={{marginBottom: "10px"}}>
              <Button id="signin-btn" onClick={this.onClick} className={classes.btn} variant="contained" color="primary" disabled={email && password ?false: true} style={{width:200}}> 
                <IntlMessages id="appModule.signIn"/>
              </Button>
            </div>
            {
              showSSO ? <div style={{marginBottom: "10px"}}>
                <Button id="sso-btn" style={{width: '200px'}} className={classes.btn} variant="contained" color="primary" onClick={this.ssoSignIn}>SSO</Button>
              </div>
              : null
            }
            <Link to="/forgotpassword">Forgot Password ?</Link>
          </div>
          </div>
        {
          loader &&
          <div className="loader-view">
            <CircularProgress/>
          </div>
        }
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer />
      </div>
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  signInSSO
})(SignIn));