import React, {Component} from 'react';
import { DatePicker } from "material-ui-pickers";


export default class DateTimePicker extends Component {
    state= {
        disableFuture : true,
        updatedStyle: {}
    }

    UNSAFE_componentWillMount() {
        if (this.props.allowFuture !== undefined && this.props.allowFuture !== null) {
            this.setState({disableFuture: false});
        }
       var tempStyle = {}
        if (this.props.marginTop !== undefined && this.props.marginTop !== null) {
            tempStyle.marginTop = this.props.marginTop;
        }
        this.setState({updatedStyle:tempStyle})
    }
    render(){
        const {selectedDate} = this.props;

       
        var displayLabel = "Date Picker";
        if (this.props.displayLabel !== undefined && this.props.displayLabel !== null) {
            displayLabel = this.props.displayLabel;
        }
        return (
            <DatePicker
                keyboard
                showTabs={false}
                disabletoolbar="true"
                disableOpenOnEnter
                autoOk={true}
                style={this.state.updatedStyle}
                disablePast
                disabled={this.props.disablePicker}
                clearable
                value={selectedDate}
                onChange={this.props.handleDateChange}
                animateYearScrolling={true}
                label={displayLabel}
                format="MMM/DD/YYYY"
                leftArrowIcon={<i className="zmdi zmdi-arrow-back"/>}
                rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
            />
        )
    }
}
