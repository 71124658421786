import * as AuthenticateAPI from '../actions/ApiCalls';

const getZipMonitor = (params) => {
    return AuthenticateAPI.getResponse(`guidemonitor`, params);
}

const getZipDetails = (zipId) => {
    return AuthenticateAPI.getResponse(`ziphealth/${zipId}?pageNo=1&limit=50`);
}

const getZipSetting = (zipId) => {
    return AuthenticateAPI.getResponse(`ziphealth/settings/zip/${zipId}`);
}

const addZipSetting = (payload) => {
    return AuthenticateAPI.postResponse(`guidemonitorsetting`, payload);
}

const saveZipSetting = (uuid, payload) => {
    return AuthenticateAPI.putResponse(`guidemonitorsetting/${uuid}`, payload);
}

const deleteZipSetting = (zipId) => {
    return AuthenticateAPI.deleteResponse(`ziphealth/settings/zip/${zipId}`)
}

const getZipMonitorSetting = (params) => {
    return AuthenticateAPI.getResponse(`guidemonitorsetting`, params);
}

const saveZipMonitorSetting = (payload) => {
    return AuthenticateAPI.postResponse(`ziphealth/settings/global`, payload);
}

const ZipMonitorServices = {
    getZipMonitor, getZipDetails, getZipSetting, saveZipSetting, addZipSetting, deleteZipSetting, 
    getZipMonitorSetting, saveZipMonitorSetting
}

export default ZipMonitorServices;