import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const MobileApp = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/info`} />
      <Route
        path={`${match.url}/dashboard/homepage`}
        component={asyncComponent(() => import("../dashboard/routes/homepage"))}
      />
      <Route
        path={`${match.url}/info`}
        component={asyncComponent(() => import("./routes/Info/info"))}
      />
      <Route
        path={`${match.url}/setup`}
        component={asyncComponent(() => import("./routes/Setup/setup"))}
      />
      <Route
        path={`${match.url}/screens`}
        component={asyncComponent(() => import("./routes/Screens/screens"))}
      />
      <Route
        path={`${match.url}/elements`}
        component={asyncComponent(() => import("./routes/Elements/elements"))}
      />
      <Route
        path={`${match.url}/zips`}
        component={asyncComponent(() => import("./routes/Zips/ZipListing"))}
      />
      <Route
        path={`${match.url}/zipOrdering`}
        component={asyncComponent(() => import("./routes/Zips/ZipOrdering"))}
      />
      <Route
        path={`${match.url}/zipDetails`}
        component={asyncComponent(() => import("./routes/Zips/ZipView"))}
      />
      <Route
        path={`${match.url}/editElement`}
        component={asyncComponent(() => import("./routes/Zips/EditZip"))}
      />
      <Route
        path={`${match.url}/tableListing`}
        component={asyncComponent(() =>
          import("./routes/Zips/ZipListingTable")
        )}
      />
      <Route
        path={`${match.url}/editPage`}
        component={asyncComponent(() => import("./routes/Zips/EditZipPage"))}
      />
      <Route
        path={`${match.url}/customizer`}
        component={asyncComponent(() =>
          import("./routes/Onboarding/onboarding")
        )}
      />
      <Route
        path={`${match.url}/logo`}
        component={asyncComponent(() => import("./routes/UploadLogo/upload"))}
      />
      <Route
        path={`${match.url}/assets`}
        component={asyncComponent(() =>
          import("./routes/UploadAssets/UploadAsset")
        )}
      />
      <Route
        path={`${match.url}/users`}
        component={asyncComponent(() =>
          import("app/routes/webApp/routes/Users/user")
        )}
      />
      <Route
        path={`${match.url}/analytics`}
        component={asyncComponent(() =>
          import("app/routes/webApp/routes/Analytics/analytics")
        )}
      />
      <Route
        path={`${match.url}/zipContent`}
        component={asyncComponent(() => import("./routes/Zips/ContentUpload"))}
      />
      <Route
        path={`${match.url}/shareables`}
        component={asyncComponent(() =>
          import("./routes/Shareables/shareable")
        )}
      />
      <Route
        component={asyncComponent(() =>
          import("app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default MobileApp;
