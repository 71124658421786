import { HIDE_MESSAGE, INIT_URL, ON_HIDE_LOADER, ON_SHOW_LOADER,
  SHOW_MESSAGE, SIGNIN_USER_SUCCESS, SIGNOUT_USER_SUCCESS } from "constants/ActionTypes";
import axios from 'axios';
import { getToken, getCurrentUser } from 'actions/AuthHelperMethods';
import config from '../config';

  const fetchSignin = () => {
    if (getToken()) {
      return axios.get(config.SessionBaseUrl + 'signin', {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization" : `Bearer ${getToken()}`
        }
      }).then(response => {
        if (response.status === 200) {
          try {
          window.$ziplyne.assignSegment(localStorage.getItem('user_type'))
          } catch(e) {
            console.log('setting segment failed')
          }
          return response
        }
      }).catch(error => {
        return error
      })
    } else return null
  }


const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: fetchSignin(),
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false
      }
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    default:
      return state;
  }
}
