import * as Sentry from "@sentry/browser";

/**
 * Logs API Error To Sentry.
 * @param {*} url
 * @param {*} error
 */
const LogError = (url, error) => {
  if (typeof error.response === "undefined") {
    const errMsg =
      "URL " +
      url +
      " A network error occurred. " +
      "This could be a CORS issue or a dropped internet connection. ";
    const customErr = new Error(errMsg);
    Sentry.captureException(customErr);
  } else {
    Sentry.captureException(error);
  }
};

export default LogError;
