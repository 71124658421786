
const filterInitialData = (input) => {
    var tempSelectedPages = [];
    var tempSelectedElements = [];
    for (var i = 0; i < input.pages.length; i++) {
        tempSelectedPages.push(true);
        var singleElement = [];
        for (var j = 0; j < input.pages[i].elements_info.length; j++) {
            singleElement.push(true);
        }
        tempSelectedElements.push(singleElement);
    }
    return ({ selectedPages: tempSelectedPages, selectedElements: tempSelectedElements });
}

const getShareableData = (shareableData, zip) => {
    var tempShareable = [];
    var tempData = shareableData;
    console.log(shareableData);
    console.log(zip)
    for (var i = 0; i < tempData.length; i++) {
        if (tempData[i].guide_id === zip.id) {
            tempShareable.push(tempData[i]);
        }
    }
    return tempShareable;
}
const getShareableIndex = (eachLink, newShareable) => {
    // console.log(eachLink);
    // console.log(newShareable);
    var ind = -1;
    for (var i = 0; i < newShareable.length; i++) {
        if (newShareable[i] === eachLink) {
            ind = i;
            break;
        }   
    }
    return ind;
}
const getParentIndexShareable = (eachZip, list) => {
    var ind = 0;
    for (var j = 0; j < list.length; j++) {
        if (list[j].id === eachZip.id) {
            ind = j;
            break;
        }
    }
    return ind;
}
const getChildListIndex = (parentElement, childList) => {
    var ind = 0;
    for (var i = 0; i < childList.length; i++) {
        if (childList[i].id === parentElement.id) {
            ind = i;
            break;
        }
    }
    return ind;
}
const updateData = (selectedPages, selectedElements) => {
    var tempObj = {};
    for (var i = 0; i < selectedPages.length; i++) {
        if (selectedPages[i] === true) {
            var tempVal = i + 1;
            var pTitle = "p" + tempVal;
            var str = "";

            for (var j = 0; j < selectedElements[i].length; j++) {
                if (selectedElements[i][j] === true) {
                    str += "" + (j + 1) + ",";
                }
            }
            str = str.substring(0, str.length - 1);
            tempObj[pTitle] = str;
        }

    }
    return (JSON.stringify(tempObj));
}
const filterOutNonFav = (shareableZips, isFav) => {
    for (var i = 0; i < shareableZips.length; i++) {
        let { num_of_plays, num_of_shares } = shareableZips[i].shareable_data_details;
        if (num_of_plays === undefined || num_of_plays === null ) {
            shareableZips[i].shareable_data_details.num_of_plays = 0;
        }
        if (num_of_shares === undefined || num_of_shares === null ) {
            shareableZips[i].shareable_data_details.num_of_shares = 0;
        }

    }
    if (!isFav === true) {
        return shareableZips;
    }
    var tempList = [];
    for (i = 0; i < shareableZips.length; i++) {
        if (shareableZips[i].is_fav === true) {
            tempList.push(shareableZips[i]);
        }
    }
    return tempList;
}
const checkFavExistence = (zipId, shareableZips, isFav) => {
    if (!isFav === true) {
        return true;
    }
    var res = false;
    for (var i = 0; i < shareableZips.length; i++) {
        if (zipId === shareableZips[i].ZipId) {
            res = true;
            break;
        }
    }
    return res;
}
const getCopiedNum = (zipId, shareableZips) => {
    if (shareableZips.length === 0) {
        return 0;
    }
    var res = 0;
    for (var i = 0; i < shareableZips.length; i++) {
        if (zipId === shareableZips[i].guide_id ) {
            if (shareableZips[i].shareable_data_details.num_of_shares !== undefined && shareableZips[i].shareable_data_details.num_of_shares !== null) {
               res += parseInt(shareableZips[i].shareable_data_details.num_of_shares);
            } 
        }
    }
    return res;
}
const getPlayedNum = (zipId, shareableZips) => {
    if (shareableZips.length === 0) {
        return "0";
    }
    var res = 0;
    for (var i = 0; i < shareableZips.length; i++) {
        if (zipId === shareableZips[i].guide_id) {
            if (shareableZips[i].shareable_data_details.num_of_plays !== undefined && shareableZips[i].shareable_data_details.num_of_plays !== null) {
               res += parseInt(shareableZips[i].shareable_data_details.num_of_plays);
            } 
        }
    }
    return res;
}
const fetchResetVars = () => {
    return {
        spinEnable: false,
        spinEnable1: false,
        zipList: [],
        zipOnlyList: [],
        zipOnlyListMaster: [],
        selectedIndex: -1,
        selectedZip: '',
        selectedShareable: [],
        selectedZipDetails: '',
        shareableData: [],
        shareableDataMaster:[],
        anchorEl2: null,
        detaileBlock: '',
        open2: false,
        selectedSorting: "Filter by",
        detailedBlock:null,
        spinEnableAdvanced: false,
        path: '',
        search: '',
        searching: false,
        timeout: 0
    }
}
const fetchShareModalStateVars = () => {
    return {
        open: true,
        open2: false,
        link: "",
        teammateDropdown: false,
        segmentsBlock: true,
        toPlay: false,
        dateSelected: false,
        spinEnable: false,
        modified: false,
        isGenerated: false,
        personName: [],
        selectedEmails: [],
        email: "",
        teamMemberEmail: "",
        locked: true,
        data: "",
        newShareable: null,
    }
}
const ShareableSupportFunctions = {
    filterInitialData,fetchResetVars, updateData,getShareableIndex, filterOutNonFav, 
    checkFavExistence, getCopiedNum, getPlayedNum, getShareableData, fetchShareModalStateVars,
    getParentIndexShareable,getChildListIndex
}

export default ShareableSupportFunctions;