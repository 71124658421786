import React, {Component} from 'react';
import { TextField, FormControl } from '@material-ui/core';

class ProfileModal extends Component {
    render () {
        const {FirstName, LastName, Email, onChange,onPasswordClick } = this.props.editData;
        return (
            <FormControl fullWidth>
                <TextField label="First Name" value={FirstName}  onChange={(e) => onChange('FirstName', e.target.value)}/>
                <TextField label="Last Name" value={LastName} onChange={(e) => onChange('LastName', e.target.value)}/>
                <TextField label="Email" disabled value={Email} onChange={(e) => onChange('Email', e.target.value)}/>
                <br/>
                <p onClick={onPasswordClick} className="hover-thing" style={{color:'#673ab6'}} >Change Password</p>
            </FormControl>
        )
    }
}

export default ProfileModal;