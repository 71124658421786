import SignServices from '../_services/sigin';
import CompanyServices from '../_services/companies';
import { typeIds, showUrls } from 'config';

export const login = (username, password, rememberme) => {
    return new Promise((resolve, reject) => {
        const payload ={
            email: username, 
            password,
        }
        SignServices.signIn(payload).then(async res => {    
            if ((res.status === 200)) {
                let userInfo = res.data.data.user;
                let roles = setRoles(userInfo);
                let { mail_confirmed, active } = userInfo.user_details;
                let rolesCondition = roles.includes('superadmin') || roles.includes('admin')
                if (checkHopkins()) {
                    rolesCondition = roles.includes('superadmin');
                }
                if (mail_confirmed && active && rolesCondition) {
                    setToken(res.data.data.token);
                    setCurrentUser(userInfo);
                    let completed = await setUserCompanyData(userInfo)
                    
                    localStorage.setItem('user_name', `${userInfo.first_name} ${userInfo.last_name}`);
                    localStorage.setItem('user_email', userInfo.email);
                    localStorage.setItem('user_type', roles.join(','));
                    localStorage.setItem('email', username);
                    localStorage.setItem('password', password);
                    localStorage.setItem('isChecked', rememberme);
                    if (completed) {
                        resolve({login: true, message: 'Successfully Logged In'});
                    }
                } else {
                    if (!mail_confirmed) reject({login: false, message: 'Mail Not Verified'});
                    reject({login: false, message: 'You do not have the right permissions'});
                }
            
            } else {
                if (res.data.error) {
                    reject({login: false, message: `Signin Failed: ${res.data.error}`});
                }
            } 
        }).catch(error => {reject(error)})
    })
}

export const setSamlUserCompanyData = async (adstring,parentCompanies) => {
    let all_companies_id = [];
    adstring.map(ad => {
        all_companies_id.push(ad.company.id)
    })
    let param = {
        id: [...new Set(all_companies_id)].join(',')
    }
    let response = await CompanyServices.getCompany(param);
    if (response.status === 200) {
        const companyData = response.data.data.datas;
        localStorage.setItem('all_companies_data', JSON.stringify(companyData));
        if (localStorage.getItem('isSAML') === 'true') {
            if (companyData.length > 0) {
                setCompanyData(companyData[0].id);
                return true;
            }
        }
        // let parentPayload = {
        //     name: parentCompanies.join(',')
        // }
        // let res = await CompanyServices.getCompany(parentPayload);
        // if (res.status === 200) {
        //     let parentData = res.data.data.datas;
        //     let allData = [...parentData, ...companyData];
        //     localStorage.setItem('all_companies_data', JSON.stringify(allData));
        //     if (localStorage.getItem('isSAML') === 'true') {
        //         setCompanyData(companyData[0].id);
        //         return true;
        //     }
        // }
        return false;
    }
    return false
}

export const setUserCompanyData = async (userInfo) => {
    let allUsers = userInfo.user_companies_roles || [];
    let all_companies_id = [userInfo.company_id]
    if (allUsers.length === 0) {
        all_companies_id = [userInfo.company_id];
    }
    let adminRoleId = typeIds.roles.filter(el => el.name === 'admin')[0].id;
    allUsers.map(user => {
        if (user.role_id === adminRoleId) {
            all_companies_id.push(user.company_id);
        }
    })
    let param = {
        id: [...new Set(all_companies_id)].join(',')
    }
    let response = await CompanyServices.getCompany(param);
    if (response.status === 200) {
        const companyData = response.data.data.datas;
        localStorage.setItem('all_companies_data', JSON.stringify(companyData));
        setCompanyData(all_companies_id[0]);
        return true;
    }
    return false;
}

export const setRoles = (userInfo) => {
    let roles = [];
    if (userInfo.roles && userInfo.roles.length > 0) {
        userInfo.roles.map(role => roles.push(role.name));
    } else {
        roles.push('user');
    }
    return roles;
}

export const getRoles = () => {
    let roles = localStorage.getItem('user_type');
    if (roles) {
        return roles;
    }
    return '';
}

export const checkHopkins = () => {
    let host = window.location.host;
    return showUrls.includes(host);
}

export const setCompanyData = (companyId, reload) => {
    //Set the company data in the local storage
    if (companyId !== null) {
        localStorage.setItem('company_id', companyId);
        if (localStorage.getItem('all_companies_data')) {
            let allCompaniesData = getAllCompaniesData() || [];
            let companyData = allCompaniesData.filter(company => company.id === companyId);
            if (companyData.length > 0) {
                localStorage.setItem('company_data', JSON.stringify(companyData[0]));
                if (reload) {
                    window.location.reload();
                }
            }
        }
    } else localStorage.setItem('company_id', '')
}

export const getMobileActive = () => {
   return localStorage.getItem('mobile_active');
}

export const getWebActive = () => {
   return localStorage.getItem('web_active');
}


export const setUserProfile = (userInfo) => {
    localStorage.setItem('user_info', userInfo);
}
export const fetchUserName = () => {
    return localStorage.getItem('user_name');
}
export const fetchProfile = () => {
    return localStorage.getItem('user_info');
}
export const setMobileToken = (idToken) => {
    localStorage.setItem('ziplyne_mobile_token', idToken)
}
export const getMobileToken = () => {
    return localStorage.getItem('ziplyne_token')

   // return localStorage.getItem('ziplyne_mobile_token')
}
export const setToken = (idToken) => {
    localStorage.setItem('ziplyne_token', idToken)
}

export const getToken = () => {
    return localStorage.getItem('ziplyne_token')
}

export const logout = () => {
    return new Promise((resolve, reject) => {
        let arr = [
            'ziplyne_token','ziplyne_mobile_token','ziplyne_token_loginUser','all_companies_data',
            'company_data','email','password','user_email','company_id','user_name','user_type','ADString',
            'isSAML'
        ]
        for (let i=0; i<arr.length; i++) {
            localStorage.removeItem(arr[i])
        }
        resolve({logout: true})
    })
}


export const setCurrentUser = (value) => {
    localStorage.setItem('ziplyne_token_loginUser', JSON.stringify(value))
}

export const getCurrentUser = () => {
    const data = JSON.parse(localStorage.getItem('ziplyne_token_loginUser'));
    return data;
}

export const getCurrentCompany = () => {
    const data = JSON.parse(localStorage.getItem('company_data'));
    return data;
}

export const getAllCompaniesId = () => {
    const data = localStorage.getItem('all_companies_id');
    return data;
}

export const getAllCompaniesData = () => {
    const data = JSON.parse(localStorage.getItem('all_companies_data'));
    return data;
}

export const isSuperAdmin = () => {
    let result = localStorage.getItem('user_type') ? localStorage.getItem('user_type').split(',') : [];
    return result.includes('superadmin');
}


export const _checkStatus = (response) => {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
        return response
    } else {
        var error = new Error(response.statusText)
        error.response = response
        throw error
    }
}