import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { getCurrentUser, getCurrentCompany } from "actions/AuthHelperMethods";

function DropZone(props) {
    var widtth = (props.newWidth !== undefined && props.newWidth !== null) ? props.newWidth : "80%" ;
    var height = (props.newHeight !== undefined && props.newHeight !== null)? props.newHeight: "auto";
    var styleBlock = {width:widtth, height:height, marginTop : '10px'}
    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            const reader = new FormData();
            // reader.append('file', file.name);
            reader.append('file', file);
            // reader['filename'] = file.name;
            reader.append('company_id', getCurrentCompany().id);
            if (props.walkthroughId !== undefined && props.walkthroughId !== null) {
              reader.append('guide_id', props.walkthroughId)
            }
            if (props.updatedName !== undefined && props.updatedName !== null && props.updatedName.length > 0) {
              reader.append('name', props.updatedName)
            } else {
              reader.append('name', file.name)
            }
            if (props.CompanyName !== undefined && props.CompanyName !== null && props.CompanyName.length > 0) {
              reader.append('CompanyName', props.CompanyName);
            }
            if (props.type !== undefined && props.type !== null && props.type.length > 0) {
              reader.append('type', props.type);
            }
            if (props.IsMobile !== undefined && props.IsMobile !== null) {
              reader.append('IsMobile', props.IsMobile);
            }
            if (props.Url !== undefined && props.Url !== null && props.Url.length > 0) {
              reader.append('Url', props.Url);
            }
            if (props.ServerPath !== undefined && props.ServerPath !== null && props.ServerPath.length > 0) {
              reader.append('ServerPath', props.ServerPath);
            }
            if (props.pageName === 'content-upload') {
              const contentReader = new FormData();
              contentReader.append('file', file);
              props.file(contentReader);
            } else props.file(reader);
          })
    }, [props])
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
      accept: props.acceptedFileTypes, onDrop
    });
    
  
  return (
    <div className="dropzone-card">
      <div className="dropzone">
        <div {...getRootProps({className: 'dropzone-file-btn'})} style={styleBlock}>
          <input {...getInputProps()} />
          {props.spinEnable ? (
            <p>Uploading...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
          {/* <em>(Only {props.acceptedFileTypes} will be accepted)</em> */}
        </div>
      </div>
      <div className="dropzone-content">
        {acceptedFiles.map((f) => (
          <li key={f.name} style={{overflow: "hidden", textOverflow: "ellipsis"}}>
            {f.name} - {f.size} bytes
          </li>
        ))}
      </div>
    </div>
  );
}

export default DropZone;
