import * as AuthenticateAPI from '../actions/ApiCalls';
import Config from "./../config";

const getUsers = (payload, isMobile) => {
	if (isMobile) {
    	return AuthenticateAPI.postMobileResponse(`company/getusers`, payload);
	} else {
    	return AuthenticateAPI.getResponse(`user`, payload);
	}
}

const addUser = (payload, isMobile) => {
	if (isMobile) {
	    return AuthenticateAPI.postMobileResponse(`user/adduser`, payload);
	} else {
    	return AuthenticateAPI.postResponse(`user`, payload);
	}
}

const getUserDetails = (payload) => {
	return AuthenticateAPI.getResponse(`user`, payload);
}

const updateUser = (uuid, payload) => {
	return AuthenticateAPI.putResponse(`user/${uuid}`, payload);
}

const updatePassword = (payload) => {
	return AuthenticateAPI.postResponse(`user/changepassword`, payload);
}

const addBulkUser = (payload, isMobile) => {
	if (isMobile) {
    	return AuthenticateAPI.postResponseFormDataMobile(`user/uploadUserExcel`, payload);
	} else {
    	return AuthenticateAPI.postResponseFormData(`user/uploadexcel`, payload);
	}
}

const downloadExcel = () => {
    return `${Config.CDNURL}/excelTemplates/usersUploadTemplate.xlsx`;
}

const listenUser = (payload) => {
	return AuthenticateAPI.postResponse(`user/sendmail`, payload);
}

const UserServices = {
    getUsers, addUser, addBulkUser, downloadExcel, getUserDetails, updateUser, updatePassword,
	listenUser
}

export default UserServices;