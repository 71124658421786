import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import {connect} from 'react-redux'
import UserServices from '_services/users';
import {
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from '@material-ui/core'
import {userSignOut} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import Modal from 'components/Modals/modal';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Config from 'config';
import {getCurrentCompany, getCurrentUser, getRoles, setCompanyData} from 'actions/AuthHelperMethods';


class UserInfo extends React.Component {

  state = {
    anchorEl: null,
    showModal: false,
    open: false,
    user: null, 
    FirstName: "",
    LastName: "",
    Email: "",
    showPasswordModal:false,
    OldPassword: "",
    NewPassword: "",
    RepeatPassword:"",
    selectedCompanyId: localStorage.getItem('company_data') ? getCurrentCompany().id : '',
    companiesData: localStorage.getItem('all_companies_data') ? JSON.parse(localStorage.getItem('all_companies_data')) : [],
    roles: getRoles()
  };

  async componentDidMount() {
    this.setState({
      selectedCompanyId: localStorage.getItem('company_data') ? getCurrentCompany().id : '',
      companiesData: localStorage.getItem('all_companies_data') ? JSON.parse(localStorage.getItem('all_companies_data')) : []
    })
  }

  handleClick = event => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };

  handleRequestClose = () => {
    this.setState({open: false});
  };
   profileSetting = () => {
    this.handleRequestClose();
    this.setState({showModal:true})
  }
  onChangeHandler = (type, value) => {
    this.setState({[type]: value})
   /* if (type === "LabelName" && value.length > 0) {
      this.setState({showDropZone: true})
    }*/
  }
  handleClose = () => {
    this.reset()
  }
  handleClosePassword = () => {
    this.setState({showPasswordModal: false})
  }
  onPasswordClick = () => {
    this.reset();
    this.setState({showPasswordModal: true})
  }
  reset = () => {
    this.setState({showModal: false})
  }

  handleSavePassword = () => {
    if (this.state.NewPassword !== this.state.RepeatPassword) {
      NotificationManager.error('Password mismatch', 'Error');
      return;
    }
    this.setState({spinEnable : true, imageStyle : {width : '80%'}})
    const { username, first_name, last_name, user_details, roles, email, id, company_id } = this.state.user;
    const payload = {
      id,
      username,
      email,
      company_id,
      first_name,
      last_name,
      user_details,
      roles,
      password: this.state.NewPassword
    }
    UserServices.addUser(payload).then(response => {
      if (response) {
        if (response.status === 201) {
          if (response.data.success === true) {
            NotificationManager.success('Password updated successfully', 'Success')
            this.setState({showPasswordModal:false, spinEnable: false, OldPassword: '', NewPassword: '', RepeatPassword: ''})
          } else {
            NotificationManager.error(response.data.message, 'Error');
            this.setState({ spinEnable: false})

          }
        }
      }
    })
  }
  handleSave = () => {
    var tempUser = this.state.user;
    tempUser.FirstName = this.state.FirstName;
    tempUser.LastName = this.state.LastName;
    tempUser.Email = this.state.Email;
    
    this.setState({spinEnable : true, imageStyle : {width : '80%'}})
    const payload = {
      company_id: tempUser.company_id,
      first_name: tempUser.FirstName,
      last_name: tempUser.LastName,
      username: tempUser.username,
      email: tempUser.Email,
      user_details: tempUser.user_details,
      roles: tempUser.roles,
      id: tempUser.id
    }
    UserServices.addUser(payload, false).then(response => {
      if (response) {
        if (response.status === 201) {
          NotificationManager.success('User Updated', 'Success');
          localStorage.setItem('user_name', `${payload.first_name} ${payload.last_name}`)
          this.reset();
          this.fetchVals();
        }
      }
    })
  }
  fetchVals = () => {
    this.setState({spinEnable: true})
      UserServices.getUserDetails({id: getCurrentUser().id}).then(response => {
          if (response.status === 200) {
            const userInfo = response.data.data.datas[0]
            this.setState({user: userInfo, FirstName:userInfo.first_name,
              LastName: userInfo.last_name, Email: userInfo.email})     
          } else {
            this.setState({
              spinEnable : false
            });
            NotificationManager.error('There is some error in fetching List', 'Error')
          }
      })
      
  }
  
  UNSAFE_componentWillMount() {
    this.fetchVals();
  }

 


  render() {
    const imgPath = Config.LocalImagePath + 'ziplogo.png';
    const {FirstName, LastName, Email, showModal,showPasswordModal, OldPassword, 
      RepeatPassword, companiesData, roles } = this.state
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        <Avatar
          alt='...'
          src={imgPath}
          className="user-avatar "
        />
        <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>{localStorage.getItem('user_name')} <i
            className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
          </h4>
        </div>
        <Menu className="user-info"
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              onClose={this.handleRequestClose}
              PaperProps={{
                style: {
                  width: 300,
                  paddingTop: 0,
                  paddingBottom: 0
                }
              }}
        >
          {/*<MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
            <IntlMessages id="popup.profile"/>
          </MenuItem>*/}
          {
            localStorage.getItem('isSAML') == 'false' && <>
              <MenuItem onClick={this.profileSetting}>
                <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/>
                <IntlMessages id="popup.userSettings"/>
              </MenuItem>
              {
                !roles.match('superadmin') && <MenuItem>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Selected company</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.selectedCompanyId}
                      label="Select a Company"
                      onChange={(e) => {
                        this.setState({ selectedCompanyId: e.target.value })
                        setCompanyData(e.target.value, true)}
                      }
                    >
                    {
                      companiesData.map(company => {
                        let name = company.name.match('/') ? company.name.split('/')[1] : company.name;
                        return <MenuItem value={company.id}>{name}</MenuItem>
                      })
                    }
                    </Select>
                  </FormControl>
                </MenuItem>
              }
            </>
          }
          <MenuItem onClick={() => {
            this.handleRequestClose();
            this.props.userSignOut()
          }}>
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>

            <IntlMessages id="popup.logout"/>
          </MenuItem>
        </Menu>
        <NotificationContainer/>
        {showModal ? 
                  <Modal 
                  title={`User Settings`}
                  type="editUserProfile"
                  close={this.handleClose} 
                  onChange={this.onChangeHandler}
                  handleSave={this.handleSave}
                  FirstName={FirstName} 
                  onPasswordClick={this.onPasswordClick}
                  LastName={LastName}
                  Email={Email}
                  /> 
                  : null}
                  {showPasswordModal?
                    <Modal
                    title={`Update Password`}
                    type="updatePassword"
                    close={this.handleClosePassword} 
                    onChange={this.onChangeHandler}
                    handleSave={this.handleSavePassword}
                    OldPassword={OldPassword} 
                    NewPassword={this.NewPassword}
                    RepeatPassword={RepeatPassword}
                    />
                    :null
                  }
      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {locale} = settings;
  return {locale}
};
export default connect(mapStateToProps, {userSignOut})(UserInfo);


