import React from 'react';
import {Card, CardBody, CardText,} from 'reactstrap';

import './NothingToDisplay.css'

const NothingToDisplay = () => {
  return (
    <Card style={{borderRadius: '0 !important'}} className={`shadow border-0`}>
      <CardBody style={{borderRadius: '0 !important'}}>
        <CardText style={{borderRadius: '0 !important'}}>
          Nothing To Display
        </CardText>
      </CardBody>
    </Card>
  );
};
export default NothingToDisplay;