import React from "react";
import "./ziploader.css";
import Logo from "./z-logo.png";

const ZipLoader = () => {
  return (
    <div id="loader-container">
      <div id="diamond-loader">
        {/* Check out viewBox videos to understand svg better */}
        <svg className="z-svg" viewBox="0 0 200 200">
          <polygon
            id="logo-border"
            stroke="#a46ef6e8"
            strokeWidth="5"
            fill="none"
            points="100 4, 4 100,100 196, 196 100"
          />
        </svg>
        <div id="logo-loader">
          <img src={Logo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ZipLoader;
