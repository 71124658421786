import React, {Component} from 'react';
import {Divider, Card, CardContent} from '@material-ui/core';
import './zipmonitor.css';

const ListItem = ({data}) => {
    const { created_at } = data;
    const { step_number, element_type, page_url, window_height,
            window_width, navigation_mode } = data.guide_monitor_data_details
    return (
        <>
            <p className="description-paragraph">Step No : {step_number}</p>
            <p className="description-paragraph">Reported URL : <a href={page_url} target="blank">{page_url}</a></p>
            <p className="description-paragraph">Element Type : {element_type}</p>
            <p className="description-paragraph">Reported On : {new Date(created_at).toLocaleDateString("en-US") +"   "+new Date(created_at).toLocaleTimeString("en-US")  }</p>
            
            <div className="description-inline">
                <p className="description-paragraph">Navigation Mode : {navigation_mode}</p>
                {/* <p className="description-paragraph">Play Mode : {PlayMode}</p> */}
                <p className="description-paragraph">Window Size : {`${window_width} x ${window_height}`}</p>
            </div>
        </>
    )
}

class ListZipDetails extends Component{
    render() {
        const {data} = this.props;
        const { name, incidents, url, incidentsData } = data;
        return (
            <>
                <div className="details-header">
                    <div>{name}</div>
                    <div>{incidents} Incidents</div>
                    <a href={url} target="_blank" rel="noopener noreferrer" style={{color: '#262A94'}}>{url}</a>
                </div>
                <div className="grid-box-detail">
                {incidentsData.map(incident => 
                    <Card key={incident.id}>
                        <CardContent>
                            <ListItem style={{fontSize: "12px !important"}} data={incident} />
                        </CardContent>
                        <Divider/>
                    </Card>
                )}
                </div>
            </>
        )
    }
}

export default ListZipDetails;