import React from "react";
import ReactDOM from "react-dom";
import "font-awesome/css/font-awesome.min.css";
import "react-notifications/lib/notifications.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Config from "./config";

const RELEASE = Config.REACT_APP_SENTRY_RELEASE;
const ENVIRONMENT = Config.REACT_APP_ENVIRONMENT;

if (!window.location.origin.match('localhost')) {
  Sentry.init({
    dsn:
      "https://3268b5bf04db4021a458a7c7a0cca7b7@o613449.ingest.sentry.io/5750676",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: RELEASE,
    environment: ENVIRONMENT,
  });
}

const rootEl = document.getElementById("app-site");

// Create a reusable render method that we can call more than once
let render = () => {
  // Dynamically import our main App component, and render it
  const MainApp = require("./MainApp").default;
  ReactDOM.render(<MainApp />, rootEl);
};

if (module.hot) {
  module.hot.accept("./MainApp", () => {
    const MainApp = require("./MainApp").default;
    render(<MainApp />, rootEl);
  });
}

render();
