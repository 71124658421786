import React, {Component} from 'react';
import {FormControl, TextField} from '@material-ui/core';

class RequestService extends Component {
    render() {
        const {onChange} = this.props;
        return(
            <div>
                <FormControl fullWidth>
                    <TextField label="What Integration would you like to add" onChange={(e) => onChange('service', e.target.value)}/>
                    <TextField label="Integration URL" onChange={(e) => onChange('url', e.target.value)}/>
                    <TextField label="Email Address" onChange={(e) => onChange('email', e.target.value)}/>
                </FormControl>
            </div>
        )
    }
}

export default RequestService;