import Config from "./../config";
import {getToken, getMobileToken, logout} from './AuthHelperMethods';
import axios from 'axios';
import LogError from "util/LogError";


/*export default class ApiCalls {
    // Initializing important variables
    postCall = (url, body) => {
        const finalUrl = Config.MobileBaseUrl + url;
        var token = getMobileToken();
        body.token = token;
        return this.fetch(finalUrl, {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "xaccesstoken" : getMobileToken()
              },
            body: JSON.stringify(body)
        }).then(res => {
            return Promise.resolve(res);
        })

    }

    getCall = (url) => {
        const finalUrl = Config.MobileBaseUrl + url;
        return this.fetch(finalUrl, {
            method: 'GET',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "xaccesstoken" : getMobileToken()
              }
        }).then(res => {
            return Promise.resolve(res);
        })
    }
}*/
const sAMLCheck = () => {
    if (localStorage.getItem('isSAML') == "true") {
        return {
            // "isSAML": true,
            // "ADString": localStorage.getItem('ADString') || ""
        }
    } else return false
}


const getMobileHeader = (formData) => {
    let header = {
        "Accept": formData? 'multipart/form-data' : "application/json",
        "Content-Type": formData? 'multipart/form-data' : "application/json",
        "xaccesstoken" : getMobileToken() || null,
    }
    let saml = sAMLCheck();
    if (saml) {
        header = {
            ...header,
            ...saml
        }
    }
    return header;
}
const getHeader = (formData) => {
    let header = {
        "Accept": formData? 'multipart/form-data' : "application/json",
        "Content-Type": formData? 'multipart/form-data' : "application/json",
        "Authorization" : `Bearer ${getToken()}` || null,
    }
    let saml = sAMLCheck();
    if (saml) {
        header = {
            ...header,
            ...saml
        }
    }
    return header;
}

const getUpdatedHeader = (formData) => {
    let header =  {
        "Accept": formData? 'multipart/form-data' : "application/x-www-form-urlencoded; charset=UTF-8",
        "Content-Type": formData? 'multipart/form-data' : "application/x-www-form-urlencoded; charset=UTF-8",
        "Authorization" : `Bearer ${getToken()}` || null,
    }
    let saml = sAMLCheck();
    if (saml) {
        header = {
            ...header,
            ...saml
        }
    }
    return header;
}

const getUpdatedHeaderMobile = (formData) => {
    let header = {
        "Accept": formData? 'multipart/form-data' : "application/x-www-form-urlencoded; charset=UTF-8",
        "Content-Type": formData? 'multipart/form-data' : "application/x-www-form-urlencoded; charset=UTF-8",
        "xaccesstoken" : getMobileToken() || null,
    }
    let saml = sAMLCheck();
    if (saml) {
        header = {
            ...header,
            ...saml
        }
    }
    return header;
}


export const getResponse = (url, params) => {
    const URL = Config.BaseUrl + url;
    return axios(URL, {
        params,
        method:'GET',
        headers: getHeader()
    }).then(response => response)
    .catch(error => {
        LogError(URL, error);
        if (error?.response?.status === 401 && !error?.response?.success) {
            logout()
            window.location.href = '/'
        }
        else return error
    })
}

export const getResponseFromURL = (url, params) => {
    const URL = url;
    return axios(URL, {
        params,
        method:'GET',
    }).then(response => response)
    .catch(error => {
        LogError(URL, error);
        if (error?.response?.status === 403 && !error?.response?.success) {
            logout()
            window.location.href = '/'
        }
        else return error
    })
}
export const putResponse = (url, payload) => {
    const URL = Config.BaseUrl + url;
    return axios(URL, {
        method:'PUT',
        headers: getHeader(),
        data: payload
    }).then(response => response)
    .catch(error => {
        LogError(URL, error);
        if (error?.response?.status === 401 && !error?.response?.success) {
            logout()
            window.location.href = '/'
        } else return error
    })
}

export const deleteResponse = (url) => {
    const URL = Config.BaseUrl + url;
    return axios(URL, {
        method:'DELETE',
        headers: getHeader(),
    }).then(response => response)
    .catch(error => {
        LogError(URL, error);
        if (error?.response?.status === 401 && !error?.response?.success) {
            logout()
            window.location.href = '/'
        } else return error
    })
}

export const getMobileFile = (url) => {
    const URL = Config.MobileUploadFilePath + url;
    return axios(URL, {
        method:'GET',
        headers: getMobileHeader()
    }).then(response => response)
    .catch(error => {
        LogError(URL, error);
        if (error?.response?.status === 401 && !error?.response?.success) {
            logout()
            window.location.href = '/'
        } else return error
    })
}

export const getMobileResponse = (url, params) => {
    const URL = Config.MobileBaseUrl + url;
    return axios(URL, {
        params,
        method:'GET',
        headers: getHeader()
    }).then(response => response)
    .catch(error => {
        LogError(URL, error);
        if (error?.response?.status === 401 && !error?.response?.success) {
            logout()
            window.location.href = '/'
        } else return error
    })
}

export const postResponse = (url, payload) => {
    const URL = Config.BaseUrl + url;
    return axios(URL, {
        method:'POST',
        headers: getHeader(),
        data: payload
    }).then(response => response)
    .catch(error => {
        LogError(URL, error);
        if (error?.response?.status === 401 && !error?.response?.success) {
            logout()
            window.location.href = '/'
        } else return error
    })
}

export const postSignInResponse = (url, payload) => {
    const URL = Config.BaseUrl + url
    return axios(URL, {
        method:'POST',
        headers: getHeader(),
        data: payload
    }).then(response => response)
    .catch(error => {
        LogError(URL, error);
        if (error?.response?.status === 401 && !error?.response?.success) {
            logout()
            window.location.href = '/'
        } else return error
    })
}

export const postResponseToURL = (URL, payload) => {
    return axios(URL, {
        method:'POST',
        headers: getHeader(),
        data: payload
    }).then(response => response)
    .catch(error => {
        LogError(URL, error);
        if (error?.response?.status === 403 && !error?.response?.success) {
            logout()
            window.location.href = '/'
        } else return error
    })
}

export const postMobileResponse = (url, payload) => {
    var baseUrl = Config.MobileBaseUrl;
    if (url === "user/signin") {
        baseUrl = Config.MobileBaseUrlSignin;
    }
    const URL = baseUrl + url;
    return axios(URL, {
        method:'POST',
        headers: getHeader(),
        data: payload
    }).then(response => response)
    .catch(error => {
        LogError(URL, error);
        if (error?.response?.status === 401 && !error?.response?.success) {
            logout()
            window.location.href = '/'
        } else return error
    })
}

export const postResponseUpdated = (url, payload) => {
    const URL = Config.BaseUrl + url;
    return axios(URL, {
        method:'POST',
        headers: getUpdatedHeader(),
        data: payload
    }).then(response => response)
    .catch(error => {
        LogError(URL, error);
        if (error?.response?.status === 401 && !error?.response?.success) {
            logout()
            window.location.href = '/'
        } else return error
    })
}

export const postMobileResponseUpdated = (url, payload) => {
    var baseUrl = Config.MobileBaseUrl;
    if (url === "user/signin") {
        baseUrl = Config.MobileBaseUrlSignin;
    } 
    const URL = baseUrl + url;
    return axios(URL, {
        method:'POST',
        headers: getUpdatedHeaderMobile(),
        data: payload
    }).then(response => response)
    .catch(error => {
        LogError(URL, error);
        if (error?.response?.status === 401 && !error?.response?.success) {
            logout()
            window.location.href = '/'
        } else return error
    })
}



export const postResponseFormData = (url, payload) => {
    const URL = Config.BaseUrl + url;
    return axios.post(URL, payload, {headers: getHeader(true)})
    .then(response => response)
    .catch(error => {
        LogError(URL, error);
        if (error?.response?.status === 401 && !error?.response?.success) {
            logout()
            window.location.href = '/'
        } else return error
    })
}
export const postResponseFormDataMobile = (url, payload) => {
    const URL = Config.MobileBaseUrl + url;
    return axios.post(URL, payload, {headers: getMobileHeader(true)})
    .then(response => response)
    .catch(error => {
        LogError(URL, error);
        if (error?.response?.status === 401 && !error?.response?.success) {
            logout()
            window.location.href = '/'
        } else return error
    })
}