import React, {Component} from 'react';
import { 
    TextField, 
    FormControl, 
    List, 
    ListItem, 
    ListItemSecondaryAction, 
    ListItemText, 
    Switch,
    MenuItem,
    InputLabel,
    Select
} from '@material-ui/core';
import { typeIds } from 'config';

class NewCompaniesModal extends Component {
    render () {
        const {CompanyName, Username, FirstName, LastName, Email, Password, selectedStatus, ZiplyneSnap, SiteSnap, CustomizeLogo, AdvancedAnalytics,onChange, MobileActive, WebActive } = this.props.editData;
        
        const draftId = typeIds.status.filter(el => el.name === 'draft')[0].id;
        const testId = typeIds.status.filter(el => el.name === 'test')[0].id;
        const liveId = typeIds.status.filter(el => el.name === 'live')[0].id;
        
        return (
            <FormControl fullWidth>
                <TextField label="Customer Name" value={CompanyName} onChange={(e) => onChange('CompanyName', e.target.value)}/>
                <TextField label="Username" value={Username} onChange={(e) => onChange('Username', e.target.value)} />
                <TextField label="First Name" value={FirstName} onChange={(e) => onChange('FirstName', e.target.value)}/>
                <TextField label="Last Name" value={LastName} onChange={(e) => onChange('LastName', e.target.value)}/>
                <TextField label="Email" value={Email} onChange={(e) => onChange('Email', e.target.value)}/>
                <TextField label="Password" value={Password} onChange={(e) => onChange('Password', e.target.value)}/>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedStatus}
                        label="Status"
                        onChange={(e) => onChange('selectedStatus', e.target.value)}
                    >
                        <MenuItem value={draftId}>Draft</MenuItem>
                        <MenuItem value={testId}>Test</MenuItem>
                        <MenuItem value={liveId}>Live</MenuItem>
                    </Select>
                </FormControl>
                <List className="position-static">
                    <ListItem>
                        <ListItemText primary="Web Active"/>
                        <ListItemSecondaryAction>
                            <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('WebActive', e.target.checked)} checked={WebActive}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Mobile Active"/>
                        <ListItemSecondaryAction>
                            <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('MobileActive', e.target.checked)} checked={MobileActive}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Popup Snap"/>
                        <ListItemSecondaryAction>
                            <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('ZiplyneSnap', e.target.checked)} checked={ZiplyneSnap}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Site Snap"/>
                        <ListItemSecondaryAction>
                            <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('SiteSnap', e.target.checked)} checked={SiteSnap}/>
                        </ListItemSecondaryAction>
                    </ListItem> 
                    <ListItem>
                        <ListItemText primary="Whitelabel"/>
                        <ListItemSecondaryAction>
                            <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('CustomizeLogo', e.target.checked)} checked={CustomizeLogo}/>
                        </ListItemSecondaryAction>
                    </ListItem> 
                    <ListItem>
                        <ListItemText primary="Advanced Analytics"/>
                        <ListItemSecondaryAction>
                            <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('AdvancedAnalytics', e.target.checked)} checked={AdvancedAnalytics}/>
                        </ListItemSecondaryAction>
                    </ListItem> 
                    {/*<ListItem>
                        <ListItemText primary="Cross Domain Access"/>
                        <ListItemSecondaryAction>
                            <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('CrossDomainAccess', e.target.checked)} checked={CrossDomainAccess}/>
                        </ListItemSecondaryAction>
                    </ListItem> */}
                    {/* <ListItem>
                        <ListItemText primary="Playbook"/>
                        <ListItemSecondaryAction>
                            <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('PlaybookAccess', e.target.checked)} checked={PlaybookAccess}/>
                        </ListItemSecondaryAction>
                    </ListItem>  */}
                    
                </List>
            </FormControl>
        )
    }
}

export default NewCompaniesModal;