import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AppBar, Toolbar, IconButton, Button } from "@material-ui/core/";
import {
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  MINI_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";
import {
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from '@material-ui/core'
import { switchLanguage, toggleCollapsedNav } from "actions/Setting";
import { setDrawerType } from "actions/index";
import collapse from "assets/images/sidebar/collapseUncollapse.svg";
import ZiplyneButton from 'components/Button/Button'
import Config from 'config'
import { getCurrentCompany, setCompanyData, getRoles } from 'actions/AuthHelperMethods';

// import { Helper } from "../../app/routes/webApp/routes/ExperienceManager/Experiences/simpleExperiences";
class Header extends React.Component {
  state = {
    selectedCompanyId: localStorage.getItem('company_data') ? getCurrentCompany().id : '',
    companiesData: localStorage.getItem('all_companies_data') ? JSON.parse(localStorage.getItem('all_companies_data')) : [],
    roles: getRoles()
  }

  async componentDidMount() {
    this.setState({
      selectedCompanyId: localStorage.getItem('company_data') ? getCurrentCompany().id : '',
      companiesData: localStorage.getItem('all_companies_data') ? JSON.parse(localStorage.getItem('all_companies_data')) : []
    })
  }

  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  render() {
    const {
      drawerType,
      navigationStyle,
      horizontalNavPosition,
      location,
    } = this.props;
    const { selectedCompanyId, companiesData, roles } = this.state;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "d-block d-xl-none"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "d-block"
      : "d-none";

    return (
      <AppBar
        className={`app-main-header ${
          navigationStyle === HORIZONTAL_NAVIGATION &&
          horizontalNavPosition === BELOW_THE_HEADER
            ? "app-main-header-top"
            : ""
        }`}
      >
        <Toolbar
          className="app-toolbar"
          disableGutters={false}
          style={{ backgroundColor: "#ffffff" }}
        >
          {/* {drawerType === FIXED_DRAWER ? (
            <Button
              style={{ color: "white" }}
              onClick={() => this.props.setDrawerType(MINI_DRAWER)}
            >
              <img
                src={collapse}
                style={{ transform: "rotate(180deg)" }}
                alt=""
              />
            </Button>
          ) : (
            <Button
              style={{ color: "white" }}
              onClick={() => this.props.setDrawerType(FIXED_DRAWER)}
            >
              <img src={collapse} alt="" />
            </Button>
          )} */}
          {/* <ul  className="header-notifications list-inline ml-auto">

            <li className="list-inline-item user-nav">
              <Button onClick={() => {this.props.userSignOut()}} style={{color:'#673AB6'}}>
                <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/>
                <IntlMessages id="popup.logout"/>
            </Button>
            </li>
          </ul>
          }
          
          */}

          {location.pathname === "/app/webapp/experience" ? (
            <>
              <h1 className="experience-heading">Simple Experiences</h1>
              <div className="simpleExperience-btnContainer">
                <div style={{marginRight: "15px", marginLeft: "auto"}}>
                <ZiplyneButton
                  background="#673AB6"
                  onClick={this.props.onClickHandler}
                >
                  Add Experiences
                </ZiplyneButton>
                </div>
                <ZiplyneButton
                  background="#673AB6"
                  onClick={this.props.saveSequence}
                >
                  Save
                </ZiplyneButton>
              </div>
            </>
          ) : drawerType === FIXED_DRAWER ? (
            <div>
              <IconButton
                className={`jr-menu-icon mr-3 ${drawerStyle}`}
                aria-label="Menu"
                onClick={this.onToggleCollapsedNav}
              >
                <span className="menu-icon" />
              </IconButton>
              <Button
                style={{ color: "white" }}
                onClick={() => this.props.setDrawerType(MINI_DRAWER)}
              >
                <img
                  src={collapse}
                  style={{ transform: "rotate(180deg)" }}
                  alt=""
                />
              </Button>
            </div>
          ) : (
            <>
              <Button
                style={{ color: "white" }}
                onClick={() => this.props.setDrawerType(FIXED_DRAWER)}
              >
                <img src={collapse} alt="" />
              </Button>
              <div className="ellipse-shape"></div>
              <h2 style={{
                color: "#000",
                margin: 0,
                fontWeight: "bold"
              }}>{Config.headerTitle}</h2>
              {
                !roles.match('superadmin') && <FormControl 
                  style={{
                    width: "250px",
                    marginLeft: "auto"
                  }}
                >
                  <InputLabel id="demo-simple-select-label-company">Selected company</InputLabel>
                  <Select
                    labelId="demo-simple-select-label-company"
                    // id="demo-simple-select"
                    value={this.state.selectedCompanyId}
                    label="Select a Company"
                    onChange={(e) => {
                      this.setState({ selectedCompanyId: e.target.value })
                      setCompanyData(e.target.value, true)}
                    }
                  >
                  {
                    companiesData.map(company => {
                      let name = company.name.match('/') ? company.name.split('/')[1] : company.name;
                      return <MenuItem value={company.id}>{name}</MenuItem>
                    })
                  }
                  </Select>
                </FormControl>
              }
            </>
          )}
          
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const {
    drawerType,
    locale,
    navigationStyle,
    horizontalNavPosition,
  } = settings;
  return { drawerType, locale, navigationStyle, horizontalNavPosition };
};
export default withRouter(
  connect(mapStateToProps, {
    setDrawerType,
    toggleCollapsedNav,
    switchLanguage,
  })(Header)
);
