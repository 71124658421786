import * as AuthenticateAPI from '../actions/ApiCalls';

const signIn = (payload) => {
    return AuthenticateAPI.postSignInResponse(`signin`, payload);
}

const signInMobile = (payload) => {
    return AuthenticateAPI.postMobileResponse(`user/signin`, payload);
}
const forgotPassword = (payload) => {
    return AuthenticateAPI.postResponse(`forgotpassword`, payload);
}

const resetPassword = (payload) => {
	return AuthenticateAPI.postResponse(`user/updatepassword`, payload);
}


const SignServices = {
    signIn, forgotPassword, signInMobile, resetPassword
}

export default SignServices;