import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, Button, MenuItem,FormControl,
  TextField,CircularProgress,Menu} from '@material-ui/core';
import { Switch } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import { yellow } from '@material-ui/core/colors';
import Select from 'react-select';
import {Grid} from '@material-ui/core';
import copy from 'copy-to-clipboard';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import DateTimePicker from 'components/DateTimePicker/DateTimePickerFuture';
import ShareableZipsServices from '_services/shareableZips';
import DialogTitle from './DialogTitle';
import { fetchUserName, getCurrentCompany } from 'actions/AuthHelperMethods';
import ShareableSupportFunctions from './shareableSupportFunctions';
import './shareableStyle.css';

const styles = theme => ({
  dialogPaper: {
    width: '40%',
    '@media(max-width: 1060px)': {
      width: '60%'
    },
    borderRadius: "25px",
    height: '700px',
    boxShadow: 'inset -6px -8px 51px 1px rgba(182,139,247,0.28) !important'
  }
});


const PurpleSwitch = withStyles({
  switchBase: {
    color: "white",
    '&$checked': {
      color: "white!important",
    },
    '&$checked + $track': {
      color: "white!important",
      backgroundImage: "linear-gradient(to right, #6C2EEE 0, #6C2EEE 33%, #6C2EEE 100%);!important",
    },
  },
  checked: {
    color: yellow[500],
    '& + $bar': {
      backgroundColor: yellow[500],
    },
  },
  track: {},
})(Switch);

class ShareableModal extends Component {
  companyId = getCurrentCompany().id;
  state = {}
  componentDidMount() {
    
    
    this.setState(ShareableSupportFunctions.fetchShareModalStateVars(), function() {
      if (this.props.selectedShareable !== null) {
        this.setState({ newShareable: this.props.selectedShareable, name: this.props.selectedShareable.name, link: this.props.selectedShareable.shareable_data_details.link, toPlay: this.props.selectedShareable.ToPlay, expirationDate: parseInt(this.props.selectedShareable.shareable_data_details.expiration_timestamp) })
      }
    this.setState({ data: this.props.data, selected: [] });
    });
  }

  componentDidUpdate(previousProps) {
    if (previousProps.locked !== this.props.locked) {
      this.setState({ locked: this.props.locked });
    }
    if (previousProps.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
  }

   toggleChecked = (e) => {
  //  this.props.onChange('IsActive', e.target.checked, this.props.index);
    this.setState({
      checked: !this.state.checked
    });
  };

  handleClose = () => {
    this.setState({ open: false }, this.props.close(this.state.modified))
  };

  handleCancel = () => {
    this.setState({ open: false }, this.props.close(this.state.modified));
  };

  copyLink = () => {
    if (this.state.link === undefined || this.state.link === null || this.state.link.length === 0) {
      NotificationManager.error('Link is empty', 'Error');
      return;
    }
    copy(this.state.link);
    NotificationManager.success('Link Successfully copied', 'Success')
    this.updateCopiedCounter();
  };

  handleInputChange = (event) => {
    this.setState({
      name: event.target.value
    });
  }

  handleDateChange = (date, type) => {
    this.setState({ [type]: date })
    this.setState({ [type]: (new Date(date)).getTime(), dateSelected: true })
  };

  onChangeHandler = (type, value) => {
    this.setState({ [type]: value })
  };

  handleRequestClose2 = () => {
    this.setState({ open2: false });
  };

  selectStatus2 = (status) => {
    this.setState({ open2: false, segmentsBlock: true, "teammateDropdown": false });
  };

  handleClick2 = event => {
    this.setState({ segmentsBlock: false, teammateDropdown: true })
  };

  createShareable = () => {
    if (this.state.link.length > 5) {
      NotificationManager.error('You have already generated link.', 'Error')
      return;
    }

    if (this.state.name === undefined || this.state.name === null || this.state.name.length === 0) {
      NotificationManager.error('Add a name first.', 'Error')
      return;
    }

    if (this.state.dateSelected === false && this.state.checked) {
      NotificationManager.error('Select Date first to continue.', 'Error')
      return;
    }
    var newLink ="";
    var addtionalParam = "";
    if (this.props.pages.length > 0 && !window.location.href.includes('/mobileApp/')) {
      newLink = this.props.pages[this.props.firstPage].page_details.page;
      if (this.props.pages[this.props.firstPage].page_details.params.length > 0) {
        addtionalParam = this.props.pages[this.props.firstPage].page_details.params + "&";
      } else {
        addtionalParam = "?"
      }
    } else {
        addtionalParam = "?"

    }
    // var url = "https://";
    var url = "";
    var zipId = this.props.zip.id;
    if (window.location.href.includes('/mobileApp/')) {
      
      zipId = this.props.zip.SecondaryId;
    }
    let uuid = uuidv4();
    url = url + this.props.zip.site + newLink + addtionalParam +  "zpGuide=" + zipId + "&zpSteps=" + btoa(this.props.data) + "&zpShav=" + uuid;
    this.setState({ spinEnable: true })
    
    this.setState({ 
      link: url, 
      spinEnable: false, 
      modified: true, 
    })

    let payload = {
      id: uuid,
      name: this.state.name,
      company_id: this.companyId,
      description: ' ',
      guide_id: this.props.zip.id,
      is_fav: false,
      shareable_data_details: {
        expiration_timestamp: "0",
        num_of_plays: 0,
        num_of_shares: 0,
        to_play: false,
        extension: false,
        link: url
      }
    }
    if (!this.state.checked) {
      payload.shareable_data_details.expiration_timestamp = 0;
    } else {
      payload.shareable_data_details.expiration_timestamp = this.state.expirationDate;
    }

    ShareableZipsServices.createShareable(payload).then(res => {
      if (res.status === 201) {

        NotificationManager.success('New Link has been generated Successfully', 'Success')
        this.setState({ link: url, spinEnable: false, modified: true, newShareable: res.data })

      } else {
        this.setState({ spinEnable: false })

        NotificationManager.error('There is some error in fetching Zips', 'Error')
      }
    })

    this.setState({ isGenerated: true })
  }
  updateCopiedCounter = () => {
    this.props.selectedShareable.NumShares = this.props.selectedShareable.NumShares + 1;
    var payload = {
      shareable: this.props.selectedShareable
    }
    ShareableZipsServices.updateShareable(payload).then(res => {
      if (res.status === 200) {
        this.props.handleUpdateCopied();
      }
    })
  }
  updateShareables = () => {
    if (this.state.name === undefined || this.state.name === null || this.state.name.length === 0) {
      NotificationManager.error('Add a name first.', 'Error')
      return;
    }
    this.props.selectedShareable.name = this.state.name;
    this.props.selectedShareable.Data = this.state.data;
    this.props.selectedShareable.ExpirationTimestamp = this.state.expirationDate;
    var newLink ="";
    var addtionalParam = "";
    if (this.props.pages.length > 0 && !window.location.href.includes('/mobileApp/')) {
      newLink = this.props.pages[this.props.firstPage].page_details.page;
      if (this.props.pages[this.props.firstPage].page_details.params.length > 0) {
        addtionalParam = this.props.pages[this.props.firstPage].page_details.params + "&";
      } else {
        addtionalParam = "?"
      }
    } else {
        addtionalParam = "?"

    }
    // var url = "https://";
    var url = "";
    var zipId = this.props.zip.id;
    if (window.location.href.includes('/mobileApp/')) {
      
      zipId = this.props.zip.SecondaryId;
    }
    url = url + this.props.zip.site + newLink + addtionalParam +  "wtdash=" + zipId + "&dWtSteps=" + btoa(this.state.data);
    this.props.selectedShareable.Link = url;
    let { is_fav,company_id,guide_id,description,id,shareable_data_details } = this.props.selectedShareable;
    let payload = {
      name: this.state.name,
      description,
      guide_id,
      company_id,
      is_fav,
      shareable_data_details: {
        ...shareable_data_details,
        expiration_timestamp: this.state.expirationDate,
        link: url
      }
    }

    ShareableZipsServices.updateShareable(id, payload).then(res => {
      if (res.status === 200) {
        NotificationManager.success('Shareable Zip has been Successfully updated', 'Success')
        this.setState({ spinEnable: false, updated: true })

      } else {
        this.setState({ spinEnable: false })

        NotificationManager.error('There is some error in updating details', 'Error')
      }
    })
    this.handleClose();
  }

  handleDropdownChangeSegments = (event) => {
    if (event === undefined || event === null) {
      this.setState({ selectedEmails: [] });
    } else {
      this.setState({ selectedEmails: event });
    }
  }

  handleSubmit = () => {
    if (this.state.selectedEmails.length === 0) {
      NotificationManager.error('Add Teammate first to continue.', 'Error');
      return;
    }
    var shareable = this.state.newShareable;
    if (shareable === undefined || shareable === null) {
      NotificationManager.error('Please Generate a link first', 'Error');
      return;
    }
    shareable.data = this.state.data;
    var payload = {
      emails: this.state.selectedEmails,
      senderName: fetchUserName(),
      url: this.state.link,
      shareableName: this.state.name,
      shareable: shareable
    }
    this.setState({ open: false }, this.props.close(this.state.modified));
    ShareableZipsServices.sendEmail(payload).then(res => {
      if (res.status === 201) {
        NotificationManager.success('Shareable Zip has been sent Successfully', 'Success')
        this.setState({ spinEnable: false, updated: true })

      } else {
        this.setState({ spinEnable: false })
        NotificationManager.error('There is some error in sending the email', 'Error')
      }
    })
  }

  render() {
    const { classes, selectedShareable } = this.props;
    return (
      <div >
        <Dialog onClose={this.handleClose} open={this.state.open} classes={{ paper: classes.dialogPaper }} className="shareableModal-container">
          {(selectedShareable) === null ?
            <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>Generate Shareable Zip</DialogTitle> :
            <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>View Shareable Zip</DialogTitle>}

          <MuiDialogContent dividers className="shareablelink-container" style={{ root: { padding: 8 } }}>
            {this.state.spinEnable ?
              <div className="mui-style">
                <CircularProgress />
              </div> :
              <div>
                <div className="shareableLink-link-name-container">
                  {(selectedShareable) === null ?
                    <TextField name="name" className="shareableLink-link-name"
                      id="name" label="Name of Shareable Link" value={this.state.name} onChange={this.handleInputChange} /> :
                    <TextField className="shareableLink-link-name" name="name" defaultValue={selectedShareable.name}
                      id="name" label="Name of Shareable Link" value={this.state.name} onChange={this.handleInputChange} />
                  }
                </div>

                <div className="shareable-date-picker-container">
                <Grid container spacing={1}>
                  <Grid item xs={2} >
                    <PurpleSwitch color="primary" classes={{ checked: 'text-primary' }} onChange={this.toggleChecked} checked={this.state.checked} />
                  </Grid>
                  <Grid item xs={10}>
                  <div className="shareable-date-picker">
                    <h4>Expires on:</h4>
                  </div>
                  <div className="shareable-date-picker-modal shareableLink-copylink-new" >
                    <DateTimePicker
                      allowFuture="yes"
                      disablePicker={!this.state.checked}
                      marginTop="-16px"
                      handleDateChange={(expirationDate) => this.handleDateChange(expirationDate, 'expirationDate')}
                      selectedDate={this.state.expirationDate} />
                  </div>
                  </Grid>
                </Grid>
                  
                </div>

                <div className="shareableLink-link-generate-container">
                  {(selectedShareable) === null ?
                    <Button onClick={this.createShareable} color="primary" className="shareableLink-link-generate-btn">
                      Generate Shareable link
                   </Button> :
                    null
                  }
                </div>

                <div className="shareableLink-copylink-container">
                  <p className="sharaebleLink-title">Shareable Zip Link </p>
                  <div className="shareableLink-copylink">
                    <div className="shareableLink-copylink-innner ">
                      <h4>{this.state.link}</h4>
                    </div>
                    <div className="sharaebleLink-copy-btn" onClick={this.copyLink} style={{ width: '10%' }}>
                      <LinkOutlinedIcon />
                    </div>
                  </div>
                </div>

                <div>
                  <FormControl className="sharaebleLink-invite-container">
                    <h6 className="sharaebleLink-invite-header">Invite Team</h6>
                    <div style={this.state.teammateDropdown ? {} : { 'display': 'none'}} className="sharaebleLink-teammates full-width">
                      <Select
                        placeholder="Search for team members.."
                        isMulti="true"
                        options={this.props.userList}
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        onChange={this.handleDropdownChangeSegments} />
                    </div>
                    <MenuItem style={this.state.segmentsBlock ? {} : { 'display': 'none' }} onClick={this.handleClick2} className="sharaebleLink-dropdownMenu">
                      Select Teammate
                </MenuItem>
                    <Menu
                      id="simple-menu"
                      style={this.state.teammateDropdown ? {} : { 'display': 'none'}}
                      className="user-info half-width "
                      open={this.state.open2}
                      onClose={this.handleRequestClose2}
                      PaperProps={{
                        style: {
                          minWidth: 120,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }
                      }}>
                    </Menu>
                  </FormControl>
                </div>
              </div>
            }
          </MuiDialogContent>
          <MuiDialogActions className="sharaebleLink-btn-container" style={{ root: { margin: 0, padding: 4, } }}>
            {(this.state.link !== "" && selectedShareable === null) ?
              <Button className="send-link-btn" onClick={this.handleSubmit} variant="contained">
                Send
            </Button> : <Button onClick={this.handleCancel} color="primary">
                Cancel
            </Button>
            }
            {(selectedShareable) !== null ?
              <Button className="send-link-btn" onClick={this.updateShareables} variant="contained">
                Update
            </Button> : null
            }
          </MuiDialogActions>
        </Dialog>
        <NotificationContainer />
        <input id="cb" type="text" hidden />
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(ShareableModal);