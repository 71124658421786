import * as AuthenticateAPI from '../actions/ApiCalls';

const getPagesInfo = (payload) => {
	return AuthenticateAPI.getResponse(`guidepage`, payload);
}

const getStepsInfo = (payload) => {
	return AuthenticateAPI.getResponse(`guidestep`, payload);
}

const getExcelGuide = (payload) => {
	return AuthenticateAPI.getResponse(`guide/getexcel/${payload.id}`)
}

const uploadExcelGuide = (payload) => {
	return AuthenticateAPI.postResponseFormData(`guide/uploadexcel`, payload)
}

const WebZipServices = {
    getStepsInfo, getPagesInfo, getExcelGuide, uploadExcelGuide
}

export default WebZipServices;