import React, { Component } from 'react';
import { Card, List, ListItem, ListItemText, Divider, IconButton, Menu, MenuItem } from "@material-ui/core/";
import { Settings, FilterList, ErrorOutline } from '@material-ui/icons';
import './zipmonitor.css';
import { typeIds } from 'config'

const emailInitial = (email) => {
    let initial = email ? email.charAt(0).toUpperCase() : 'NA';
    return <p className="profile-initial" title={email}>{initial}</p>
}

const status = (statusId) => {
    let status = typeIds.status.filter(el => el.id === statusId)[0].name
    const cls = status === 'live' ? 'green' : status === 'archived' ? 'red' : status === 'draft' ? 'black' : status === 'test' ? 'orange' : null;
    return <div><div className={`status ${cls}`}></div><div>{status}</div></div>
}

const filter = (data, column) => {
    return data.sort((a, b) => {
        if(a[column] < b[column]) { return -1; }
        if(a[column] > b[column]) { return 1; }
        return 0;
    })
}

const ListZip = ({data, onClickHandler}) => {
    const {id, creator, incidents, name, status: zipStatus, step, url, zip_type_id } = data;
    // const elId = id.split('-')[0];
    let obj = {
        name,
        id,
        zipTypeId: zip_type_id
    }
    return (
        <>
        <ListItem>
                <ListItemText>
                    <div>
                        <div className="setting-icons"><Settings style={{position: 'sticky'}} onClick={e => {onClickHandler('setting', obj)}}/></div>
                    </div>
                    <div className="item" onClick={() => onClickHandler('zipId', data)}>
                        <p className="value-name">{name}</p>
                        <div className="values">{status(zipStatus)}</div>
                        <p className="values">{step}</p>
                        <p className="values">{incidents} <ErrorOutline style={{width: '16px'}}/></p>
                        {/* <div className="values padding">{emailInitial(creator)}</div> */}
                        <div className="values padding">{creator}</div>
                        <p className="value-url">{url}</p>
                    </div>
                    </ListItemText>
            </ListItem>
            <Divider/>
        </>
    )
}

class ListZips extends Component {
    state = {
        anchorEl: null,
        filterData: []
    }

    componentDidMount() {
        this.setState({data: this.props.data}, () => this.handleSort())
    }

    onClickHandler = (ElementId, ZipId, type, zipName) => {
        if (type === 'setting') {
            this.props.onClick('setting', ZipId, zipName)
        } else {
            let existingSelection = document.querySelector('.active-list');
            if (existingSelection) {
                existingSelection.classList.remove('active-list');
            }
            let newSelection = document.getElementById(ElementId);
            newSelection.classList.add('active-list')
            this.props.onClick('zipId',ZipId)
        }
    }

    onSelectHandler = (type, value, data) => {

    }

    handleClick = (event) => {
        
        this.setState({anchorEl: event.currentTarget});
    };
    
    handleSort = (value) => {
        let activeId;
        let filterData = filter(this.state.data, value ? value.id : null);
        let existingSelection = document.querySelector('.active-list');
        if (existingSelection) {
            activeId = existingSelection.getAttribute('id');
            existingSelection.classList.remove('active-list');
        }
        this.setState({filterData}, () => {
            let getElement = document.getElementById(activeId);
            if (getElement) getElement.classList.add('active-list')
        })
        this.setState({anchorEl: null})
    }


    render () {
        const {anchorEl, filterData} = this.state;
        const {scrollRef, scrollHandler} = this.props;
        const options = [{id: 'name',name: 'ZIP NAME'}, {id: 'status',name: 'ZIP STATUS'},
                {id: 'step',name: 'STEPS'},{id: 'incidents',name: 'INCIDENTS'},
                {id: 'creator',name: 'CREATOR'},{id: 'url',name: 'URL'}]
        return (
            <>
            <Card>
                <div className="filter-right">FILTER BY<IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}><FilterList /></IconButton>
                    <Menu anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    keepMounted id="simple-menu"
                    onClose={() => this.setState({anchorEl: null})}
                    PaperProps={{style: {maxHeight: 48 * 3.0,width: '15ch'}}}>
                    {options.map((option) => (
                    <MenuItem key={option.id} onClick={() => this.handleSort(option)}>{option.name}</MenuItem>
                    ))}
                    </Menu>
                </div>
            
                <div className="item" style={{
                    background: "#946BEA",
                    color: "#fff",
                    fontSize:"25px", 
                    fontWeight: "bold"
                }}>
                    <p className="value-name">ZIP NAME</p>
                    <p className="values">ZIP STATUS</p>
                    <p className="values">STEPS</p>
                    <p className="values">INCIDENTS</p>
                    <p className="values">CREATOR</p>
                    <p className="value-url">URL</p>
                </div>
            </Card>
            <List className="list grid-box-list" ref={scrollRef} onScroll={scrollHandler}>
                {filterData.length && filterData.map((list, index) => <ListZip data={list} key={index} onClickHandler={this.props.onSelect}/>)}
            </List>
            </>
        )
    }
} 

export default ListZips;