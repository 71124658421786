import React , { Component } from 'react';
import {FormControl, TextField} from '@material-ui/core';


class AddEditService extends Component {
    state = {
        settings:  {}
    }

    componentDidMount() {
        if (this.props.type !== 'addIntegration') {
            this.setState({
                settings: {
                    ...this.props.data.service_settings
                }
            })
        }
    }

    onChangeHandler = (type, value) => {
        if (this.props.type !== 'addIntegration') {
            this.state.settings[type] = value
            this.props.onChange(this.state.settings)
        } else { 
            this.state.settings[type] = value
            this.props.onChange(this.state.settings)
        }
    }
    render() {
        const { data, type } = this.props
        const forms = type === 'addIntegration'? Object.keys(data.service_object) : Object.keys(data.service_settings);
        const formElement = forms.map((form, index) => <TextField label={form} key={index} defaultValue={type === 'addIntegration'? '': data.service_settings[form]} onChange={(e) => this.onChangeHandler(form, e.target.value)}/>)
        return (
            <div>
                <FormControl fullWidth>
                    {formElement}
                </FormControl>
            </div>
        )
    }
}

export default AddEditService;