import React, {Component} from 'react';
import { TextField, FormControl} from '@material-ui/core';

class SelectorSettingsModal extends Component {
    render () {
        const {ClassName, onChange} = this.props.editData
        return (
            <FormControl fullWidth>
                <TextField label="Class to Ignore" value={ClassName} onChange={(e) => onChange('ClassName', e.target.value)}/>
            </FormControl>
        )
    }
}

export default SelectorSettingsModal;