import React, {Component} from 'react';
import { 
    TextField, 
    FormControl, 
    List, 
    ListItem, 
    ListItemSecondaryAction, 
    ListItemText, 
    Switch,
    MenuItem,
    InputLabel,
    Select
} from '@material-ui/core';
import ZiplyneButton from 'components/Button/Button';
import Autocomplete from '@mui/material/Autocomplete';
import modal from 'components/Modals/modal';
import { typeIds } from 'config';

class EditCompanyModal extends Component {

    componentDidUpdate(prevProps) {
        if (prevProps.editData.modalType !== this.props.editData.modalType) {
            this.setState({
                selectedDomain: '',
                deleteDomain: ''
            })
        }
    }

    state = {
        selectedDomain: '',
    }

    render () {
        const {EditableZiplyneSnap, EditableSiteSnap, EditableCustomizeLogo, EditableAdvancedAnalytics, newDomainName, editedDomainDetails, selectedStatus, 
            EditableCompanyName, EditableCompanyUrl, EditableWebActive, EditableMobileActive, onChange, modalType } = this.props.editData;
        
        const draftId = typeIds.status.filter(el => el.name === 'draft')[0].id;
        const testId = typeIds.status.filter(el => el.name === 'test')[0].id;
        const liveId = typeIds.status.filter(el => el.name === 'live')[0].id;
        
        return (
            <FormControl fullWidth>
                <TextField label="Customer Name" value={EditableCompanyName} onChange={(e) => onChange('EditableCompanyName', e.target.value)}/>
                {/* <TextField disabled label="Customer Domains (ex salesforce.com)" value={EditableCompanyUrl} onChange={(e) => onChange('EditableCompanyUrl', e.target.value)}/> */}
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedStatus}
                        label="Status"
                        onChange={(e) => onChange('selectedStatus', e.target.value)}
                    >
                        <MenuItem value={draftId}>Draft</MenuItem>
                        <MenuItem value={testId}>Test</MenuItem>
                        <MenuItem value={liveId}>Live</MenuItem>
                    </Select>
                    </FormControl>
                <List className="position-static">
                    <ListItem>
                        <ListItemText primary="Web Active"/>
                        <ListItemSecondaryAction>
                            <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('EditableWebActive', e.target.checked)} checked={EditableWebActive}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Mobile Active"/>
                        <ListItemSecondaryAction>
                            <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('EditableMobileActive', e.target.checked)} checked={EditableMobileActive}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Popup Snap"/>
                        <ListItemSecondaryAction>
                            <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('EditableZiplyneSnap', e.target.checked)} checked={EditableZiplyneSnap}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Site Snap"/>
                        <ListItemSecondaryAction>
                            <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('EditableSiteSnap', e.target.checked)} checked={EditableSiteSnap}/>
                        </ListItemSecondaryAction>
                    </ListItem> 
                    <ListItem>
                        <ListItemText primary="Whitelabel"/>
                        <ListItemSecondaryAction>
                            <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('EditableCustomizeLogo', e.target.checked)} checked={EditableCustomizeLogo}/>
                        </ListItemSecondaryAction>
                    </ListItem> 
                    <ListItem>
                        <ListItemText primary="Advanced Analytics"/>
                        <ListItemSecondaryAction>
                            <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('EditableAdvancedAnalytics', e.target.checked)} checked={EditableAdvancedAnalytics}/>
                        </ListItemSecondaryAction>
                    </ListItem> 
                    {/*<ListItem>
                        <ListItemText primary="Cross Domain Access"/>
                        <ListItemSecondaryAction>
                            <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('EditableCrossDomainAccess', e.target.checked)} checked={EditableCrossDomainAccess}/>
                        </ListItemSecondaryAction>
                    </ListItem> */}
                    {/* <ListItem>
                        <ListItemText primary="Playbook"/>
                        <ListItemSecondaryAction>
                            <Switch classes={{checked: 'text-primary'}} onChange={(e) => onChange('EditablePlaybookAccess', e.target.checked)} checked={EditablePlaybookAccess}/>
                        </ListItemSecondaryAction>
                    </ListItem>  */}
                    <div style={{
                        display: "flex",
                        // flexDirection: "column",
                        justifyContent: "center",
                        margin: "1.5rem 0"
                    }}>
                        <ZiplyneButton
                            customWidth="35%"
                            background={(modalType && modalType === 'add') ? "#673ab7" : ""}
                            border={(modalType !== 'add') ? "1px solid #673ab7" : ''}
                            onClick={(e) => onChange('modalType', 'add')}
                        >Add Domain</ZiplyneButton>
                        <ZiplyneButton
                            customWidth="35%"
                            background={(modalType && modalType === 'edit') ? "#673ab7" : ""}
                            border={(modalType !== 'edit') ? "1px solid #673ab7" : ''}
                            onClick={(e) => onChange('modalType', 'edit')}
                        >Edit Domain</ZiplyneButton>
                        <ZiplyneButton
                            customWidth="35%"
                            background={(modalType && modalType === 'delete') ? "#673ab7" : ""}
                            border={(modalType !== 'delete') ? "1px solid #673ab7" : ''}
                            onClick={(e) => onChange('modalType', 'delete')}
                            border="1px solid #673ab7"
                        >Delete Domain</ZiplyneButton>
                    </div>
                    {
                        modalType && modalType === 'add' && 
                        <TextField 
                            fullWidth 
                            label="Enter comma separated domains" 
                            onChange={(e) => onChange('newDomainName', e.target.value)}
                            value={newDomainName}
                        />
                    }
                    {
                        modalType && modalType === 'edit' &&
                        <Autocomplete
                            id="checkboxes-tags-demo"
                            options={EditableCompanyUrl}
                            onChange={(e, val) => {
                                this.setState({ 
                                    selectedDomain: val,
                                });
                                if (val) {
                                    onChange('editedDomainDetails', { nameBefore: val?.url, nameAfter: val?.url, domainId: val?.id })
                                } else {
                                    onChange('editedDomainDetails', { nameBefore: undefined, nameAfter: '', domainId: '' })
                                }
                            }}
                            getOptionLabel={(option) => option.url}
                            renderInput={(params) => <TextField {...params} label="Search for the domain to Edit" margin="normal" />}
                        />
                    }
                    {
                        this.state.selectedDomain !== '' && modalType === 'edit' &&
                        <TextField 
                            fullWidth
                            label="Edit the selected domain"
                            value={editedDomainDetails.nameAfter}
                            onChange={(e) => onChange('editedDomainDetails', {
                                nameBefore: this.state.selectedDomain,
                                nameAfter: e.target.value
                            })}
                        />
                    }
                    {
                        modalType === 'delete' && <Autocomplete
                            id="checkboxes-tags-demo"
                            options={EditableCompanyUrl}
                            onChange={(e, val) => {
                                onChange('deleteDomainId', val.domainId)
                            }}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.url}
                            renderInput={(params) => <TextField {...params} label="Search for the domain to Delete" margin="normal" />}
                        />
                    }
                </List>
            </FormControl>
        )
    }
}

export default EditCompanyModal;