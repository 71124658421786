import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CSVLink } from "react-csv";

import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import { Checkbox, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel,
  Toolbar, Typography, IconButton, Tooltip, TextField, Paper} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import BasicFunctions from 'helpers/BasicFunctions';
import CircularProgress from 'components/CircularProgress';
import ZiplyneButton from '../Button/Button'
import './table.css'

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      borderRadius: "0 !important",
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      borderRadius: "0 !important",
    },
    table: {
      // minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));

  const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);

export default function TableComponent({pageName, datas, columns, name, edit, _delete, onClickHandler, onBulkDeleteHandler, onBulkEditHandler,downloadData, useHidden,zipDetails, onSelectHandler,
  handleMigrateButton, migratedZipIds, branchingId, totalLength, fetchRefresh, spinEnable
}) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState(migratedZipIds ? migratedZipIds : []);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState(undefined);

  function searchHandler(value) {
    setSearch(value);
  }
  useEffect(() => {
    if (search !== undefined)
    document.getElementById('search').focus()
  }, [search]);

  const lowercasedFilter = search ? search.toString().toLowerCase(): '';
  const filteredData = datas.filter(item => {
    if (item) {
      return Object.keys(item).some(key => {
        return !item[key] ? '': item[key].toString().toLowerCase().includes(lowercasedFilter)
      });
    }
    return
  });

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  

  function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
        {
          (pageName === 'allZips' || pageName === 'modalManager' || pageName === 'snaps' || pageName === 'contentLinks' || pageName === 'zipManagement' || pageName === 'migration' ||
            pageName === 'subCompanies') && <TableCell>
            <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />
          </TableCell>
         }
          {columns.map(headCell => (
            headCell.id === 'hidden' ? null :
            <TableCell
              key={headCell.id}
              style={headCell.id === 'screenshot' ? {position:'sticky', left: '1px', background:'white', zIndex:1}:{}}
              sortDirection={orderBy === 'headCell.id' ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {/* {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? '' : ''}
                  </span>
                ) : null} */}
              </TableSortLabel>
            </TableCell>
          ))}
           {columns[columns.length - 1].deleteAll ? <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            />
          </TableCell>: null}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  const useToolbarStyles = makeStyles(theme => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            // color: theme.palette.secondary.main,
            color: "#262a94",
            // backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            backgroundColor: "#e4d4ff"
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }));
  
  const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
  
    return (
      <Toolbar
        className={clsx((classes.root, classes.search), {
          [classes.highlight]: numSelected > 0,
        })}
      >

        {((pageName === 'allZips' || pageName === 'snaps' || pageName === 'modalManager' || pageName === 'zipManagement' || pageName === 'migration' || pageName === 'subCompanies') && numSelected > 0) ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <div style={{width: '100%'}}>
          <Typography className={classes.title} variant="h6" id="tableTitle">
            {name}
          </Typography>
          {(downloadData !== undefined && downloadData !== null) ?
          
          <CSVLink
          data={downloadData}
          filename={"my-file.csv"}
          target="_blank"
        >
          Download 
        </CSVLink>: null }
          </div>
          
        )}
  
        {((pageName === 'allZips' || pageName === 'snaps' || pageName === 'modalManager' || pageName === 'contentLinks' || pageName === 'subCompanies') && numSelected > 0) ? (
          <>
            {
              pageName === 'allZips' && <Tooltip title="Edit">
              <IconButton aria-label="edit">
                <CreateIcon onClick={() => selected.length === 1 ? edit(selected[0]) : onBulkEditHandler(selected)} />
              </IconButton> 
            </Tooltip>
            }
            <Tooltip title="Delete">
              <IconButton aria-label="delete">
                <DeleteIcon onClick={() => {
                  onBulkDeleteHandler(selected);
                  setSelected([]);
                }}/>
              </IconButton>
            </Tooltip>
            {
              pageName === 'allZips' && <Tooltip>
                <ZiplyneButton
                  customWidth="30%"
                  background="#673ab7"
                  onClick={() => handleMigrateButton(selected)}
                >
                  Migrate
                </ZiplyneButton>
              </Tooltip>
            }
          </>
        ) : (
          <Tooltip title="Search" style={{background: "white !important"}}>
            <TextField id="search" label="Search" variant="outlined" style={{width:'25%'}} size="small" onChange={(e)=> searchHandler(e.target.value)} value={search}/>
          </Tooltip>
        )}
        {
          (pageName === 'zipManagement' && numSelected > 0) ? 
          <>
            {
              pageName === 'zipManagement' && <Tooltip title="Next">
                <ZiplyneButton
                  background="#673ab7"
                  onClick={() => {
                    onSelectHandler(selected)
                    setSelected([])
                  }}
                  custommarginLeft="1rem"
                >
                  Next
                </ZiplyneButton>
              </Tooltip>
            }
          </> : null
        }
        {
          (pageName === 'migration' && numSelected > 0) ?
          <Tooltip title="Send">
            <ZiplyneButton
              background="#673ab7"
              customWidth="100%"
              onClick={() => {
                onSelectHandler(branchingId, selected);
                setSelected([])
              }}
            >
              Set Domain
            </ZiplyneButton>
          </Tooltip> : null
        }
        {
          pageName === 'migration' ? 
          <Tooltip title='Set Default'>
            <ZiplyneButton 
              background="#673ab7"
              customWidth= "50%"
              custommarginLeft="1rem"
              onClick={() => onClickHandler(branchingId)}
            >
              Set to Default
            </ZiplyneButton>
          </Tooltip> : null
        }
      </Toolbar>
    );
  };
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };
  

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = filteredData.map(n => n[columns[0].id]);
      // const newSelecteds = filteredData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    // if(newPage > page) {
    //   fetchRefresh()
    // }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredData.length - page * rowsPerPage);
  

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes.root}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredData.length}
            />
            {
              <TableBody>
                {stableSort(filteredData, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    let _index = index + (rowsPerPage * page);
                    {/* isItemSelected = migratedZipIds.includes(row[columns[0].id]) */}
                    const isItemSelected = isSelected(row[columns[0].id]);
                    {/* const isItemSelected = isSelected(row.name); */}
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (

                      <StyledTableRow
                        hover
                        // onClick={(event) => handleClick(event, row[columns[0].id])}
                        // role="checkbox"
                        // aria-checked={isItemSelected}
                        // tabIndex={-1}
                        key={row[columns[0].id]}
                      >
                        {
                          (pageName === 'allZips' || pageName === 'modalManager' || pageName === 'snaps' || pageName === 'contentLinks' || pageName === 'zipManagement' || pageName === 'migration' || pageName === 'subCompanies') && <TableCell
                            onClick={(event) => handleClick(event, row[columns[0].id])}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                          >
                          <Checkbox
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                        </TableCell>
                        }
                      {/* <TableCell padding="checkbox">
                          <Checkbox className="selectItem"/>
                        </TableCell> */}
                      {columns.map((column, columnIndex) => {
                      const value = row[column.id];

                      if (column.id === 'hidden') {return (<div key={column.id} style={{display :'none'}}></div>)} else {
                      return (
                        <>
                        <StyledTableCell key={column.id} style={column.id === 'screenshot'? {position:'sticky', left: '1px', background:'white'}: {}}>
                        {
                          column.id === 'clickableSearchTitle'? <div className="hover-thing selected-color" onClick={() => onClickHandler({searchTerm: row['clickableSearchTitle'], url: row['url']})}> {value} </div>: 
                          column.id === 'clickableTitle'? <div className="hover-thing selected-color" onClick={() => onClickHandler(row['hidden'])}> {value} </div> :
                          column.id === 'logo' ? <img width="50px" height="50px" alt="..." src={value}/> : 
                          column.id === "bigImg" ? <img width="200px" alt="..." src={value}/> :
                          column.id === 'zipClickName'? <span className="selected-color hover-thing" onClick={() =>(useHidden === undefined || useHidden === null || useHidden.length === 0)? zipDetails(_index) : zipDetails(row['hidden'])}>{value}</span>  :
                          column.id === 'screenshot' ? <img src={value} height="85" width="85" alt="" onClick={() => onClickHandler(value)}/> :
                          column.id === 'screenshot_url' ? <p dangerouslySetInnerHTML={{ __html: value }}></p> :
                          column.id === 'url' ? <a href={value} target="_blank" rel="noopener noreferrer">{value}</a> :
                          column.id === 'autoplay' ? <Checkbox checked={value} onClick={(e) => onClickHandler(filteredData[_index], e.target.checked)}></Checkbox> :
                          column.id === 'killSwitchStatus' ? value ? 'Is Active' : '' :
                          column.id === 'version' ? value.replace(/-/, '.') :
                          column.id === 'zipName' ? <div>{value}</div> :
                          column.id === 'admins' ? value.map(admin => <div>{admin}</div>) :
                          column.id === 'action' ? 

                          (<div style={{display: 'inline-flex'}}>{column.edit &&<Tooltip title="Edit"><IconButton aria-label="edit"><CreateIcon onClick={() =>(useHidden === undefined || useHidden == null || useHidden.length === 0)? edit(_index) : edit(row['hidden']) /*() => edit(filteredData[_index])*/}/></IconButton></Tooltip>}
                          {column.delete && <Tooltip title="Delete"><IconButton aria-label="delete"><DeleteIcon onClick={() => _delete(filteredData[_index])}/></IconButton></Tooltip>}

                          </div>
                          )
                          : column.id === 'updatedAt' || column.id === 'createdAt' || column.id === 'createdOn' || column.id === 'lastModifiedOn' || column.id === 'addedOn' ? BasicFunctions.displayTime(value) : value }
                        </StyledTableCell>
                        </>
                      );}
                      })}
                      {columns[columns.length -1].deleteAll ? <StyledTableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        // onClick={event => handleClick(event, row[columns[0].id])}
                        onClick={event => handleClick(event, row[columns[0].id])}
                      />
                      </StyledTableCell> : null}
                      </StyledTableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            }
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[25, 35, 45]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}