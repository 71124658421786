import zips from '../../assets/images/sidebar/zips.svg';
import analytics from '../../assets/images/sidebar/analytics.svg';
import customizer from '../../assets/images/sidebar/customizer.svg';
import mobile from '../../assets/images/sidebar/mobile.svg';
import snap from '../../assets/images/sidebar/snaps.svg';
import users from '../../assets/images/sidebar/users.svg';
import web from '../../assets/images/sidebar/web.svg';
import logout from '../../assets/images/sidebar/logout.svg';
import rating from '../../assets/images/sidebar/rating.svg';
import integration from '../../assets/images/sidebar/integration.svg';
import embed from '../../assets/images/sidebar/embed.svg';
import player from '../../assets/images/sidebar/player.svg'
import appInfo from '../../assets/images/sidebar/appInfo.svg';
import phoneSettings from '../../assets/images/sidebar/phoneSettings.svg';
import elements from '../../assets/images/sidebar/elements.svg';
import zipContent from '../../assets/images/sidebar/zipContent.svg';
import migrationHistory from '../../assets/images/sidebar/migrationHistory.svg';
import shareable from '../../assets/images/sidebar/shareable.svg';
import assets from '../../assets/images/sidebar/assets.svg';
import screens from '../../assets/images/sidebar/screens.svg';
import selectorSettings from '../../assets/images/sidebar/selectorSettings.svg';
import zipMonitor from '../../assets/images/sidebar/zipMonitor.svg';
import expManager from '../../assets/images/sidebar/expManager.svg';
import modalManager from '../../assets/images/sidebar/modalManager.svg';
import application from '../../assets/images/sidebar/application.svg';
import companies from '../../assets/images/sidebar/companies.svg';
import dashboardOverview from '../../assets/images/sidebar/dashboardOverview.svg';
import setup from '../../assets/images/sidebar/setup.svg';
import apps from '../../assets/images/sidebar/apps.svg';
import deleteOutline from '../../assets/images/zips/deleteOutline.svg';
import tag from '../../assets/images/sidebar/tag.svg';
import embedUtils from '../../assets/images/sidebar/embedUtils.svg';
import contentLinks from '../../assets/images/sidebar/contentLinks.svg';

const images = {
    contentLinks,
    embedUtils,
    zips,
    analytics,
    customizer,
    migrationHistory,
    mobile,
    snap,
    users,
    web,
    logout,
    rating,
    integration,
    embed,
    player,
    zipContent,
    assets,
    selectorSettings,
    screens,
    elements,
    shareable,
    appInfo,
    phoneSettings,
    zipMonitor,
    expManager,
    modalManager,
    application,
    companies,
    dashboardOverview,
    setup,
    apps,
    deleteOutline,
    tag
}

export default images;
