import React, { Component } from 'react';
import ZipMonitorService from '_services/zipmonitor';
import { getCurrentCompany } from 'actions/AuthHelperMethods';
import CircularProgress from 'components/CircularProgress/index';
import { withStyles, Grid,  } from "@material-ui/core/";
import ContainerHeader from 'components/ContainerHeader/index';
import IntlMessages from 'util/IntlMessages';
import NothingToDisplay from '../../../../../components/Card/NothingToDisplay';
import ListZip from './listZip';
import ListZipDetails from './listZipDetails';
import ZipMonitorSettings from './zipMonitorSettings';
import WebZipServices from '_services/webZips';
import Modal from 'components/Modals/modal';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import './zipmonitor.css';
import { typeIds } from 'config';

const styles = theme => ({
    header: {
        width: '50%',
        textAlign: 'center'
    },
    tab: {
        color: '#262a94',
        width: '50%',
        display: 'inline-block',
        margin: 0,
        padding: 15,
        height: 50,
        background: 'white',
        borderRadius: '0 !important',
        //boxShadow: '0px 8px 3px 0px rgba(0,0,0,0.2)',
        cursor: 'pointer',
        fontSize: "20px"
    },
})

class ZipMonitor extends Component {
    companyId = getCurrentCompany().id;
    state = {
        spinEnable: true,
        tab: 1,
        showModal: false,
        maxThreshold:'',
        incFreq: '',
        zipMonitorList: [],
        page: 1,
    }
    constructor(props) {
        super(props);
        this.scrollerRef = React.createRef();
        this.onClickHandler = this.onClickHandler.bind(this);
    }
    componentDidMount() {
        let pathname = window.location.pathname;
        if (pathname.match('/zipmonitordetail')) {
            let zipSettingUrl = pathname.split('/')[2];
            this.setState({zipId: zipSettingUrl})
        }
        this.fetchZipMonitor()
    }

    handleScroll = async () => {
        const scroller = this.scrollerRef.current;
        if (scroller.scrollHeight - scroller.scrollTop === scroller.clientHeight) {
            this.setState((prev) => ({
                page: prev.page + 1,
            }), () => {
                this.fetchZipMonitor();
            })
        }
      }

    onClickHandler = (type, value, zipName) => {
        if (type === 'zipId') this.setState({[type]: value}, () => this.fetchZipDetails());
        else if (type === 'save') this.saveZipMonitorSetting();
        else if (type === 'setting') this.setState({zipId: value}, () => this.getZipSetting(value, zipName));
        else if (type === 'tab') this.setState({[type]: value}, () => {
            if (this.state.tab === 1) this.fetchZipMonitor();
            else if (this.state.tab === 2) this.getZipMonitorSetting();
        })
    }

    onChangeHandler = (type, value) => {
        if (type === 'threshold' && value === '') value = ' '
        this.setState({[type]: value})
    }

    structureData = async (data) => {
        let tempList = []
        let guidesId = [];

        data.forEach(zip => {
            if (!guidesId.includes(zip.guide_id)) {
                guidesId.push(zip.guide_id)
            }
        });
        let payload = {
            guide_id: guidesId.join(','),
            customQuery: 'guide_steps'
        }
        let res = await WebZipServices.getPagesInfo(payload);
        let stepsData = res.data.data;
        guidesId.forEach(guideId => {
            const filteredGuides = data.filter(zip => zip.guide_id === guideId);
            const guideSteps = stepsData.filter(step => step.guide_id === guideId);
            let numOfSteps = guideSteps.length > 0 ? guideSteps[0].guide_steps.length : 0;
            let obj = {
                name: filteredGuides[0].guide.name,
                id: filteredGuides[0].guide_id,
                zip_type_id: filteredGuides[0].guide_type_id,
                status: filteredGuides[0].guide.status_id,
                step: numOfSteps,
                incidents: filteredGuides.length,
                creator: `${filteredGuides[0].guide.user.first_name} ${filteredGuides[0].guide.user.last_name}` ,
                url: `https://` + filteredGuides[0].guide.site,
                incidentsData: filteredGuides
            }
            tempList.push(obj);
        })
        return tempList;
    }

    fetchZipMonitor = () => {
        const scrollTop = this.scrollerRef.current?.scrollTop;
        const payload = {
            company_id: getCurrentCompany().id,
            customQuery: 'guide(user)' 
        }
        ZipMonitorService.getZipMonitor(payload).then(async response => {
            if (response.status === 200) {
                const rows = response.data.data.datas;
                let newData = await this.structureData(rows);
                this.setState({spinEnable: false, zipMonitorList: newData}, () => {
                    if(scrollTop && newData.length > 0){
                        this.scrollerRef.current.scrollTop = scrollTop;
                    }
                })
            }
        })
    }

    fetchZipDetails = (ElementId) => {
        let zipId = this.state.zipId
        ZipMonitorService.getZipDetails(zipId).then(response => {
            if (response.status === 200) {
                this.setState({spinEnable: false, zipDetails: response.data.rows}, () => {
                    if (ElementId) {
                        let selectedElement = document.getElementById(ElementId);
                        if(selectedElement) selectedElement.classList.add('active-list')
                    }
                })
            }
        })
    }

    getZipSetting = (zipId, zipName) => {
        this.setState({zipMonitorSetting: undefined, showModal: true, zipName}, () => {
            let params = {
                company_id: this.companyId,
                guide_id: zipId
            }
            ZipMonitorService.getZipMonitorSetting(params).then(response => {
                if (response.status === 200) {
                    let settings = response.data.data.datas;
                    if (settings.length === 0) {
                        this.setState({threshold: ' ', max_threshold: ' ', email: ' ', zipSettings: false})
                    } else {
                        let { threshold, max_threshold, emails, scope } = settings[0].guide_configuration;
                        this.setState({threshold: undefined, email: undefined, scope: 0})
                        this.setState({
                            threshold: max_threshold,
                            email: emails,
                            scope,
                            zipSettings: true,
                            zipSettingsId: settings[0].id
                        })
                    }
                }
            })
        })
    }

    saveZipSetting = async () => {
        const {email, threshold,maxThreshold,incFreq,zipId, scope, zipSettings, zipSettingsId} = this.state;
        let zipTypeId = typeIds.guideType.filter(el => el.name === 'zip')[0].id;
        if (zipSettings) {
            let payload = {
                "company_id": this.companyId,
                "guide_id": zipId,
                "guide_type_id": zipTypeId,
                "guide_configuration": {
                    "emails": email.trim(),
                    "threshold": "",
                    "scope": scope ? 0 : 1,
                    "max_threshold": threshold,
                    "frequency": incFreq
                }
            };
            let uuid = zipSettingsId;
            ZipMonitorService.saveZipSetting(uuid, payload).then(response => {
                if (response.status === 200) {
                    NotificationManager.success('Settings Updated Successfully', 'Success')
                    this.handleReset();
                    this.fetchZipMonitor();
                }
            })
        } else {
            let payload = {
                "company_id": this.companyId,
                "guide_id": zipId,
                "guide_type_id": zipTypeId,
                "guide_configuration": {
                    "emails": email.trim(),
                    "threshold": "",
                    "scope": scope ? 0 : 1,
                    "max_threshold": threshold,
                    "frequency": incFreq
                }
            }
            ZipMonitorService.addZipSetting(payload).then(response => {
                if (response.status === 201 || response.status === 200) {
                    NotificationManager.success('Settings Added Successfully', 'Success')
                    this.handleReset();
                }
            })
        }
    }

    getZipMonitorSetting = () => {
        let params = {
            company_id: this.companyId,
            guide_id: null
        }
        ZipMonitorService.getZipMonitorSetting(params).then(response => {
            if (response.status === 200) {
                let settings = response.data.data.datas;
                settings = settings.filter(setting => (setting.guide_id === undefined || setting.guide_id === null))
                if (settings.length === 0) {
                    this.setState({threshold: ' ', email: ' ', zipMonitorSetting: false})
                } else {
                    let { threshold, emails, max_threshold, frequency } = settings[0].guide_configuration;
                    this.setState({
                        threshold,
                        maxThreshold: max_threshold,
                        incFreq: frequency,
                        email: emails, 
                        // Id: settings.id,
                        zipMonitorSetting: true,
                        zipMonitorSettingId: settings[0].id
                    })
                }
            }
        })
    }

    saveZipMonitorSetting = () => {
        const {Id, threshold, email,maxThreshold,incFreq, zipMonitorSetting, zipMonitorSettingId} = this.state;
        const zipTypeId = typeIds.guideType.filter(el => el.name === 'zip')[0].id
        if (zipMonitorSetting) {
            let payload = {
                "company_id": this.companyId,
                "guide_type_id": zipTypeId,
                "guide_configuration": {
                    "emails": email.trim(),
                    "threshold": threshold,
                    "max_threshold": maxThreshold,
                    "frequency": incFreq
                }
            };
            let uuid = zipMonitorSettingId;
            ZipMonitorService.saveZipSetting(uuid, payload).then(response => {
                if (response.status === 200) {
                    NotificationManager.success('Settings Updated Successfully', 'Success')
                    this.handleReset()
                }
            })
        } else {
            let payload = {
                "company_id": this.companyId,
                "guide_type_id": zipTypeId,
                "guide_configuration": {
                    "emails": email.trim(),
                    "threshold": threshold,
                    "max_threshold": maxThreshold,
                    "frequency": incFreq
                }
            }
            ZipMonitorService.addZipSetting(payload).then(response => {
                if (response.status === 201 || response.status === 200) {
                    NotificationManager.success('Settings Added Successfully', 'Success')
                    this.handleReset()
                }
            })
        }
        
    }

    onSelectHandler = (type, data) => {
        if (type === 'zipId') {
            this.setState({
                zipDetails: data
            })
        } else if (type === 'setting') {
            this.setState({zipId: data.id, zipTypeId: data.zip_type_id}, () => this.getZipSetting(data.id, data.name))
        } else if (type === 'save') this.saveZipMonitorSetting();
    }

    handleClose = () => {
        this.handleReset();
    }

    handleReset = () => {
        this.setState({
            // threshold: undefined,
            scope: undefined,
            email: undefined,
            showModal: false,
            zipSettings: false,
            zipSettingsId: undefined,
            zipMonitorSetting: false,
            zipMonitorSettingId: undefined
        })
    }

    render () {
        const {classes, match} = this.props;
        const {tab, spinEnable, zipMonitorList, zipDetails, threshold, email, scope, showModal, zipName, maxThreshold,incFreq } = this.state;
        return (
            <div>
                <ContainerHeader title={<IntlMessages id="sidebar.zipmonitor"/>} match={match}/>
                <div className={classes.header}>
                    <p className={classes.tab} onClick={()=>this.onClickHandler('tab', 1)}>Overview</p>
                    <p className={classes.tab} onClick={()=>this.onClickHandler('tab', 2)}>Settings</p>
                </div>
                <>{spinEnable ? <CircularProgress /> : 
                (tab === 1) ?
                <div style={{marginTop: 10}}>
                    <Grid container justify="center">
                        <Grid item xs={6}>
                            {zipMonitorList && zipMonitorList.length > 0 ? 
                                <ListZip scrollRef={this.scrollerRef} scrollHandler={this.handleScroll} data={zipMonitorList} onSelect={this.onSelectHandler} onClickHandler={this.onClickHandler}/>
                                : <NothingToDisplay />
                            } 
                        </Grid>
                        <Grid item xs={6}>
                            {zipDetails && 
                                <ListZipDetails data={zipDetails}/> 
                            }
                        </Grid>
                    </Grid>
                </div>
                :  
                (tab === 2) ? 
                <div style={{width: '50%'}}>
                {/* <ZipMonitorSettings threshold={threshold} email={email} incFreq={incFreq} maxThreshold={maxThreshold} onChange={this.onChangeHandler} onClick={this.onClickHandler}/> */}
                    {(threshold || email) && 
                    <ZipMonitorSettings threshold={threshold} email={email} incFreq={incFreq} maxThreshold={maxThreshold} onChange={this.onChangeHandler} onClick={this.onClickHandler}/> }
                </div> 
                :
                <div>
                </div>
                }</>
                {showModal && 
                <Modal 
                title={`Configure Zip : ${zipName}`}
                type='zipSettings'
                close={this.handleClose} 
                threshold={threshold}
                scope={scope}
                email={email}
                onClick={this.onSelectHandler}
                onChange={this.onChangeHandler}
                handleSave={this.saveZipSetting}
                />}
                <NotificationContainer/>
            </div>
        )
    }
}

export default withStyles(styles, {withTheme: true})(ZipMonitor);