import React from 'react';
import { TextField, Switch, FormControl, Select } from '@material-ui/core';

const EditEmbed = (props) => {
    let { version, url, killSwitchStatus, comment } = props.data;

    return (
        <div>
            <FormControl fullWidth>
                <TextField label="URL" value={url} disabled/>
                <br/>
                <TextField label="Comment" value={comment} onChange={(e) => props.onChange('comment', e.target.value)}/>
                <br/>

                <Select native value={version} onChange={(e) => props.onChange('version', e.target.value)}>
                    <option value="">Select Version</option>
                    {props.versions.map(verison => <option value={verison.value} key={verison.name}>{verison.name}</option>)}
                </Select>
                <br/>
                <br/>
                <div>Kill Switch : </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div>In-Active</div>
                    <Switch
                        checked={Boolean(killSwitchStatus)}
                        onChange={(e) => props.onChange('killSwitchStatus', e.target.checked)}
                        name="checkedA"
                        color="primary"
                    />
                    <div>Is Active</div>
                </div>
            </FormControl>
        </div>
    )
}

export default EditEmbed