import React from 'react';
import {Typography, Card, CardContent, TextField, Checkbox} from '@material-ui/core';
import ZiplyneButton from 'components/Button/Button';
import './zipmonitor.css';

const zipMonitorSetting = ({type, threshold,maxThreshold ,incFreq,email, scope, onChange, onClick}) => {
    return (
        <>
        {!type ? 
        <>
        <Typography style={{background: '#946BEA', color: '#fff', padding: 10, opacity: '88%'}}>Configure Zip Monitor Settings</Typography>
            <Card>
                <CardContent>
                    <div>
                        <Typography variant="subtitle1" style={{verticalAlign: 'bottom', fontSize:"14px"}}>Maximum Threshold to trigger alert Email</Typography>&emsp;
                        <TextField type="number" size="small" value={threshold} 
                            onChange={(e) => onChange('threshold', e.target.value)} 
                            InputProps={{ inputProps: { min: 0 } }}/>
                    </div>
                    <div>
                        <Typography variant="subtitle1" style={{verticalAlign: 'bottom', fontSize:"14px"}}>Incremental Threshold</Typography>&emsp;
                        <TextField type="number" size="small" value={maxThreshold} 
                            onChange={(e) => onChange('maxThreshold', e.target.value)} 
                            InputProps={{ inputProps: { min: 0 } }}/>
                    </div>
                    <div>
                        <Typography variant="subtitle1" style={{verticalAlign: 'bottom', fontSize:"14px"}}>Incremental Frequency</Typography>&emsp;
                        <TextField type="number" size="small" value={incFreq} 
                            onChange={(e) => onChange('incFreq', e.target.value)} 
                            InputProps={{ inputProps: { min: 0 } }}/>
                    </div>
                    <br/>
                    <br/>
                    <div>
                        <Typography variant="subtitle1" style={{fontSize: "14px"}}>Invite Team</Typography>
                        <TextField label="Enter Comma Separated Emails" fullWidth value={email} 
                            onChange={(e) => onChange('email', e.target.value)}/>
                    </div>
                    <br/>
                    <div style={{textAlign: 'right'}}>
                        <ZiplyneButton variant="gradient" onClick={() => onClick('save')}>Save</ZiplyneButton>
                    </div>
                </CardContent>
            </Card>
            </>: 
            <>
            {threshold &&
            <>
            <div>
                <Typography variant="subtitle1" style={{verticalAlign: 'bottom', fontSize:"14px"}}>Maximum Threshold to trigger alert Email</Typography>&emsp;
                <TextField type="number" size="small" value={threshold} 
                    onChange={(e) => onChange('threshold', e.target.value)}
                    InputProps={{ inputProps: { min: 0} }}/>
            </div>
            <br/>
            <br/>
            <div>
                <Typography variant="subtitle1" style={{fontSize: "14px"}}>Invite Team</Typography>
                <TextField style={{fontSize:"14px"}} label="Enter Comma Separated Emails" fullWidth value={email} 
                    onChange={(e) => onChange('email', e.target.value)}/>
            </div>
            <br/>
            <div>
                <div>Apply Only This Setting?
                <span><Checkbox checked={scope === 1 || scope === true ? true : false} onChange={(e) => onChange('scope', e.target.checked)}/></span></div>
            </div>
            </>
            }
            </>
            }
        </>
    )
}

export default zipMonitorSetting;