import UserInfo from 'components/UserInfo';
import React, {useEffect, useState} from 'react'
import { NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import SignInServices from '_services/sigin';
import UserServices from '_services/users'

const VerifyUser = (props) => {
    const [email, setEmail] = useState('');
    useEffect(() => {
        let search = props.location.search;
        let arr = search.split('&');
        let data = {}
        arr.forEach(val => {
        let key = val.split('=')[0];
        let value = val.split('=')[1];
        data[key] = value;
        })
        let params = {
            email: data['verifylink']
        }
        UserServices.getUsers(params).then(res => {
            let userData = res.data.data.datas[0];
            const { id, company_id, first_name, last_name, email, roles, username, user_details } = userData;
            let payload = {
                id,
                company_id,
                username,
                first_name,
                last_name,
                email,
                roles,
                user_details: {
                    ...user_details,
                    mail_confirmed: true
                }
            }
            SignInServices.resetPassword(payload).then(res => {
                if (res.status === 201) {
                    NotificationManager.success('User Verified', 'Success');
                }
            });
        })
        setEmail(data['verifylink']);
    }, [])

    return (
        <div className="app-background">
            <div className="login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="login-content">
                    <div className="login-header">
                    <a style={{cursor: "pointer"}} className="app-logo" href="/" title="Jambo">
                        <img src="./ziplyne_dropshadow.png" alt="jambo" title="jambo"/>
                    </a>
                    </div>

                    <div className="mb-2">
                    <h2><IntlMessages id="appModule.verifyUser"/> !!!</h2>
                    <h3><span style={{fontWeight: "bold"}}>Email</span> - {email}</h3>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default VerifyUser;
