import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import SignServices from '_services/sigin';
import { NotificationContainer, NotificationManager } from 'react-notifications';


const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const onChangeHandler = (e) => {
    setEmail(e.target.value)
  }

  const onClickHandler = () => {
    SignServices.forgotPassword({email}).then(response => {
      if (response.status === 201) {
        NotificationManager.success('Link Sent to Mail', 'Success')
      } else {
        NotificationManager.error(response.data.message, 'Error')
      }
    })
  }

  return (
    <div className="app-background">
    <div className="login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="login-content">
        <div className="login-header">
          <Link className="app-logo" to="/" title="Jambo">
            <img src="./ziplyne_dropshadow.png" alt="jambo" title="jambo"/>
          </Link>
        </div>

        <div className="mb-2">
          <h2><IntlMessages id="appModule.forgotPassword"/></h2>
        </div>

        <div className="login-form">
          <TextField fullWidth
            type="email" label={<IntlMessages id="appModule.email"/>}
            margin="normal" className="mt-0 mb-4" onChange={onChangeHandler}/>

          <Button variant="contained" color="primary" className="text-white" onClick={onClickHandler}>
            <IntlMessages id="appModule.resetPassword"/>
          </Button>
            
          <br/>
          <br/>
          <Link to="signin">Login ?</Link>
          <NotificationContainer />
        </div>
      </div>
    </div>
    </div>
  );
};

export default ForgotPassword;

