import React, {Component} from 'react';
/*import Dropzone from 'components/FileUpload/DropZone';
*/import { TextField, FormControl } from '@material-ui/core';

class EditLogoModal extends Component {
    state = {
        showDropZone: {display: 'none'}
    }

    render () {
        const {EditLabelName,DisplayName, onChange } = this.props.editData;
        return (
            <FormControl fullWidth>
                <TextField label={DisplayName} value={EditLabelName} onChange={(e) => onChange('EditLabelName', e.target.value)}/>
               
            </FormControl>
        )
    }
}

export default EditLogoModal;