import ZiplyneButton from "components/Button/Button";
import ZipLoader from "components/ZipLoader";
import React from "react";
import "./index.css";
import * as Sentry from "@sentry/browser";

const ErrorFallbackUI = (props) => {
  return (
    <div className="loader-wrap">
      <ZipLoader />
      <div className="loader-title">Something Went Wrong !!</div>
      <div className="loader-title">Try Refresh Page</div>
      <ZiplyneButton
        onClick={() => {
          Sentry.showReportDialog({ eventId: props.eventId,  });
        }}
        variant="gradient"
      >
        Report feedback
      </ZiplyneButton>
    </div>
  );
};

export default ErrorFallbackUI;
