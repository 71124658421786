import React, { Component } from "react";
import PropTypes from "prop-types";

import * as Sentry from "@sentry/browser";
import ErrorFallbackUI from "components/ErrorFallbackUI";

export default class ErrorBoundary extends Component {
  state = {
    error: "",
    eventId: "",
    errorInfo: "",
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });
    // SEND ERROR LOG TO SENTRY
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, errorInfo });
    });
  }

  render() {
    const { hasError, errorInfo, eventId } = this.state;
    if (hasError) {
      return <ErrorFallbackUI eventId={eventId} />;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
