import * as AuthenticateAPI from '../actions/ApiCalls';

const createShareable = (payload) => {
    return AuthenticateAPI.postResponse(`guideshareable`, payload);
}
const fetchShareable = (payload) => {
    return AuthenticateAPI.getResponse(`guideshareable`, payload);
}
const addNewFav = (payload) => {
	return AuthenticateAPI.postMobileResponse(`shareableZips/addNewFav`, payload);
}
const fetchFav = (payload) => {
	return AuthenticateAPI.postMobileResponse(`shareableZips/fetchFavZips`, payload);
}
const deleteShareble = (payload) => {
	return AuthenticateAPI.deleteResponse(`guideshareable/${payload.uuid}`);
}
const updateShareable = (uuid, payload) => {
	return AuthenticateAPI.putResponse(`guideshareable/${uuid}`, payload);
}
const sendEmail = (payload) => {
	return AuthenticateAPI.postResponse(`guideshareable/sendmail`, payload);
}
const ShareableZipsServices = {
	createShareable, fetchShareable, addNewFav,fetchFav, deleteShareble, updateShareable
	 ,sendEmail

}

export default ShareableZipsServices;