import * as AuthenticateAPI from '../actions/ApiCalls';

const getZips = (payload) => {
    return AuthenticateAPI.getResponse(`guide`, payload);
}

const updateZip = (uuid, payload) => {
    return AuthenticateAPI.putResponse(`guide/${uuid}`, payload)
}

const deleteZip = (payload) => {
    return AuthenticateAPI.deleteResponse(`guide/${payload.uuid}`)
}

const updateZipBulk = (payload) => {
    return AuthenticateAPI.postResponse(`walkthrough/updateBulk/data`, payload)
}

const fetchStatus = (payload) => {
    return AuthenticateAPI.getResponse(`status`, payload)
}

const fetchMigratedZips = (payload) => {
    return AuthenticateAPI.getResponse(`guidemigration`, payload)
}

const fetchBranching = (payload) => {
    return AuthenticateAPI.getResponse(`guidebranching`, payload)
}

const guideLive = (payload) => {
    return AuthenticateAPI.getResponse('guide/sendmail', payload);
}

const ZipServices = {
    getZips, updateZip, deleteZip, updateZipBulk, fetchStatus, fetchMigratedZips, fetchBranching,
    guideLive
}

export default ZipServices;