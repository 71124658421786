import React from 'react';
import TextField from '@material-ui/core/TextField';

export const EditCategoriesAndTags = ({data, editName, onChange}) => {
    return (
        <>
            <TextField value={editName} onChange={(e) => onChange('editName', e.target.value)}/>
            <div>Created By: {data.createdBy}</div>
            <div>Created On: {data.createdOn}</div>
        </>
    )
}