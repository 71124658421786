import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import UserService from '../../_services/users';
import CloseIcon from '@material-ui/icons/Close';
import Dropzone from '../FileUpload/DropZone';
import ZiplyneButton from 'components/Button/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Dialog, Typography, IconButton, FormControl, TextField, Select, InputLabel } from '@material-ui/core';
import AddEditUser from 'app/routes/webApp/routes/Users/edit_user';
import AddGroup from 'app/routes/webApp/routes/ExperienceManager/addGroup';
import AddEditServices from 'app/routes/webApp/routes/Integration/add_service';
import RequestService from 'app/routes/webApp/routes/Integration/requestService';
import SelectorSettingsModal from 'components/MobileApp/Ignore/selectorSettingsModal';
import UploadLogoModal from 'components/MobileApp/UploadLogo/uploadLogoModal';
import UploadAssetModal from 'components/MobileApp/UploadAssets/UploadAssetModal';
import EditLogoModal from 'components/MobileApp/UploadLogo/editLogoModal';
import CompaniesModal from 'components/MobileApp/Companies/companiesModal';
import NewCompaniesModal from 'components/MobileApp/NewCompanies/NewCompaniesModal';
import AddSubCompaniesModal from 'components/MobileApp/SubCompanies/AddSubCompaniesModal';
import EditSubCompaniesModal from 'components/MobileApp/SubCompanies/EditSubCompaniesModal';
import EditCompanyModal from 'components/MobileApp/NewCompanies/EditCompanyModal';
import ShareableModal from 'components/MobileApp/Shareables/ShareableModal';
import UserSearchModal from 'components/MobileApp/UserSearch/userSearchModal';
import ProfileModal from 'components/UserInfo/ProfileModal';
import PasswordModal from 'components/UserInfo/PasswordModal';
import { EditCategoriesAndTags } from 'app/routes/webApp/routes/CategoriesAndTags/EditCategoriesAndTags';
import ZipMonitorSettings from 'app/routes/webApp/routes/ZipMonitor/zipMonitorSettings';
import EditEmbed from 'app/routes/webApp/routes/EmbedModule/editEmbed';
import SelectMultipleModal from 'components/MobileApp/ThemeManager/selectMultipleModal';
import AllDomainsModal from 'components/Template/component/allDomainsModal'

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    userSerachDetailsSize: {
        width: '80%',
        maxWidth: 'none'
    },
    dialogPaper: {
        width: '35%',
    },
    screenshot: {
        width: '80%',
        maxWidth: 'none'
    }
});



const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class Modal extends Component {
    url = UserService.downloadExcel();
    state = {
        open: true,
    }
    componentDidMount() {
        
    }
    handleClose = () => {
        this.setState({open: false}, this.props.close())
    };

    handleCancel = () => {
        this.setState({open: false}, this.props.close())
    };    

    render() {
        if (this.props.type === "userSerachDetails") {
            let a = document.querySelector('div[role="dialog"]');
            if (a) {
                a.classList.remove("MuiDialog-paperWidthSm");
            }
        }
        const { title, type, classes, data, text, name } = this.props;
        const zipStatus = ["DRAFT", "TEST", "LIVE", "ARCHIVED"]
        return (
            <div>
            <Dialog disableEnforceFocus onClose={this.handleClose} open={this.state.open} classes={{ paper: type === 'screenshot'? classes.screenshot : type=== "userSerachDetails" ? classes.userSerachDetailsSize :classes.dialogPaper}}>
                <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>{title}</DialogTitle>
                <MuiDialogContent dividers style={{root: {padding:8}}}>
                    {type === 'addUser' || type === 'editUser' ? 
                    <AddEditUser onChange={this.props.onChange} editData={this.props}/>
                    : type === 'bulkUser' ?
                    <div>
                        <a href={this.url} target="_blank" rel="noopener noreferrer" style={{paddingRight: 80, float:'right'}}>Download Template</a>
                        <br/>
                        <Dropzone file={(e) => this.props.onChange('file', e)} acceptedFileTypes=".xlsx, .csv"/>
                    </div> : 
                    type === 'saveCheckPoint'? 
                    <FormControl fullWidth>
                        <TextField label="Checkpoint Name" onChange={(e) => this.props.onChange(e.target.value)}/>
                    </FormControl> : 
                    type === 'editZip'  ? 
                    <FormControl fullWidth>
                        <FormControl style={{marginBottom: "15px"}}>
                            <TextField label="Name" onChange={(e) => this.props.onChange('name', e.target.value)} value={name} />
                        </FormControl>
                        <FormControl>
                            <InputLabel>Status</InputLabel>
                            <Select native value={data} onChange={(e) => this.props.onChange('status', e.target.value)}>
                                {zipStatus.map(status => <option value={status} key={status}>{status}</option>)}
                            </Select>
                            </FormControl>
                    </FormControl>:
                    type === 'editModal'  ? 
                    <FormControl fullWidth>
                        <FormControl style={{marginBottom: "15px"}}>
                            <TextField label="Modal Title" onChange={(e) => this.props.onChange('clickableTitle', e.target.value)} value={name} />
                        </FormControl>
                        <FormControl>
                            <InputLabel>Status</InputLabel>
                            <Select native value={data} onChange={(e) => this.props.onChange('status', e.target.value)}>
                                {zipStatus.map(status => <option value={status} key={status}>{status}</option>)}
                            </Select>
                            </FormControl>
                    </FormControl>:
                    type === 'editBulkZips'  ? 
                    <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select native value={data} onChange={(e) => this.props.onChange('status', e.target.value)}>
                            {zipStatus.map(status => <option value={status} key={status}>{status}</option>)}
                        </Select>
                    </FormControl> :
                    type === 'editGroupName' ?
                    <FormControl fullWidth>
                        <TextField label="Group Name" value={name} onChange={(e) => this.props.onChange('newParentGroupName', e.target.value)}/>
                    </FormControl>:
                    type === 'editAlias' ? 
                    <FormControl fullWidth>
                        <TextField label="Add/Edit Friendly Name" value={data} onChange={(e) => this.props.onChange('aliasName', e.target.value)}/>
                    </FormControl> :
                    type === 'addexperience' || type === 'editExperience' ? 
                    <FormControl fullWidth>
                        <TextField label={type === 'addexperience' ? "Add Experience" : 'Rename Experience'} value={data} onChange={(e) => this.props.onChange('experienceName', e.target.value)}/>
                    </FormControl> :
                    type === 'addParentGroup' || type === 'addSubGroups' || type === 'addUsers' || type === 'addZips' ? <AddGroup onChange={this.props.onChange} type={type} data={data} selectedUsers={this.props.selectedUsers} selectedZips={this.props.selectedZips}/> :
                    type === 'screenshot' ? <img src={data} alt="" style={{width:'100%', height: '80%'}}/> :
                    type === 'requestIntegration' ? <RequestService onChange={this.props.newRequest}/> :
                    type === 'addIntegration' || type === 'editIntegration' ? <AddEditServices data={data} onChange={this.props.onChange} type={type}/> :
                    type === 'deleteIntegration'? <Typography variant="subtitle1"><p>{text ? text : 'Are you sure you want to delete this item?'}</p></Typography> :
                    type === 'zipSettings' ? <ZipMonitorSettings onChange={this.props.onChange} type={type} setting={data} onClick={this.props.onClick} threshold={this.props.threshold} scope={this.props.scope} email={this.props.email}/> :
                    type === 'editUserProfile'? <ProfileModal onChange={this.props.onChange} editData={this.props} onPasswordClick={this.onPasswordClick}/> :
                    type === 'selectorModal' ?
                    <SelectorSettingsModal onChange={this.props.onChange} editData={this.props}/> :
                    type === 'updatePassword'? 
                    <PasswordModal onChange={this.props.onChange} editData={this.props}/>:
                    type === 'companiesModal' ?
                    <CompaniesModal onChange={this.props.onChange} editData={this.props}/> :
                    type === 'allDomainsModal' ?
                    <AllDomainsModal editData={this.props} /> :
                    type === 'newCompaniesModal'?
                    <NewCompaniesModal onChange={this.props.onChange} editData={this.props}/> :
                    type === 'newSubCompaniesModal'?
                    <AddSubCompaniesModal onChange={this.props.onChange} editData={this.props}/> :
                    type === 'editSubCompaniesModal'?
                    <EditSubCompaniesModal onChange={this.props.onChange} editData={this.props}/> :
                    type === 'editCompaniesModal' ?
                    <EditCompanyModal onChange={this.props.onChange} editData={this.props}/> :
                    type === 'uploadLogoModal' ?
                    <UploadLogoModal showDropZone={this.props.showDropZone} onChange={this.props.onChange} editData={this.props}/> 
                    :type === 'uploadAssetModal' ?
                    <UploadAssetModal showDropZone={this.props.showDropZone} onChange={this.props.onChange} editData={this.props}/> 
                    : type === 'editLogoModal' ?
                    <EditLogoModal onChange={this.props.onChange} editData={this.props}/> 
                    : type === 'selectMultipleModal' ?
                    <SelectMultipleModal editData={this.props} />
                    : type === 'shareableModal' ?
                    <ShareableModal selectedShareable={this.props.selectedShareable} firstPage={this.props.firstPage} pages={this.props.pages} userList={this.props.userList} zip={this.props.zip} data={this.props.data} handleUpdateCopied={this.props.handleUpdateCopied} title={this.props.title} close={this.props.close}/>
                    : type === 'confirmation' ?<h3>{this.props.displayText}</h3>
                    : type === 'userSerachDetails'?
                    <UserSearchModal editData={this.props} SearchTerm={this.props.SearchTerm} SearchFrequency={this.props.SearchFrequency}
                            SearchUrl={this.props.SearchUrl} SearchUsers={this.props.SearchUsers} SearchDetailData={this.props.SearchDetailData} />
                    : type === 'editCategoriesAndTags' ? 
                    <EditCategoriesAndTags editName={this.props.editName} data={data} onChange={this.props.onChange}/> 
                    : type === 'editEmbed' ? 
                    <EditEmbed data={data} versions={this.props.versions} onChange={this.props.onChange}/>: null 
                }
                </MuiDialogContent>
                <MuiDialogActions style={{root: {margin: 0, padding:4}}}>
                <ZiplyneButton onClick={this.handleCancel}>
                    {type === 'screenshot' ? 'Close': type === 'confirmation'?'No' : 'Cancel'}
                </ZiplyneButton>
                { type !== 'screenshot' ? <ZiplyneButton onClick={() => type === 'bulkUser' ? this.props.fileUpload() : this.props.handleSave(type)}>
                    {type === 'deleteIntegration' ? 'Delete' : type==='confirmation'? 'Yes' : 'Save'}
                </ZiplyneButton> : null}
                </MuiDialogActions>
            </Dialog>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Modal);