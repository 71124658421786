const checkUserStatus= (userModel) => {
    if (userModel.roles) {
        let role = userModel.roles[0].name;
        if (role === 'superadmin') {
            return "Super Admin";
        }else if (role === 'admin') {
            return "Admin";
        }
        else if (role === 'creator') {
            return "Cretor";
        }
        else if (role === 'tester') {
            return "Tester";
        }
    } else {
        return 'User';
    }
} 

const fetchUniqueTable = (list) => {
    var tempList = [];
    for (var i = 0; i < list.length; i++) {
        if (i === 0) {
            tempList.push(list[i]);
        } else {
            var isAvailable = false;
            for (var j = 0; j < tempList.length; j++) {
                if (list[i].user_id === tempList[j].user_id && list[i].clicked_guide === tempList[j].clicked_guide) {
                    isAvailable = true;
                    break;
                }
            }
            if (isAvailable === false) {
                tempList.push(list[i]);
            }
        }
    }
    for (i = 0; i < tempList.length; i++) {
        var counter = 0;
        for (j = 0; j < list.length; j++) {
            if (tempList[i].user_id === list[j].user_id && tempList[i].clicked_guide === list[j].clicked_guide) {
                counter++;
            }
        }
        tempList[i].Frequency = counter;
    }
    return tempList;
}
const getUniqueUsersNumber = (list) => {
    var tempUsers =[];
    for (var i = 0; i < list.length; i++) {
        tempUsers.push(list[i].user_id);
    }
    
    var count = tempUsers.reduce(function(values, v) {
      if (!values.set[v]) {
        values.set[v] = 1;
        values.count++;
      }
      return values;
    }, { set: {}, count: 0 }).count;
    return count;
}

const UserSearchSupportFunctions = {
    checkUserStatus,getUniqueUsersNumber, fetchUniqueTable
}

export default UserSearchSupportFunctions;