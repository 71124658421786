import React, {Component} from 'react';
import Dropzone from 'components/FileUpload/DropZone';
import {Autocomplete} from '@material-ui/lab';
import { TextField, FormControl } from '@material-ui/core';

class UploadAssetModal extends Component {
    state = {
        showDropZone: {display: 'none'},
        filterTeamatesNames: [{label : 'Font', value :'Fonts'}],
        IsMobile: true
    }

    componentDidMount() {
        
        if (this.props.editData.LabelName !== undefined && this.props.editData.LabelName !== null &&
         this.props.editData.LabelName.length > 0) {
            this.setState({showDropZone: {}});
        }
    }

    onChange = (inp, value) => {
        
        
        if (value.length === 0) {
            this.setState({showDropZone:{display: 'none'}});
        } else {
            this.setState({showDropZone: {}})
        }
        this.props.onChange(inp, value)
    }
    selectStatus3 = (status) => {
        if (status !== undefined && status !== null && status.length > 0) {
            this.setState({showDropZone:{}});
        } else {
            this.setState({showDropZone:{display: 'none'}});

        }
        this.onChange('Type', status);
    }

    render () {
        const defaultPropsTeammates = {
            options: this.state.filterTeamatesNames,
            getOptionLabel:(option) => option.value,
        }
        
        const {LabelName, Type, IsMobile/*, onChange */} = this.props.editData;
        //var showDropZone = (this.props.showDropZone === true) ? {} : {display: 'none'}
        return (
            <FormControl fullWidth>
                <Autocomplete
                id="tags"
                {...defaultPropsTeammates}
                onChange={(event, value)=> this.selectStatus3(value.value)}
                disableClearable
                renderInput={(params) => <TextField {...params} label="Select Asset Type" margin="normal" />}
                />
                <TextField label="Asset Label" value={LabelName} onChange={(e) => this.onChange('LabelName', e.target.value)}/>
                <div style={this.state.showDropZone}>
                    <Dropzone updatedName={LabelName} Type={Type} IsMobile={IsMobile} file={(e) => this.props.onChange('file', e)} acceptedFileTypes=".otf"/>
                </div>
            </FormControl>
        )
    }
}

export default UploadAssetModal;